import React, { Component } from "react";
import UserAPI from "../../api/User";

import { Button, Card, Container, Form, Breadcrumb } from "react-bootstrap";
import { ToastContext } from "../../contexts/ToastContext";
import { errorMessage } from "../../utils/format";
import { NavLink } from "react-router-dom";

export default class EditUser extends Component {
  static contextType = ToastContext;
  state = {
    user: null,
    login: "",
    password: "",
    confirmPassword: ""
  };
  userId = this.props.match.params.id;
  edit = this.userId !== undefined;

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  createUser = event => {
    event.preventDefault();
    const { login, password, confirmPassword } = this.state;
    const body = {
      login,
      password,
      confirmPassword
    };

    UserAPI.create(body)
      .then(data => {
        this.context.addToast("success", data.message);
      })
      .catch(err => {
        this.context.addToast("error", errorMessage(err));
      })
      .finally(() => {
        this.setState({
          login: "",
          password: "",
          confirmPassword: ""
        });
      });
  };

  updateUser = event => {
    event.preventDefault();
    const { login, password, confirmPassword } = this.state;
    const body = {
      login,
      password,
      confirmPassword
    };

    UserAPI.edit(this.userId, body)
      .then(data => {
        this.context.addToast("success", data.message);
      })
      .catch(err => {
        this.context.addToast("error", errorMessage(err));
      })
      .finally(() => {
        this.setState({
          password: "",
          confirmPassword: ""
        });
      });
  };

  async componentDidMount() {
    if (this.edit) {
      const user = await UserAPI.get(this.userId);
      this.setState({ user });
    }
  }

  render() {
    const { user, login, password, confirmPassword } = this.state;
    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/users">Users</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {this.edit ? "Edit" : "New"} user
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <h5>{this.edit ? `Edit user ${user?.login}` : "New user"}</h5>
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={this.edit ? this.updateUser : this.createUser}>
              <Form.Group>
                <Form.Control
                  required={!this.edit}
                  type="text"
                  name="login"
                  value={this.edit ? user?.login : login}
                  placeholder="Login"
                  disabled={this.edit}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required
                  name="password"
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirm password"
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="btn-block">
                {this.edit ? "Update" : "Create"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
