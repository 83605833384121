import React, { useEffect, useState } from "react";
import WorkflowProvider from "../../contexts/WorkflowContext";
import WorkflowEditor from "./WorkflowEditor";
import WorkflowAPI from "../../api/WorkflowAPI";

export default function WorkflowContainer({ match }) {
  const { id, versionId } = match.params;
  const [loading, setLoading] = useState(true);
  const [workflow, setWorkflow] = useState({});

  useEffect(() => {
    WorkflowAPI.getVersion(id, versionId).then(workflowVersion => {
      setWorkflow(workflowVersion);
      setLoading(false);
    });
  }, [id, versionId]);

  return loading ? (
    <>Loading...</>
  ) : (
    <WorkflowProvider workflow={workflow}>
      <div className="workflow-container no-gutters">
        <WorkflowEditor />
      </div>
    </WorkflowProvider>
  );
}
