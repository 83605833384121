import React from "react";
import { Badge } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { differenceInMilliseconds, parseISO } from "date-fns";

export default function TimeBadge({ startedAt, endedAt, error }) {
  return (
    <Badge pill variant="primary">
      <FaRegClock />{" "}
      {(error === "started" &&
        differenceInMilliseconds(new Date(), parseISO(startedAt))) ||
        differenceInMilliseconds(parseISO(endedAt), parseISO(startedAt))}
      ms
    </Badge>
  );
}
