import React, { Component } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import { FaFileExport, FaFileImport, FaUserCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ChannelAPI from "../../api/Channel";
import UserAPI from "../../api/User";
import Auth from "../../authentication/auth";
import { ToastContext } from "../../contexts/ToastContext";
import { loadFile, saveFile } from "../../utils/file";
import { errorMessage, formatShortDate } from "../../utils/format";
import ChannelTypes from "./ChannelTypes";
import EventChannelForm from "./forms/EventChannelForm";
import FileChannelForm from "./forms/FileChannelForm";
import IotChannelForm from "./forms/IotChannelForm";
import RestChannelForm from "./forms/RestChannelForm";
import SaasChannelForm from "./forms/SaasChannelForm";
import WebChannelForm from "./forms/WebChannelForm";
import SchedulerChannelForm from "./forms/SchedulerChannelForm";
import SubscriptionCodeModal from "./SubscriptionCodeModal";
import ChannelWorkflows from "./ChannelWorkflows";

export default class EditChannel extends Component {
  static contextType = ToastContext;

  isAdmin = Auth.isAdmin();
  channelId = this.props.match.params.id;
  edit = this.channelId !== undefined;

  state = {
    channel: {
      active: true,
      type: "rest",
      name: "",
      slug: "",
      description: "",
      columns: [],
      response: null,
      company: "",
      meta: {}
    },
    channelOwner: Auth.getLogin(),
    companies: [],
    showSubscriptionCodeModal: false,
    workflows: []
  };

  async componentDidMount() {
    if (this.isAdmin) {
      // Await companies because is needed in getCompanyName
      const companies = await UserAPI.all();
      this.setState({ companies });
    }
    if (this.edit) {
      const [workflows, channel] = await Promise.all([
        ChannelAPI.getWorkflows(this.channelId),
        await ChannelAPI.get(this.channelId)
      ]);

      this.setState({
        channel,
        channelOwner: this.getCompanyName(channel.company) || Auth.getLogin(),
        workflows
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: {
        ...channel,
        [target.name]:
          target.type === "checkbox" ? target.checked : target.value
      }
    }));
  };

  handleMetaInputChange = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: {
        ...channel,
        meta: {
          ...channel.meta,
          [target.name]:
            target.type === "checkbox" ? target.checked : target.value
        }
      }
    }));
  };

  getCompanyName = companyId => {
    const company = this.state.companies.find(({ id }) => id === companyId);
    return company ? company.login : null;
  };

  selectCompany = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: {
        ...channel,
        company: target.value
      },
      channelOwner: this.getCompanyName(target.value) || Auth.getLogin()
    }));
  };

  selectChannelType = channelType => {
    this.handleInputChange({ target: { name: "type", value: channelType } });
  };

  updateChannel = event => {
    event.preventDefault();
    const { channel } = this.state;
    ChannelAPI.edit(channel.id, channel)
      .then(() => {
        this.props.history.push({ pathname: "/channels" });
      })
      .catch(err => {
        this.context.addToast(
          "error",
          errorMessage(err),
          "Failed to update channel"
        );
      });
  };

  createChannel = event => {
    event.preventDefault();
    const { channel } = this.state;

    ChannelAPI.create(channel)
      .then(() => {
        this.props.history.push({ pathname: "/channels" });
      })
      .catch(err => {
        this.context.addToast(
          "error",
          errorMessage(err),
          "Failed to create channel"
        );
      });
  };

  toggleSubscriptionCodeModal = () => {
    this.setState(({ showSubscriptionCodeModal }) => ({
      showSubscriptionCodeModal: !showSubscriptionCodeModal
    }));
  };

  channelForm = (channel, channelOwner) => {
    switch (channel.type) {
      case "file":
        return (
          <FileChannelForm
            channel={channel}
            channelOwner={channelOwner}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case "web":
        return (
          <WebChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case "event":
        return (
          <EventChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case "saas":
        return (
          <SaasChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case "iot":
        return (
          <IotChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case "scheduler":
        return (
          <SchedulerChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case null:
        return null;
      default:
        return (
          <RestChannelForm
            channel={channel}
            channelOwner={channelOwner}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
    }
  };

  export = () => {
    const { channel } = this.state;
    let filename = `${channel.slug}.ecosystem.json`;
    let contentType = "application/json;charset=utf-8;";
    const exportData = { channel };
    saveFile(filename, contentType, exportData);
  };

  import = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    loadFile(file, content => {
      try {
        const { channel = this.state.channel } = JSON.parse(content);
        this.setState({ channel });
      } catch (err) {}
    });
  };

  render() {
    const {
      channel,
      companies,
      channelOwner,
      showSubscriptionCodeModal,
      workflows
    } = this.state;

    return (
      <Container className="pb-5">
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/channels">Channels</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {this.edit ? "Edit" : "New"} channel
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header className="border-bottom">
            <Row>
              <Col>
                <h5>{this.edit ? "Update channel" : "New channel"}</h5>
                {this.edit && (
                  <Badge variant="primary">
                    Last update : {formatShortDate(channel.updatedAt)}
                  </Badge>
                )}
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => this.upload.click()}
                  size="sm"
                  variant="inline-primary"
                  className="mr-3"
                >
                  <FaFileImport />
                  &nbsp; Import
                </Button>
                {this.edit && (
                  <>
                    <Button
                      onClick={this.export}
                      size="sm"
                      variant="inline-primary"
                    >
                      <FaFileExport />
                      &nbsp; Export
                    </Button>
                    <Button
                      onClick={() => this.toggleSubscriptionCodeModal()}
                      size="sm"
                      variant="inline-primary"
                      className="ml-3"
                    >
                      <FaUserCheck />
                      &nbsp; Generate Subscription Code
                    </Button>

                    <Form.Check
                      name="active"
                      type="switch"
                      checked={channel.active}
                      id="custom-switch"
                      onChange={this.handleInputChange}
                      label="Active"
                      className="mt-3 d-flex justify-content-end"
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Form
              onSubmit={this.edit ? this.updateChannel : this.createChannel}
            >
              <input
                id="import"
                type="file"
                ref={ref => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.import}
                accept="application/json"
              />

              {this.edit && (
                <Form.Label>
                  Slug : <Badge variant="secondary">{channel.slug}</Badge>
                </Form.Label>
              )}

              {this.isAdmin && (
                <Form.Group>
                  <Form.Label>Company*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="company"
                    className={!channel.company ? "select-default" : ""}
                    onChange={this.selectCompany}
                    value={channel.company}
                  >
                    <option value="">Select a company</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Example: Delivery"
                    value={channel.name}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    placeholder="Detailed purpose of this channel"
                    value={channel.description}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Data source*</Form.Label>
                <ChannelTypes
                  selectedType={channel.type}
                  onSelect={this.selectChannelType}
                />
              </Form.Group>
              {this.channelForm(channel, channelOwner)}

              <Button
                className="btn-block"
                variant="inline-alpha"
                type="submit"
              >
                {this.edit ? "Update" : "Create"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
        {workflows.length > 0 && (
          <ChannelWorkflows channel={channel} workflows={workflows} />
        )}

        {showSubscriptionCodeModal && (
          <SubscriptionCodeModal
            channelId={channel.id}
            onClose={this.toggleSubscriptionCodeModal}
          />
        )}
      </Container>
    );
  }
}
