import BaseAPI from "./BaseAPI";

/**
 * Workflow API
 * @extends BaseAPI
 */
export default class WorkflowAPI extends BaseAPI {
  /**
   * Get all workflows
   *
   * @returns {Promise<Object[]>} The workflow
   */
  static getAll() {
    return this.execute("get", `/workflows/`);
  }
  /**
   * Get a workflow by its id
   *
   * @param {String} id Id of the workflow
   * @returns {Promise<Object>} The workflow
   */
  static get(id) {
    return this.execute("get", `/workflows/${id}`);
  }

  static create(workflow) {
    return this.execute("POST", `/workflows`, workflow);
  }

  static delete(id) {
    return this.execute("DELETE", `/workflows/${id}`);
  }

  static update(id, updatedWorkflow) {
    return this.execute("PATCH", `/workflows/${id}`, updatedWorkflow);
  }

  static getVersion(id, versionId) {
    return this.execute("GET", `/workflows/${id}/versions/${versionId}`);
  }
  static createVersion(id, version, fromVersion) {
    return this.execute("POST", `/workflows/${id}/versions`, {
      version,
      fromVersion
    });
  }

  static updateVersion(id, versionId, version) {
    return this.execute(
      "PUT",
      `/workflows/${id}/versions/${versionId}`,
      version
    );
  }

  static deleteVersion(id, versionId) {
    return this.execute("DELETE", `/workflows/${id}/versions/${versionId}`);
  }

  static deployVersion(id, versionId) {
    return this.execute("PUT", `/workflows/${id}/versions/${versionId}/deploy`);
  }

  static testRun(node, columns, context) {
    return this.execute("POST", "/workflows/testRun", {
      node,
      columns,
      context
    });
  }
}
