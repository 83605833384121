import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import "./EditableText.scss";

export default function EditableText({ text, onChange, onSubmit }) {
  const [edit, setEdit] = useState(false);

  function submit() {
    setEdit(false);
    if (onSubmit) {
      onSubmit();
    }
  }
  if (!edit) {
    return (
      <div className="editable-text">
        <span onClick={() => setEdit(true)}>{text}</span>
        <MdEdit onClick={() => setEdit(true)} />
      </div>
    );
  }
  return (
    <div className="editable-text">
      <Form onSubmit={submit}>
        <Form.Control
          type="text"
          onBlur={submit}
          value={text}
          onChange={onChange}
          autoFocus
          required
          size="sm"
        />
      </Form>
    </div>
  );
}
