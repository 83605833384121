import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import Help from "../../../../Help";
import EditableText from "../../EditableText";
import SendEmailRecipientForm from "../../../../subscriptions/calls/email/SendEmailRecipientForm";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

export default function SendEmailNodeForm({ node, onClose }) {
  const { updateNode } = useWorkflow();

  const [name, setName] = useState(node.name);

  const [config, setConfig] = useState(node.config);

  const [content, setContent] = useState(
    node.config.html && node.config.html.length > 0
      ? node.config.html
      : node.config.text
  );

  const [recipients, setRecipients] = useState(
    (node.config.to || "").split(",")
  );

  const [contentAsHtml, setContentAsHtml] = useState(
    node.config.html && node.config.html.length > 0
  );

  const deleteRecipient = deleteIndex => {
    setRecipients(recipients.filter((it, index) => index !== deleteIndex));
  };

  const addRecipient = () => {
    setRecipients([...recipients, ""]);
  };

  const updateRecipient = ({ target }, index) => {
    setRecipients(recipients =>
      recipients.map((recipient, id) =>
        id === index ? target.value : recipient
      )
    );
  };

  const updateConfig = (key, value) => {
    setConfig({ ...config, [key]: value });
  };

  const saveNode = e => {
    e.preventDefault();

    updateNode(node.id, {
      ...node,
      name,
      config: {
        ...config,
        to: recipients.join(),
        ...(contentAsHtml
          ? { html: content, text: undefined }
          : { text: content, html: undefined })
      }
    });
  };

  const { subject } = config;

  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>

      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <Form.Label>
              Send email&nbsp;
              <Help title="Send email">
                <p>Allow to send email to a list of users</p>
              </Help>
            </Form.Label>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  <b>Recipients</b>
                  <Button
                    type="button"
                    size="lg"
                    variant="inline-success"
                    onClick={() => addRecipient()}
                  >
                    <FaPlus />
                  </Button>
                </Form.Label>
                {recipients.map((recipient, index) => (
                  <div key={index}>
                    <SendEmailRecipientForm
                      recipient={recipient}
                      onChange={e => updateRecipient(e, index)}
                      onDelete={e => deleteRecipient(index)}
                      index={index}
                    ></SendEmailRecipientForm>
                  </div>
                ))}
              </Form.Group>
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  <b>Email subject</b>
                </Form.Label>
              </Form.Group>
              <Form.Control
                required={true}
                type="text"
                name="subject"
                placeholder="Message from your ecosystem workflow"
                onChange={({ target }) => updateConfig("subject", target.value)}
                value={subject}
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  <b>Email content</b>
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="retry-switch"
                  name="retryActive"
                  label="Content as html"
                  checked={contentAsHtml}
                  onChange={({ target }) => setContentAsHtml(target.checked)}
                />
              </Form.Group>
              <Form.Control
                as="textarea"
                type="text"
                required={true}
                name="content"
                placeholder="Content of your message"
                onChange={({ target }) => setContent(target.value)}
                value={content}
                rows={3}
              />
            </Form.Row>
          </Form.Group>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
