import React from 'react'

export default function BroadcastColumn({ column, handleChange }) {
  return (
    <div className="ecosystem-div-small">
      <label>{column.label}</label>
      {" "}
      <input
        className="ecosystem-input-small"
        type="text"
        id={column.label}
        value={column.value}
        onChange={(event) => handleChange(column.label, event.target.value)} />
    </div>
  )
}