import BaseAPI from './BaseAPI'

/**
 * Broadcast API
 * @extends BaseAPI
 */
export default class Broadcast extends BaseAPI {
  /**
   * Broadcast to a channel
   *
   * @returns {Promise<Object>} All the subscribed response
   */
  static broadcast(data) {
    return this.execute('post', '/broadcast', data)
  }
}
