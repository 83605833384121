import React, { useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export default function ConditionalNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();
  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);
  const [error, setError] = useState();

  const { variables, autocomplete } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );

  const updateConfig = (key, value) => {
    setConfig({ ...config, [key]: value });
  };

  const saveNode = e => {
    e.preventDefault();
    if ((config.conditionCode === "") | !config.conditionCode) {
      setError("Condition is required.");
      return;
    }
    updateNode(node.id, { ...node, name, config });
  };

  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, name, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <Form.Label>Condition*</Form.Label>
            <Help title="Condition" placement="left" maxWidth="370px">
              <p>
                Execute <b>javascript code</b> that <b>return a boolean</b> to
                determine which of the next two paths your workflow should execute.
              </p>
              Ex:
              <LegacyCodeEditor
                readOnly
                code={
                  // eslint-disable-next-line no-template-curly-in-string
                  "return context.myVariable === 'ok';"
                }
              />
            </Help>
            <CodeEditor
              code={config.conditionCode}
              onChange={code => updateConfig("conditionCode", code)}
              autocomplete={autocomplete}
            />
            {error && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
