import React from "react";
import { Col, Form } from "react-bootstrap";
import AnonymizeInput from "./../../AnonymizeInput";
export default function ColumnForm({
  name,
  example,
  anonymize = false,
  onChange,
  children = null
}) {
  return (
    <Form.Row>
      <Col xs={3}>
        <Form.Control
          required
          type="text"
          name="name"
          value={name}
          placeholder="Name"
          onChange={onChange}
        />
      </Col>
      <Col xs={3}>
        <Form.Control
          type="text"
          name="example"
          value={example}
          placeholder="Example"
          onChange={onChange}
        />
      </Col>
      <Col xs={2}>
        <AnonymizeInput
          name="anonymize"
          checked={anonymize}
          onChange={onChange}
        />
      </Col>

      {children}
    </Form.Row>
  );
}
