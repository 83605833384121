import React from "react";

export default function NodeFiller() {
  return (
    <div className="node-filler">
      <svg className="node-arrow-svg">
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          stroke="#5e636b"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
}
