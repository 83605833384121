import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import WorkflowAPI from "../../api/WorkflowAPI";
import UserAPI from "../../api/User";
import WorkflowList from "./WorkflowList";
import Auth from "../../authentication/auth";

export default function Workflows({ history }) {
  const [workflows, setWorkflows] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deletingWorkflow, setDeletingWorkflow] = useState(null);
  const isAdmin = Auth.isAdmin();
  async function loadWorkflows() {
    const workflows = await UserAPI.getWorkflows();
    setWorkflows(workflows);
  }

  useEffect(() => {
    loadWorkflows();
  }, []);

  function executeAction(action, workflowId) {
    switch (action) {
      case "new":
        history.push({ pathname: "/workflows/create" });
        break;
      case "edit":
        updateWorkflow(workflowId);
        break;
      case "delete":
        askDeleteConfirm(workflowId);
        break;
      default:
        break;
    }
  }

  function updateWorkflow(workflowId) {
    history.push({
      pathname: `/workflows/edit/${workflowId}`
    });
  }

  function askDeleteConfirm(workflowId) {
    setShowDeleteConfirm(true);
    setDeletingWorkflow(workflowId);
  }

  function closeDeleteConfirm() {
    setShowDeleteConfirm(false);
    setDeletingWorkflow(null);
  }

  function deleteWorkflow(workflowId) {
    WorkflowAPI.delete(workflowId).finally(() => {
      closeDeleteConfirm();
      loadWorkflows();
    });
  }
  return (
    <Container>
      <WorkflowList
        workflows={workflows}
        onAction={executeAction}
        isAdmin={isAdmin}
      />
      <br />
      <Modal show={showDeleteConfirm} onHide={closeDeleteConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Delete workflow</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this workflow ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirm}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteWorkflow(deletingWorkflow)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
