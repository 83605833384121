export function branchCount(node) {}

export function lastNodeOfBranch(nodes, start) {
  if (!start) {
    return undefined;
  }
  if (!nodes[start]) {
    return undefined;
  }

  if (nodes[start].next) {
    return lastNodeOfBranch(nodes, nodes[start].next);
  }
  return nodes[start];
}
