import React, { Component } from "react";
import { MdBuild } from "react-icons/md";
import { Button, Col, Form } from "react-bootstrap";

export default class EventChannelForm extends Component {
  state = {
    showAdvanced: false
  };

  toggleAdvanced = () => {
    this.setState(({ showAdvanced }) => ({
      showAdvanced: !showAdvanced
    }));
  };

  render() {
    const { showAdvanced } = this.state;
    const { channel, onMetaChange } = this.props;
    const { meta = {} } = channel;
    return (
      <>
        <Form.Group>
          <Form.Label>Event provider*</Form.Label>
          <Form.Control
            as="select"
            required
            name="eventProvider"
            className={!meta.eventProvider ? "select-default" : ""}
            onChange={onMetaChange}
            value={meta.eventProvider}
          >
            <option value="">Select a type</option>
            <option value="amq">ActiveMQ</option>
            <option value="rmq">RabbitMQ</option>
            <option value="kafka">Kafka</option>
            <option value="redis">Redis Stream</option>
            <option value="jms">JMS</option>
            <option value="gcp">GCP PubSub</option>
            <option value="azure">...Azure SB</option>
            <option value="awssqs">...AWS SQS</option>
            {/* <option value="jms">JMS</option>
            <option value="rabbit">RabbitMQ</option> */}
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              {" "}
              {(meta.eventProvider !== "gcp" &&
                "URI (protocol - Host - port)*") ||
                "ProjectId*"}
            </Form.Label>
            <Form.Control
              type="text"
              name="eventHost"
              required
              placeholder=""
              value={meta.eventHost}
              onChange={onMetaChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Label>
            {" "}
            {(meta.eventProvider !== "gcp" && "Topic*") || "Subcription*"}
          </Form.Label>
          <Form.Control
            type="text"
            required
            name="eventTopic"
            placeholder=""
            value={meta.eventTopic}
            onChange={onMetaChange}
          />
        </Form.Group>
        <Form.Group>
          <Button type="button" onClick={this.toggleAdvanced} variant="light">
            <MdBuild /> Advanced
          </Button>
          {showAdvanced && (
            <div style={{ paddingTop: "1rem" }}>
              <Form.Group>
                {meta.eventProvider !== "gcp" && (
                  <>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="eventUsername"
                      placeholder="username"
                      value={meta.eventUsername}
                      onChange={onMetaChange}
                    />
                  </>
                )}
              </Form.Group>
              <Form.Group>
                {(meta.eventProvider !== "gcp" && (
                  <>
                    <Form.Label> Password</Form.Label>
                    <Form.Control
                      type="text"
                      name="eventPassword"
                      placeholder="password"
                      value={meta.eventPassword}
                      onChange={onMetaChange}
                    />
                  </>
                )) || (
                  <>
                    <Form.Label> Service account key</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="eventPassword"
                      placeholder="password"
                      value={meta.eventPassword}
                      onChange={onMetaChange}
                    />
                  </>
                )}
              </Form.Group>
            </div>
          )}
        </Form.Group>
      </>
    );
  }
}
