import React, { useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaCodeBranch } from "react-icons/fa";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import { lastNodeOfBranch } from "../../../../utils/graph";
import { DeleteConfirm } from "../../../DeleteConfirm";
import NewNode from "../NewNode";
import NodeContainer from "../NodeContainer";
import NodeFiller from "../NodeFiller";

const NodeConditionArrow = ({ condition }) => {
  const position = condition.value
    ? {
        x1: "50%",
        y1: "0%",
        x2: "100%",
        y2: "0%"
      }
    : {
        x1: "50%",
        y1: "0%",
        x2: "0%",
        y2: "0%"
      };
  return (
    <div className="node-condition-arrow" key={condition.value}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100% 100%"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <defs>
          <marker
            id="arrow"
            markerWidth="12"
            markerHeight="12"
            refX="0"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
            viewBox="0 0 15 15"
          >
            <path d="M0,0 L0,6 L6,3 z" fill="#5e636b" />
          </marker>
        </defs>
        <line {...position} stroke="#5e636b" strokeWidth="3" />
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="90%"
          stroke="#5e636b"
          strokeWidth="3"
          markerEnd="url(#arrow)"
        />
      </svg>
      <div
        className={`node-condition-label node-condition-label-${condition.value}`}
      >
        <span>{condition.label}</span>
      </div>
    </div>
  );
};

const NodeConditionEndArrow = ({ condition }) => {
  const position = condition.value
    ? {
        x1: "50%",
        y1: "100%",
        x2: "100%",
        y2: "100%"
      }
    : {
        x1: "50%",
        y1: "100%",
        x2: "0%",
        y2: "100%"
      };
  return (
    <div className="node-condition-arrow" key={condition.value}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100% 100%"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <defs>
          <marker
            id="arrow"
            markerWidth="12"
            markerHeight="12"
            refX="0"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
            viewBox="0 0 15 15"
          >
            <path d="M0,0 L0,6 L6,3 z" fill="#5e636b" />
          </marker>
        </defs>
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="90%"
          stroke="#5e636b"
          strokeWidth="3"
          markerEnd="url(#arrow)"
        />
        <line {...position} stroke="#5e636b" strokeWidth="3" />
      </svg>
    </div>
  );
};

export default function ConditionalNode({ node }) {
  const { nodes, dispatch } = useWorkflow();
  const { conditions = [] } = node.config ?? {};

  const nextNode = node.next ? nodes[node.next] : null;

  const leftBranch = node.config.conditions[0]?.next;
  const rightBranch = node.config?.conditions[1]?.next;
  const lastLeftNode = useMemo(
    () => lastNodeOfBranch(nodes, leftBranch),
    [nodes, leftBranch]
  );
  const lastRightNode = useMemo(
    () => lastNodeOfBranch(nodes, rightBranch),
    [nodes, rightBranch]
  );

  return (
    <>
      <Card
        className="node p-0"
        onClick={() => dispatch({ type: "toggleNode", payload: { node } })}
      >
        <Card.Body className="node-body">
          <Row className="align-items-center">
            <div className="node-icon">
              <FaCodeBranch />
            </div>
            <Col className="node-name">
              <b>{node.name ? node.name : "If"}</b>
            </Col>
            <Col className="node-actions" xs="auto">
              <DeleteConfirm
                className="node-delete"
                onClick={e => {
                  e.stopPropagation();
                  dispatch({ type: "deleteNode", payload: { id: node.id } });
                }}
                size="sm"
                variant="outline-danger"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <svg className="node-arrow-svg">
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          stroke="#5e636b"
          strokeWidth="3"
        />
      </svg>
      <div className="node-conditional">
        {conditions.map(condition => {
          return condition.next ? (
            <div className="node-container">
              <NodeConditionArrow key={condition.value} condition={condition} />
              <NodeContainer
                key={condition.next}
                node={nodes[condition.next]}
              />
              <NodeConditionEndArrow
                key={condition.value}
                condition={condition}
              />
            </div>
          ) : (
            <>
              <div className="node-container">
                <NodeConditionArrow
                  key={condition.value}
                  condition={condition}
                />
                <NewNode
                  key={condition.value}
                  onClick={() =>
                    dispatch({
                      type: "toggleNewNode",
                      payload: {
                        parentId: node.id,
                        conditionValue: condition.value
                      }
                    })
                  }
                />
                <NodeFiller />
                <NodeConditionEndArrow
                  key={condition.value}
                  condition={condition}
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="node-next">
        <svg className="node-arrow-svg-cond">
          <line
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            stroke="#5e636b"
            strokeWidth="3"
          />
        </svg>
        <div className="node-conditional-arrow"></div>
        <NewNode
          onClick={() =>
            dispatch({
              type: "toggleNewNode",
              payload: {
                parentId: node.id,
                nextId: nextNode ? nextNode.id : null
              }
            })
          }
        />
        {nextNode && <NodeContainer node={nextNode} />}
      </div>
    </>
  );
}
