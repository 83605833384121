import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";

export default class FieldForm extends Component {
  render() {
    const { type, name, content, onChange, children = null } = this.props;
    return (
      <>
        <Form.Row style={{ alignItems: "flex-end" }}>
          <Col xs={2}>
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              required
              name="type"
              onChange={onChange}
              value={type}
            >
              <option value="type">Value</option>
              <option value="file">File</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              onChange={onChange}
            />
          </Col>
          <Col>
            <Form.Label>Content</Form.Label>
            <Form.Control
              required
              type="text"
              name="content"
              value={content}
              placeholder="Content"
              onChange={onChange}
            />
          </Col>
          {children}
        </Form.Row>
      </>
    );
  }
}
