import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Row,
  Table
} from "react-bootstrap";
import { FaLock, FaLockOpen } from "react-icons/fa";
import DatasourceAPI from "./../api/Datasource";
import { formatLongDate } from "./../utils/format";

export default function Datasources() {
  const [datasources, setDatasources] = useState([]);

  useEffect(() => {
    fetchDataSources();
  }, []);

  async function fetchDataSources() {
    const datasources = await DatasourceAPI.all();
    setDatasources(datasources);
  }

  async function unlock(id) {
    await DatasourceAPI.unlock(id);
    // Relock is so fast that we can not see the relock properly
    // we will mock it visualy
    setDatasources(datasources =>
      datasources.map(datasource => {
        if (datasource._id === id) {
          return { ...datasource, locked: false };
        }
        return datasource;
      })
    );
    setTimeout(() => {
      fetchDataSources();
    }, 1000);
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Datasource Locks</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{ padding: 0 }}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Channel slug</th>
                <th>Locked</th>
                <th>Process Id</th>
                <th>Last update</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {datasources
                .filter(datasource => datasource.channel)
                .map(datasource => (
                  <tr key={datasource.id}>
                    <td>{datasource.channel.slug}</td>
                    <td>
                      {datasource.locked ? (
                        <Badge pill className="badge-primary">
                          <FaLock /> Locked
                        </Badge>
                      ) : (
                        <Badge pill className="badge-danger">
                          <FaLockOpen /> Unlocked
                        </Badge>
                      )}
                    </td>
                    <td>{datasource.processId}</td>
                    <td>{formatLongDate(datasource.updatedAt)}</td>
                    <td>
                      <ButtonToolbar className="float-right">
                        <Button
                          onClick={() => unlock(datasource._id)}
                          variant="inline-primary"
                        >
                          <FaLockOpen /> Force Unlock
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
              {datasources.length === 0 && (
                <tr>
                  <td>
                    <h6>No datasources lock.</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}
