import React, { Component } from "react";
import { MdBuild } from "react-icons/md";
import { Button, Col, Form, Tabs, Tab } from "react-bootstrap";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import Columns from "../columns/Columns";
const MAX_POLLING_INTERVAL = 2000000; // For safety measures
export default class WebChannelForm extends Component {
  state = {
    showAdvanced: false
  };

  toggleAdvanced = () => {
    this.setState(({ showAdvanced }) => ({
      showAdvanced: !showAdvanced
    }));
  };

  render() {
    const { channel, onChange, onMetaChange } = this.props;
    const { meta = {} } = channel;
    const { showAdvanced } = this.state;
    const {
      pollingType,
      pollingMethod,
      pollingUrl,
      pollingPath,
      pollingInterval,
      pollingHeaders,
      pollingBody,
      pollingMode = "new"
    } = meta;
    return (
      <>
        <Form.Group>
          <Form.Label>Polling type*</Form.Label>
          <Form.Control
            as="select"
            required
            name="pollingType"
            className={!pollingType ? "select-default" : ""}
            onChange={onMetaChange}
            value={pollingType}
          >
            <option value="">Select a type</option>
            <option value="api">Api Polling</option>
            <option disabled value="long">Long polling</option>
            <option disabled value="websocket">Websocket</option>
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} xs={2}>
            <Form.Label>Polling Method</Form.Label>
            <Form.Control
              as="select"
              name="pollingMethod"
              onChange={onMetaChange}
              value={pollingMethod}
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Polling Url</Form.Label>
            <Form.Control
              type="text"
              required
              name="pollingUrl"
              placeholder="https://api.test.com/products/"
              value={pollingUrl}
              onChange={onMetaChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Label>Polling Path</Form.Label>
          <Form.Control
            type="text"
            name="pollingPath"
            placeholder=""
            value={pollingPath}
            onChange={onMetaChange}
          />
        </Form.Group>
        <Form.Group>
          <Button type="button" onClick={this.toggleAdvanced} variant="light">
            <MdBuild /> Advanced
          </Button>
          {showAdvanced && (
            <div style={{ paddingTop: "1rem" }}>
              {pollingType === "api" && (
                <Form.Group>
                  <Form.Label>Polling mode</Form.Label>
                  <Form.Control
                    id="select-mode"
                    as="select"
                    placeholder="Polling Mode"
                    name="pollingMode"
                    value={pollingMode}
                    onChange={onMetaChange}
                  >
                    <option value="new">Send only new items</option>)
                    <option value="all">Send all returned items</option>)
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Polling interval (in seconds)</Form.Label>
                <Form.Control
                  type="number"
                  name="pollingInterval"
                  placeholder="1"
                  min={1}
                  max={MAX_POLLING_INTERVAL}
                  value={pollingInterval}
                  onChange={onMetaChange}
                />
              </Form.Group>

              <Tabs
                variant="pills"
                className="tabs-call"
                defaultActiveKey="headers"
              >
                <Tab eventKey="headers" title="Headers">
                  <Form.Group>
                    <LegacyCodeEditor
                      code={pollingHeaders}
                      onChange={value =>
                        onMetaChange({
                          target: { name: "pollingHeaders", value }
                        })
                      }
                    />
                  </Form.Group>
                </Tab>
                <Tab eventKey="body" title="Body">
                  <Form.Group>
                    <LegacyCodeEditor
                      code={pollingBody}
                      onChange={value =>
                        onMetaChange({
                          target: { name: "pollingBody", value }
                        })
                      }
                    />
                  </Form.Group>
                </Tab>
              </Tabs>
            </div>
          )}
        </Form.Group>
        <Columns columns={channel.columns} onChange={onChange} />
      </>
    );
  }
}
