import React from "react";
import { useToasts } from "../contexts/ToastContext";
import { Toast } from "react-bootstrap";
import { capitalize } from "../utils/format";

const CLEAR_DELAY = 5000;

const Toasts = () => {
  const { toasts, clearToast } = useToasts();
  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        bottom: 15,
        left: 15,
        zIndex: 999
      }}
    >
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          className={`toast-${toast.type}`}
          onClose={() => clearToast(toast.id)}
          delay={CLEAR_DELAY}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">
              {toast.title || capitalize(toast.type)}
            </strong>
          </Toast.Header>
          <Toast.Body className={`toast-${toast.type}`}>
            <strong>{toast.message}</strong>
            {toast.additional && <div className="pt-2">{toast.additional}</div>}
          </Toast.Body>
        </Toast>
      ))}
    </div>
  );
};
export default Toasts;
