import React from "react";
import {
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { FaRegQuestionCircle } from "react-icons/fa";
import LegacyCodeEditor from "./LegacyCodeEditor";

const popover = (
  <Popover id="popover-basic">
    <Popover.Title>Anonymization</Popover.Title>
    <Popover.Content>
      When anonymized, the value will be replaced by **** in monitoring
      <br />
      <br />
      Example :
      <br />
      <LegacyCodeEditor
        readOnly
        code={JSON.stringify(
          {
            firstname: "*****",
            lastname: "*****"
          },
          null,
          2
        )}
      />
    </Popover.Content>
  </Popover>
);

export default function AnonymizeInput({
  name,
  checked = false,
  onChange,
  label = "Anonymize",
  width = 165
}) {
  return (
    <InputGroup style={{ width }} className="anonymize-input">
      <InputGroup.Prepend>
        <InputGroup.Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
        />
      </InputGroup.Prepend>

      <FormControl disabled defaultValue={label} />

      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={popover}
      >
        <InputGroup.Append>
          <InputGroup.Text>
            <FaRegQuestionCircle />
          </InputGroup.Text>
        </InputGroup.Append>
      </OverlayTrigger>
    </InputGroup>
  );
}
