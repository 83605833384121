import jsonata from "jsonata";
import React, { Component } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { xml2js } from "xml-js";
import CodeEditor from "../../../CodeEditor";

export default class DataPathPlayground extends Component {
  state = {
    xml: "",
    json: "",
    path: "",
    result: ""
  };

  prettyStringify = json => JSON.stringify(json, null, 2);

  updateXml = xml => {
    let json = {};
    try {
      json = xml2js(xml, { compact: true });
    } catch (err) {}
    this.setState({ xml, json: this.prettyStringify(json) });
  };

  updatePath = ({ target }) => {
    const extractResult = this.extract(target.value, this.state.json);
    this.setState({
      path: target.value,
      result: this.prettyStringify(extractResult)
    });
  };

  extract(path, json) {
    let expression;
    try {
      expression = jsonata(path);
    } catch (err) {
      return err.message;
    }
    let parsedResponse;
    try {
      parsedResponse = JSON.parse(json);
    } catch (err) {
      return err.message;
    }
    let extracted;
    try {
      extracted = expression.evaluate(parsedResponse) || {};
    } catch (err) {
      return err.message;
    }
    return extracted;
  }
  render() {
    const { xml, json, path, result } = this.state;
    return (
      <Modal
        show={true}
        onHide={this.props.onClose}
        className="modal-playground modal-fullscreen"
      >
        <Modal.Header>
          <Modal.Title as="h5">Data Path Playground</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center">
            <Alert variant="info">
              We are using JSONata behind the scene to help you extract pieces
              of data, &nbsp;
              <a
                href="https://docs.jsonata.org/simple"
                target="_blank"
                rel="noopener noreferrer"
              >
                see the documention
              </a>
              &nbsp;for the <b>path</b> syntax.
            </Alert>
          </Row>
          <Row>
            <Col>
              <h6>XML</h6>
              <CodeEditor
                name="xml"
                onChange={this.updateXml}
                code={xml}
                mode="xml"
              />
            </Col>
            <Col>
              <h6>JSON</h6>
              <CodeEditor name="json" readOnly code={json} />
            </Col>
            <Col>
              <Row className="mb-3">
                <h6>Path</h6>
                <Form.Control
                  type="text"
                  name="path"
                  value={path}
                  onChange={this.updatePath}
                />
              </Row>
              <Row>
                <h6>Result</h6>
                <CodeEditor readonly code={result} />
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.props.onPathSave(path)}>
            Save path
          </Button>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
