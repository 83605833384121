import React, { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { v4 } from "uuid";
import WorkflowApi from "../api/WorkflowAPI";
import { DEFAULT_REQUEST_TIMEOUT } from "../components/workflow/WorkflowEditor/nodes/forms/HttpNodeForm";
export const WorkflowContext = createContext();

const defaultConfigs = {
  HTTP: {
    name: "Http Request",
    method: "GET",
    url: "",
    body: "",
    headers: '{\n\t"Content-Type": "application/json"\n}',
    anonymize: {},
    extractors: [],
    retryActive: false,
    retryOptions: {},
    timeout: DEFAULT_REQUEST_TIMEOUT
  },
  FORM: {
    name: "Submit form",
    method: "POST",
    url: "",
    headers: '{\n\t"Accept": "application/json"\n}',
    anonymize: {},
    fields: [],
    extractors: [],
    retryActive: false,
    retryOptions: {},
    timeout: DEFAULT_REQUEST_TIMEOUT
  },
  FTP: {
    name: "Send file to FTP server",
    server: "",
    port: "",
    content: "",
    filePath: "",
    user: "",
    password: "",
    overwrite: true,
    retryActive: false,
    retryOptions: {},
    timeout: DEFAULT_REQUEST_TIMEOUT
  },
  BUSINESS: {
    name: "Business Dashboard Trace",
    businessTraces: []
  },
  CODE: {
    name: "Code",
    code: ""
  },
  RESPONSE: {
    statusCode: "return 200",
    responseTemplate: ""
  },
  CONDITIONAL: {
    name: "Conditional",
    conditions: [
      { value: true, label: "True", next: null },
      { value: false, label: "False", next: null }
    ],
    conditionCode: ""
  },
  FOREACH: {
    name: "Foreach",
    foreachCode: "",
    foreach: {
      next: null
    }
  },
  WAIT: {
    name: "Wait",
    time: "100"
  },
  EVENT: {
    name: "Emit event",
    URI: "",
    body: "",
    headers: '{\n\t"Content-Type": "application/json"\n}',
    anonymize: {},
    retryActive: false,
    retryOptions: {},
    timeout: DEFAULT_REQUEST_TIMEOUT
  },
  SEND_EMAIL: {
    name: "Send email"
  }
};

function reducer(draft, action) {
  const { type, payload } = action;

  switch (type) {
    case "createNode": {
      const node = {
        id: v4(),
        type: payload.type,
        name: defaultConfigs[payload.type].name,
        config: defaultConfigs[payload.type],
        parent: draft.newNode.parentId,
        next: draft.newNode.nextId ?? null
      };

      draft.showNode = node;

      return;
    }
    case "deleteNode": {
      const deletedNode = draft.nodes[payload.id];
      const parent = draft.nodes[deletedNode.parent];
      const next = draft.nodes[deletedNode.next] ?? null;

      if (parent.type === "CONDITIONAL") {
        // if node is in a condition of parent
        const conditionNode = parent.config.conditions.find(
          condition => condition.next === payload.id
        );
        if (conditionNode) {
          conditionNode.next = null; // delete node from condition
          if (next) {
            conditionNode.next = next.id; // put next node at start of branch
          }
        } else {
          // after conditional block
          parent.next = next ? next.id : null;
        }
      } else if (parent.type === "FOREACH") {
        if (
          parent.config.foreach.next &&
          parent.config.foreach.next === deletedNode.id
        ) {
          parent.config.foreach.next = next ? next.id : null;
        } else {
          // set next into parent
          parent.next = next ? next.id : null;
        }
      } else {
        // set next into parent
        parent.next = next ? next.id : null;
      }
      if (next) {
        next.parent = parent.id;
      }

      if (
        deletedNode.type === "CONDITIONAL" ||
        deletedNode.type === "FOREACH"
      ) {
        // Remove node and children nodes
        collectIds(draft.nodes, deletedNode.id).forEach(
          id => delete draft.nodes[id]
        );
      } else {
        // Simply delete node
        delete draft.nodes[payload.id];
      }

      return;
    }
    case "insertOrUpdateNode": {
      // INSERT NEW NODE
      if (!draft.nodes[payload.id]) {
        const { node } = payload;

        const parent = draft.nodes[draft.newNode.parentId];
        const next = draft.nodes[payload.node.next] ?? null;

        if (
          parent.type === "CONDITIONAL" &&
          draft.newNode.conditionValue !== undefined
        ) {
          // find and add node to parent correct "branch" true or false
          parent.config.conditions.find(
            ({ value }) => value === draft.newNode.conditionValue
          ).next = node.id;
        } else if (parent.type === "FOREACH" && draft.newNode.inForeach) {
          parent.config.foreach.next = node.id;
        } else {
          parent.next = node.id;
          if (next) {
            next.parent = node.id;
          }
        }

        draft.nodes[node.id] = node;
      } else {
        // UPDATE GIVEN NODE
        draft.nodes[payload.id] = payload.node;
      }

      draft.touched = true;
      draft.newNode = null;
      draft.showNode = null;
      return;
    }
    case "toggleNewNode": {
      draft.newNode = payload ?? null;
      draft.showNode = null;
      return;
    }
    case "toggleNode": {
      draft.showNode = payload?.node ?? null;
      draft.newNode = null;
      return;
    }
    case "setTouched": {
      draft.touched = payload;
      return;
    }
    case "testRun": {
      draft.testRun = {
        node: payload.node
      };
      return;
    }
    case "closeTestRun": {
      draft.testRun = null;
      return;
    }
    case "updateNodes": {
      draft.nodes = payload;
      return;
    }
    default:
      throw new Error(`Action type ${type} not implemented.`);
  }
}

// Helper function to recursively collect id in a branch
function collectIds(nodes, currentNodeId, endNodeId, ids = []) {
  const node = nodes[currentNodeId];
  if (!node || (endNodeId && endNodeId === currentNodeId)) {
    return ids;
  }

  ids.push(node.id);

  if (node.type === "CONDITIONAL") {
    ids = ids.concat(
      collectIds(nodes, node.config.conditions[0].next, endNodeId)
    );
    ids = ids.concat(
      collectIds(nodes, node.config.conditions[1].next, endNodeId)
    );
  } else if (node.type === "FOREACH") {
    ids = ids.concat(collectIds(nodes, node.config.foreach.next, endNodeId));
  } else {
    if (node.next) {
      ids = ids.concat(collectIds(nodes, node.next, endNodeId));
    }
  }

  return ids;
}

export default function WorkflowProvider({ workflow, children }) {
  const [state, dispatch] = useImmerReducer(reducer, {
    nodes: workflow.nodes,
    touched: false,
    newNode: null,
    showNode: null,
    testRun: null
  });

  // Collect columns and context extraction variables to display
  function getAvailableVariables(currentNodeId) {
    const { nodes } = state;
    const variables = [];
    // Create an object for autocompleting CodeEditor
    const autocomplete = {
      columns: {},
      context: {}
    };

    // get variables from columns
    if (workflow.channel.columns?.length > 0) {
      workflow.channel.columns.forEach(column => {
        variables.push(`columns.${column.name}`);
        autocomplete.columns[column.name] = "";
      });
    }

    //get previous nodes stopping at current nodeId
    const firstNodeId = nodes.channel.next;
    const previousIds = collectIds(nodes, firstNodeId, currentNodeId);

    let lastResponseAdded = false;
    for (const id of previousIds) {
      const node = nodes[id];
      if (node.type === "HTTP") {
        node.config.extractors.forEach(extractor => {
          variables.push(`context.${extractor.name}`);
          autocomplete.context[extractor.name] = "";
        });
        if (!lastResponseAdded) {
          variables.push("context.lastResponse.status");
          variables.push("context.lastResponse.success");
          autocomplete.context.lastResponse = {
            status: 200,
            success: true
          };
          lastResponseAdded = true;
        }
      }
    }

    return { variables, autocomplete };
  }

  function updateNode(id, node) {
    dispatch({ type: "insertOrUpdateNode", payload: { id, node } });
  }

  async function saveWorkflow() {
    try {
      await WorkflowApi.updateVersion(workflow.id, workflow.versionId, {
        nodes: state.nodes
      });
      dispatch({ type: "setTouched", payload: false });
    } catch (err) {
      throw err;
    }
  }

  function startTestRun(node) {
    dispatch({ type: "testRun", payload: { node } });
  }

  function closeTestRun() {
    dispatch({ type: "closeTestRun" });
  }

  function updateNodes(nodes) {
    dispatch({ type: "updateNodes", payload: nodes });
  }

  return (
    <WorkflowContext.Provider
      value={{
        ...state,
        id: workflow.id,
        name: workflow.name,
        channel: workflow.channel,
        subscriber: workflow.subscriber,
        company: workflow.company,
        version: workflow.version,
        versionId: workflow.versionId,
        isDeployed: workflow.versionId === workflow.currentVersion,
        dispatch,
        getAvailableVariables,
        saveWorkflow,
        updateNode,
        startTestRun,
        closeTestRun,
        updateNodes
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
}

export function useWorkflow() {
  return useContext(WorkflowContext);
}
