import React, { Component } from "react";
import UserAPI from "../api/User";
import { Button, Container, Form, Row, Card, Col } from "react-bootstrap";
import Auth from "../authentication/auth";
import { ToastContext } from "../contexts/ToastContext";
import { errorMessage } from "../utils/format";

export default class Login extends Component {
  static contextType = ToastContext;
  state = {
    username: "",
    password: ""
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  login = async event => {
    event.preventDefault();
    const { username, password } = this.state;
    try {
      const { token, role, login } = await UserAPI.login({
        login: username,
        password
      });
      Auth.authenticate(token, role, login);
      this.props.history.push({ pathname: "/channels" });
    } catch (err) {
      this.setState({
        password: ""
      });
      this.context.addToast("error", errorMessage(err), "Authentication error");
    }
  };

  render() {
    const { username, password } = this.state;
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <Card>
              <Card.Header className="border-bottom">
                <h5>Login</h5>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={this.login}>
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="username"
                      value={username}
                      placeholder="Username"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      required
                      value={password}
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Button
                    variant="inline-alpha"
                    type="submit"
                    className="btn-block"
                  >
                    Login
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
