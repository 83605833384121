import BaseAPI from "./BaseAPI";
import queryString from "query-string";
/**
 * Tracing API
 * @extends BaseAPI
 */
export default class Tracing extends BaseAPI {
  /**
   * Get all the tracings
   *
   * @param {Object} filters
   * @returns {Promise<Object>} All the tracings paginated
   */
  static all(filters = {}, cancelToken) {
    const query = queryString.stringify(filters, {
      skipEmptyString: true,
      skipNull: true
    });
    return this.execute("get", `/tracings?${query}`, undefined, cancelToken);
  }

  /**
   * Get a tracing by its id
   *
   * @param {String} id Id of the tracing
   * @returns {Promise<Object>} The tracinf
   */
  static get(id) {
    return this.execute("get", `/tracings/${id}`);
  }

  /**
   * Delete a tracing
   *
   * @param {String} id The tracing ID
   * @returns {Promise}
   */
  static delete(id) {
    return this.execute("delete", `/tracings/${id}`);
  }

  /**
   * Mass delete tracings
   *
   * @param {Array} ids Tracing ids
   * @returns {Promise}
   */
  static massDelete(ids) {
    return this.execute("post", `/tracings/massDelete`, { ids });
  }

  /**
   * Get channels in tracings
   *
   * @returns {Promise}
   */
  static channels() {
    return this.execute("get", "/tracings/channels");
  }

  /**
   * Get susbscribers in tracings
   *
   * @returns {Promise}
   */
  static subscribers() {
    return this.execute("get", "/tracings/subscribers");
  }

  /**
   * Get workflows in tracings
   *
   * @returns {Promise}
   */
  static workflows() {
    return this.execute("get", "/tracings/workflows");
  }

  /**
   * Get stats
   *
   * @returns {Promise}
   */
  static stats(filters) {
    const query = queryString.stringify(filters, {
      skipEmptyString: true,
      skipNull: true
    });

    return this.execute("get", `/tracings/stats?${query}`);
  }
}
