import React, { useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MdSave } from "react-icons/md";
import WorkflowAPI from "../../api/WorkflowAPI";
export default function VersionModal({
  workflowId,
  existingVersions = [],
  fromVersion,
  show,
  onClose,
  onNewVersion
}) {
  const versionRef = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    const version = versionRef.current.value;
    // Check version already exists
    if (existingVersions.includes(version)) {
      setError("Version already exists");
    } else {
      setLoading(true);

      try {
        await WorkflowAPI.createVersion(workflowId, version, fromVersion);
        onNewVersion();
        onClose();
        setError(false);
      } catch (err) {
        setError(err.response?.data?.message ?? err.message);
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Workflow version</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Version</Form.Label>
            <Form.Control
              type="text"
              ref={versionRef}
              placeholder="0.0.1"
              pattern="[0-9]+\.{1}[0-9]+\.{1}[0-9]+"
              isInvalid={error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              Version need to respect semver semantic (major).(minor).(patch)
              (Ex: 0.0.1)
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="inline-danger" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="inline-success" disabled={loading}>
            <MdSave />
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
