import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Row,
  Table,
  Modal
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import UserApi from "../../api/User";
import { formatLongDate } from "../../utils/format";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  let deletingUser = useRef(null);

  const fetchUsers = async () => {
    const users = await UserApi.all();
    setUsers(users);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const askDeleteConfirm = user => {
    deletingUser.current = user;
    setShowConfirm(true);
  };

  const closeDeleteConfirm = () => {
    deletingUser.current = null;
    setShowConfirm(false);
  };

  const deleteUser = async () => {
    await UserApi.delete(deletingUser.current.id);
    await fetchUsers();
    closeDeleteConfirm();
  };

  return (
    <Container>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Users</h5>
            </Col>
            <Col xs="auto">
              <Button as={Link} to="/users/create">
                <FaPlus /> New user
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{ padding: 0 }}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Login</th>
                <th>Last login</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>{user.login}</td>
                  <td>{user.lastLogin && formatLongDate(user.lastLogin)}</td>
                  <td>
                    <ButtonToolbar className="float-right">
                      <Button
                        as={Link}
                        to={`/users/edit/${user.id}`}
                        variant="inline-primary"
                      >
                        <FaEdit /> Edit
                      </Button>
                      &nbsp;
                      <Button
                        onClick={() => askDeleteConfirm(user)}
                        variant="inline-danger"
                      >
                        <FaTrashAlt /> Delete
                      </Button>
                    </ButtonToolbar>
                  </td>
                </tr>
              ))}
              {users.length === 0 && (
                <tr>
                  <td>
                    <h6>No users yet.</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={showConfirm} onHide={closeDeleteConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete user {deletingUser.current?.login} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirm}>
            Close
          </Button>
          <Button variant="danger" onClick={() => deleteUser()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
