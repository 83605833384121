import React, { Component } from "react";

import SubscriptionAPI from "../../api/Subscription";
import UserAPI from "../../api/User";
import { Container, Button, Modal } from "react-bootstrap";

import Auth from "../../authentication/auth";
import SubscriptionList from "./SubscriptionList";
import SubscriberList from "./SubscriberList";

export default class Subscriptions extends Component {
  state = {
    subscriptions: [],
    subscribers: [],
    showDeleteConfirm: false,
    deletingSubcription: null
  };

  login = Auth.getLogin();
  isAdmin = Auth.isAdmin();

  componentDidMount() {
    this.loadSubscriptions();
  }

  loadSubscriptions() {
    UserAPI.getSubscriptions().then(subscriptions =>
      this.setState({
        subscriptions: subscriptions.filter(
          ({ subscriber }) => subscriber.login === this.login || this.isAdmin
        ),
        subscribers: subscriptions.filter(
          ({ company }) => company.login === this.login || this.isAdmin
        )
      })
    );
  }

  executeAction = (action, subscriptionId) => {
    switch (action) {
      case "new":
        this.props.history.push({ pathname: "/subscriptions/create" });
        break;
      case "approve":
        this.approveSubscription(subscriptionId);
        break;
      case "reject":
        this.rejectSubscription(subscriptionId);
        break;
      case "edit":
        this.updateSubscription(subscriptionId);
        break;
      case "delete":
        this.askDeleteConfirm(subscriptionId);
        break;
      default:
        break;
    }
  };

  approveSubscription = subscriptionId => {
    SubscriptionAPI.approve(subscriptionId).then(() => {
      this.loadSubscriptions();
    });
  };

  rejectSubscription = subscriptionId => {
    SubscriptionAPI.reject(subscriptionId).then(() => {
      this.loadSubscriptions();
    });
  };
  updateSubscription = subscriptionId => {
    this.props.history.push({
      pathname: `/subscriptions/edit/${subscriptionId}`
    });
  };

  askDeleteConfirm = subscriptionId => {
    this.setState({
      showDeleteConfirm: true,
      deletingSubcription: subscriptionId
    });
  };

  closeDeleteConfirm = () => {
    this.setState({ showDeleteConfirm: false, deletingSubcription: null });
  };

  deleteSubscription = subscriptionId => {
    SubscriptionAPI.delete(subscriptionId).finally(() => {
      this.closeDeleteConfirm();
      this.loadSubscriptions();
    });
  };

  render() {
    const {
      subscriptions,
      subscribers,
      showDeleteConfirm,
      deletingSubcription
    } = this.state;

    return (
      <Container>
        <SubscriptionList
          subscriptions={subscriptions}
          onAction={this.executeAction}
          isAdmin={this.isAdmin}
        />
        <br />
        {!this.isAdmin && (
          <SubscriberList
            subscriptions={subscribers}
            onAction={this.executeAction}
          />
        )}
        <Modal show={showDeleteConfirm} onHide={this.closeDeleteConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this subscription ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeDeleteConfirm}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => this.deleteSubscription(deletingSubcription)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
