import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import CodeEditor from "../../CodeEditor";

export default function AvailableVariables({ node, variables }) {
  const [showVariables, setShowVariables] = useState(false);

  const close = () => {
    setShowVariables(false);
  };

  return (
    <>
      <Alert className="available-variables" style={{ paddingLeft: 0 }}>
        <Button
          variant="outline-primary"
          type="button"
          onClick={() => setShowVariables(true)}
        >
          See available variables
        </Button>
      </Alert>

      <Modal show={showVariables} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Available variables</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>One can use 3 types of variables in a workflow node : </p>
          <p>
            1. variables from the input channel of this workflow, called
            columns.xyz
          </p>
          <ul>
            {variables?.map((variable, index) => (
              <li key={index}>
                <b>{variable}</b>
              </li>
            ))}
          </ul>
          <p>
            {" "}
            NB: for channels set with the process-file-as-a-whole setting, all
            the lines of an input file are given at once in an array. In this
            case attributes are accessed with columns[0].xyz, columns[1].xyz,
            etc.
          </p>
          <p></p>
          <p>
            2. variables named context.xyz that are passed by previous nodes of
            type HTTP where an extractor has been set to create this value from
            its http response, or from a previous node using a statement like
            context.xyz = some value.
          </p>
          <p></p>
          <p>
            3. variables from one of the dictionaries of this account.
            Dictionaries are used for storing secrets (passwords etc.) and
            environment-specific values (urls for instance). Their values are
            used by syntaxes such as dictionaries[dictionary_name].xyz and set
            by the dictionaries menu.
          </p>

          {node.type === "HTTP" ? (
            <>
              <p></p>
              <p>
                <b>Example for defining a dynamic url</b> :{" "}
              </p>
              <p>{"https://api.test.com/products/{{ columns.productId }}"}</p>
              <p>
                <b>Example for defining a request header</b> : <br />
                <br />
                <CodeEditor
                  readOnly
                  code={JSON.stringify(
                    { "x-transaction-id": "{{ columns.tid }}" },
                    null,
                    2
                  )}
                  language="json"
                />
              </p>
              <p>
                <b>Example for defining a request body</b> : <br />
                <br />
                <CodeEditor
                  readOnly
                  code={JSON.stringify(
                    { type: "add", quantity: "{{ context.totalCart }}" },
                    null,
                    2
                  )}
                  language="json"
                />
              </p>
            </>
          ) : (
            <p>
              Example :
              <br />
              <CodeEditor
                readOnly
                code={`let myVariable = columns.id`}
                language="javascript"
              />
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
