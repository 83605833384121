import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import AnonymizeInput from "../../../AnonymizeInput";
import ExtractorPlayground from "./ExtractorPlayground";

export default class ExtractorForm extends Component {
  state = {
    showPlayground: false
  };

  toggleTry = () => {
    this.setState(({ showPlayground }) => ({
      showPlayground: !showPlayground
    }));
  };

  updatePath = path => {
    // Simulate path input change
    this.props.onChange({ target: { name: "path", value: path } });
    this.toggleTry();
  };

  render() {
    const {
      part,
      name,
      path,
      anonymize,
      onChange,
      children = null
    } = this.props;
    const { showPlayground } = this.state;
    return (
      <>
        <Form.Row style={{ alignItems: "flex-end" }}>
          <Col xs={2}>
            <Form.Label>From</Form.Label>
            <Form.Control
              as="select"
              required
              name="part"
              onChange={onChange}
              value={part}
            >
              <option value="body">Body</option>
              <option value="headers">Headers</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              onChange={onChange}
            />
          </Col>
          <Col>
            <Form.Label>Path</Form.Label>
            <Form.Control
              required
              type="text"
              name="path"
              value={path}
              placeholder="Path"
              onChange={onChange}
            />
          </Col>
          <Col>
            <AnonymizeInput
              name="anonymize"
              checked={anonymize}
              onChange={onChange}
            />
          </Col>
          {children}
          <Col xs="auto">
            <Button
              size="lg"
              variant="inline-success"
              type="button"
              style={{ fontWeight: 400 }}
              onClick={this.toggleTry}
            >
              Test it
            </Button>
          </Col>
        </Form.Row>
        {showPlayground && (
          <ExtractorPlayground
            path={path}
            onClose={this.toggleTry}
            onPathSave={this.updatePath}
          />
        )}
      </>
    );
  }
}
