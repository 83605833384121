import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TracingAPI from "../api/Tracing";
import ChannelAPI from "../api/Channel";
import { addMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Stats.scss";
import Loader from "./Loader";

const PALETTE = [
  "#05668D",
  "#9BC53D",
  "#4DAA57",
  "#171D1C",
  "#D1BEB0",
  "#FA7921",
  "#E55934",
  "#A53860",
  "#B892FF"
];

export default class Stats extends React.Component {
  state = {
    owners: [],
    ownerNodes: [],
    subscriberNodes: [],
    from: new Date().toISOString(),
    to: new Date().toISOString(),
    selectedOwner: null,
    loading: true
  };

  palette = [...PALETTE];

  componentDidMount() {
    this.fetchOwners();
    this.fetchStats();
  }

  onDateChange = (name, date) => {
    let dateString = null;
    if (date instanceof Date) {
      // Set date to UTC
      dateString = addMinutes(
        date,
        Math.abs(date.getTimezoneOffset())
      ).toISOString();
    }

    this.setState({ [name]: dateString });
  };

  selectOwner = ({ target }) => {
    const owner = target.value;
    this.setState({ selectedOwner: owner === "" ? null : owner });
  };

  getRandomColor() {
    const randomIndex = Math.floor(Math.random() * this.palette.length);
    const randomColor = this.palette[randomIndex] || "#000";
    this.palette.splice(randomIndex, 1);
    return randomColor;
  }

  async fetchOwners() {
    const channels = await ChannelAPI.all();
    let seen = new Set();
    const owners = channels
      .filter(channel => {
        const login = channel.company.login;
        return seen.has(login) ? false : seen.add(login);
      })
      .map(({ id, company }) => ({
        id: company.id,
        login: company.login
      }));

    this.setState({ owners });
  }

  async fetchStats() {
    const { from, to, selectedOwner } = this.state;
    const { owners = [], subscribers = [] } = await TracingAPI.stats({
      from,
      to,
      owner: selectedOwner
    });

    this.palette = [...PALETTE]; // reset palette
    this.setState({
      ownerNodes: this.ownerNodes(owners),
      subscriberNodes: this.subscriberNodes(subscribers),
      loading: false
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true }, this.fetchStats);
  };

  ownerNodes(ownerStats) {
    return ownerStats.map((owner, index) => {
      const y = index * 12;
      return {
        id: owner._id,
        text: owner.login,
        x: 10,
        y,
        color: this.getRandomColor(),
        callCount: owner.count,
        subscribersId: owner.subscribersId ?? []
      };
    });
  }

  subscriberNodes(subscribersStats) {
    return subscribersStats.map((subscriber, index) => {
      const y = index * 12;
      return {
        id: subscriber._id,
        text: subscriber.login,
        x: 90,
        y,
        color: this.getRandomColor(),
        callCount: subscriber.count
      };
    });
  }

  render() {
    const {
      ownerNodes,
      subscriberNodes,
      from,
      to,
      loading,
      selectedOwner,
      owners
    } = this.state;

    return (
      <>
        <Container>
          <Form onSubmit={this.onSubmit}>
            <Row className="justify-content-center">
              <Col>
                <Form.Group>
                  <Form.Label>From</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={Date.parse(from) || ""}
                    onChange={date => this.onDateChange("from", date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>To</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={Date.parse(to) || ""}
                    onChange={date => this.onDateChange("to", date)}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Channel owner</Form.Label>
                  <Form.Control
                    as="select"
                    name="owner"
                    onChange={this.selectOwner}
                    className={!selectedOwner ? "select-default" : ""}
                    value={selectedOwner || ""}
                  >
                    <>
                      <option value="">Select a channel owner</option>
                      {owners.map(owner => (
                        <option key={owner.id} value={owner.id}>
                          {owner.login}
                        </option>
                      ))}
                    </>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button type="submit" variant="primary">
                Search
              </Button>
            </Row>
          </Form>
        </Container>
        <Container fluid>
          {loading ? (
            <Container style={{ display: "flex", justifyContent: "center" }}>
              <Loader />
            </Container>
          ) : ownerNodes.length === 0 || subscriberNodes.length === 0 ? (
            <h5 className="text-center pt-5">
              No stats for selected date slot
            </h5>
          ) : (
            <svg className="stats" viewBox="0 0 100 100">
              <g transform="translate(25, 5) scale(0.5)">
                {ownerNodes.map(owner => {
                  return (
                    <g key={owner.id}>
                      <text
                        textAnchor="right"
                        className="user"
                        x={owner.x - Math.round((owner.text.length * 1.5) / 2)}
                        y={owner.y - 4}
                      >
                        {owner.text}
                      </text>
                      <g transform={`translate(${owner.x}, ${owner.y})`}>
                        <rect
                          fill="#FFF"
                          stroke={owner.color}
                          className="count"
                          x="-5"
                          y="-2"
                          width="10"
                          height="5"
                          strokeWidth="0.1"
                        />
                        <text
                          y={1.5}
                          className="subscriber"
                          textAnchor="middle"
                        >
                          {owner.callCount}
                        </text>
                      </g>
                      {owner.subscribersId.map(subscriberId => {
                        const subscriber = subscriberNodes.find(
                          ({ id }) => id === subscriberId
                        );

                        return (
                          <path
                            key={subscriberId}
                            stroke={owner.color}
                            className="link"
                            d={`M${owner.x + 5},${owner.y}C${
                              (owner.x + subscriber.x) / 2
                            },${owner.y} ${(owner.x + subscriber.x) / 2},${
                              subscriber.y
                            } ${subscriber.x - 3},${subscriber.y}`}
                          />
                        );
                      })}
                    </g>
                  );
                })}
                {subscriberNodes.map(subscriber => (
                  <g key={subscriber.id}>
                    <text
                      className="subscriber"
                      x={
                        subscriber.x -
                        Math.round((subscriber.text.length * 1.5) / 2)
                      }
                      y={subscriber.y - 4}
                    >
                      {subscriber.text}
                    </text>
                    <g
                      transform={`translate(${subscriber.x}, ${subscriber.y})`}
                    >
                      <rect
                        fill="#FFF"
                        stroke={subscriber.color}
                        className="count"
                        x="-5"
                        y="-2"
                        width="10"
                        height="5"
                        strokeWidth="0.1"
                      />
                      <text y={1.5} className="subscriber" textAnchor="middle">
                        {subscriber.callCount}
                      </text>
                    </g>
                  </g>
                ))}
              </g>
            </svg>
          )}
        </Container>
      </>
    );
  }
}
