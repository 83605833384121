import React from "react";
import { Badge } from "react-bootstrap";
import { FaCheck, FaTimes, FaClock } from "react-icons/fa";

export default function SuccessBadge({ success, error }) {
  if (error === "started") {
    return (
      <Badge pill className="badge-soft-warning">
        <FaClock /> Started
      </Badge>
    );
  }
  return success ? (
    <Badge pill className="badge-soft-success">
      <FaCheck /> Success
    </Badge>
  ) : (
    <Badge pill className="badge-soft-danger">
      <FaTimes /> Failed
    </Badge>
  );
}
