import React from "react";
import { Badge } from "react-bootstrap";

export default function StatusBadge({ status }) {
  const badgeClass =
    status > 400 || status === null
      ? "badge-soft-danger"
      : "badge-soft-success";
  return (
    <Badge pill className={badgeClass}>
      {status ? status : "No response"}
    </Badge>
  );
}
