import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaBullhorn } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import Help from "../../../Help";
import SimpleNextNode from "./SimpleNextNode";
import LegacyCodeEditor from "../../../LegacyCodeEditor";

const handlebarsExample = `{
  "message": "{{ error.message }}",
  "stack": "{{ error.stack }}"
}`;

const ErrorHandlingHelp = (
  <Help title="Error handling" placement="bottom" maxWidth="400px">
    <p>
      This workflow branch is executed when an error occured on the main
      workflow branch.
    </p>
    <p>
      The variable <strong>error</strong> will be available in the execution
      context of this flow.
    </p>
    <p>
      For example, when templating with{" "}
      <a
        href="https://handlebarsjs.com/guide/#what-is-handlebars"
        target="_blank"
        rel="noopener noreferrer"
      >
        Handlebars
      </a>
      :
    </p>
    <LegacyCodeEditor readOnly code={handlebarsExample} />
  </Help>
);

export default function ChannelNode({ node, startIcon, title }) {
  const { nodes, channel, company, dispatch } = useWorkflow();
  const nextNode = node.next ? nodes[node.next] : null;
  return (
    <>
      <Card
        className="node p-0"
        onClick={() => dispatch({ type: "toggleNode", payload: { node } })}
      >
        <Card.Body className="node-body">
          <Row className="align-items-center">
            <div className="node-icon">
              {startIcon ? startIcon : <FaBullhorn />}
            </div>
            <Col className="node-name">
              <b>
                {title ? (
                  title
                ) : (
                  <>
                    {company?.login ?? <i>Unknown</i>}-
                    {channel?.name ?? <i>Unknown</i>}
                  </>
                )}
              </b>
            </Col>
            {node.id === "channel" && (
              <div className="node-settings">
                <MdSettings
                  onClick={() =>
                    dispatch({ type: "toggleNode", payload: { node } })
                  }
                />
              </div>
            )}
            {node.id === "global_error_catch" && (
              <div className="node-settings">{ErrorHandlingHelp}</div>
            )}
          </Row>
        </Card.Body>
      </Card>
      <SimpleNextNode currentNodeId={node.id} nextNode={nextNode} />
    </>
  );
}
