import React, { useState, useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";
import DataSourceApi from "./../../../../../api/Datasource";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

const FtpConnectionMessage = ({ ftpConnection }) => {
  switch (ftpConnection.status) {
    case "loading":
      return <span style={{ color: "#598efb" }}>{ftpConnection.message}</span>;
    case "ok":
      return <span style={{ color: "#00864e" }}>{ftpConnection.message}</span>;
    case "error":
      return <span style={{ color: "#e63757" }}>{ftpConnection.message}</span>;
    default:
      return null;
  }
};

export default function FtpNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();

  const [ftpConnection, setFtpConnection] = useState({ status: "inactive" });

  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);

  const { variables } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );
  const updateConfig = (key, value) => {
    setConfig(config => ({ ...config, [key]: value }));
  };

  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };

  const updateRetryOptions = ({ target }) => {
    setConfig(config => ({
      ...config,
      retryOptions: {
        ...config.retryOptions,
        [target.name]: target.value
      }
    }));
  };

  const testFtpConnection = async () => {
    setFtpConnection({ status: "loading", message: "Testing connection..." });
    try {
      await DataSourceApi.testFtpConnection({
        ftpHost: config.server,
        ftpPort: config.port,
        ftpUsername: config.user,
        ftpPassword: config.password,
        ftpPrivateKey: config.privateKey
      });
      setFtpConnection({ status: "ok", message: "Connection ok" });
    } catch (err) {
      setFtpConnection({
        status: "error",
        message: err.response?.data?.message || "Something bad happened"
      });
    }
  };

  const {
    server,
    port = 21,
    content,
    filePath,
    user,
    password,
    privateKey,
    overwrite,
    retryActive = false,
    retryOptions = {},
    continueWorkflow = false,
    timeout = DEFAULT_REQUEST_TIMEOUT
  } = config;
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Form.Row>
            <Col>
              <Button
                className="btn"
                type="button"
                disabled={
                  !server ||
                  !port ||
                  !user ||
                  (!password && !privateKey) ||
                  ftpConnection.status === "loading"
                }
                onClick={() => testFtpConnection()}
              >
                <FaPlay /> Test connection
              </Button>
            </Col>
            <Col>
              <FtpConnectionMessage ftpConnection={ftpConnection} />
            </Col>
          </Form.Row>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Server*</Form.Label>
              <Form.Control
                required
                type="text"
                name="server"
                title="Must be a valid server ip or dns name"
                placeholder="myremoteserver{{context.serverId}}.company.com"
                onChange={({ target }) => updateConfig("server", target.value)}
                value={server}
              />
            </Form.Group>
          </Form.Row>
          <hr />
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Port*</Form.Label>
              <Form.Control
                required
                type="text"
                name="port"
                title="Must be a valid tcp port, typically 21 for FTP and 22 for sftp"
                placeholder="21"
                onChange={({ target }) => updateConfig("port", target.value)}
                value={port}
              />
            </Form.Group>
          </Form.Row>
          <hr />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Content</Form.Label>
              <Form.Control
                type="text"
                name="content"
                title="The content of the file sent to the remote server. Typically use the name of a variable that contains content to send. If left empty, the variable context.ftpOutputFileContent value will be used as raw file contents."
                placeholder="{{context.myContentVariable}}"
                onChange={({ target }) => updateConfig("content", target.value)}
                value={content}
              />
            </Form.Group>
          </Form.Row>
          <hr />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Remote file path</Form.Label>
              <Form.Control
                required
                type="text"
                name="filePath"
                title="The path and name of the file on the remote server. Typically use the remote dir name and file name. Variables can be used."
                placeholder="inputdir/file{{context.mySuffixVariable}}.xml"
                onChange={({ target }) =>
                  updateConfig("filePath", target.value)
                }
                value={filePath}
              />
            </Form.Group>
          </Form.Row>
          <hr />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>user</Form.Label>
              <Form.Control
                required
                type="text"
                name="user"
                title="The login user on the remote server. Variables can be used."
                placeholder="myname{{context.myIdentityVariable}}"
                onChange={({ target }) => updateConfig("user", target.value)}
                value={user}
              />
            </Form.Group>
          </Form.Row>
          <hr />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                {privateKey ? "Pass phrase" : "Password*"}{" "}
              </Form.Label>
              <Form.Control
                required={!privateKey}
                type="password"
                name="password"
                title="The login password on the remote server. Variables can be used."
                placeholder={privateKey ? "my-pass-phrase" : "my-passwd"}
                onChange={({ target }) =>
                  updateConfig("password", target.value)
                }
                value={password}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                {password ? "Private key" : "Private key*"}
              </Form.Label>
              <Form.Control
                as="textarea"
                required={!password}
                type="password"
                name="privateKey"
                title="The private key on the remote server. Variables can be used."
                onChange={({ target }) =>
                  updateConfig("privateKey", target.value)
                }
                value={privateKey}
              />
            </Form.Group>
          </Form.Row>

          <hr />

          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="overwrite"
              name="overwrite"
              label="Overwrite file if exists on remote server"
              checked={overwrite}
              onChange={({ target }) =>
                updateConfig("overwrite", target.checked)
              }
            />
            <Help title="Overwrite remote file">
              <p>
                Should the workflow replace a previously existing remote file
                the has the same name ? One can also use dynamic filename suffix
                to avoid overwrite.
              </p>
            </Help>
          </Form.Row>

          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="continue-workflow"
              name="continueWorkflow"
              label="Continue workflow if file sending failed"
              checked={continueWorkflow}
              onChange={({ target }) =>
                updateConfig("continueWorkflow", target.checked)
              }
            />
            <Help title="Continue workflow">
              <p>
                By default, the workflow will stop if a file sending fails. By
                activating this option, workflow will continue even if this file
                sending returns an error. After the sending an object named
                lastResponse will be available in the context containing status
                and success of last sending attempt.
              </p>
              <LegacyCodeEditor
                code={JSON.stringify(
                  {
                    context: { lastResponse: { success: false } }
                  },
                  null,
                  2
                )}
                readOnly
              />
            </Help>
          </Form.Row>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
