import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row
} from "react-bootstrap";
import { FaCode } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import WorkflowTracingAPI from "../../../api/WorkflowTracingAPI";
import { formatLongDate } from "../../../utils/format";
import Loader from "../../Loader";
import LegacyCodeEditor from "./../../LegacyCodeEditor";
import WorkflowColumnsRow from "./WorkflowColumnsRow";
import WorkflowHistoryRow from "./WorkflowHistoryRow";
import SuccessBadge from "./../SuccessBadge";
import TimeBadge from "./../TimeBadge";

function getHistory(history, startParent) {
  if (!startParent) {
    return [];
  }
  const h = history.find(el => el.nodeParent === startParent);

  if (!h) {
    return [];
  }

  return [h, ...getHistory(history, h.nodeId)];
}

export default function WorkflowTracing({ match }) {
  const [tracing, setTracing] = useState(null);
  const [detail, setDetail] = useState("");
  const [channelHistory, setChannelHistory] = useState([]);
  const [errorHandlingHistory, setErrorHandlingHistory] = useState([]);

  useEffect(() => {
    const tracingId = match.params.id;
    async function fetchTracing() {
      const tracing = await WorkflowTracingAPI.get(tracingId);
      setTracing(tracing);

      const globalCatchHistory = getHistory(
        tracing.history,
        "global_error_catch"
      );

      setChannelHistory(
        tracing.history.filter(el => !globalCatchHistory.includes(el))
      );

      setErrorHandlingHistory(globalCatchHistory);
    }
    fetchTracing();
  }, [match.params.id]);

  const showDetail = () => {
    setDetail(JSON.stringify(tracing, null, 2));
  };

  if (!tracing) {
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item active>
          <NavLink to="/monitoring">Monitoring</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Tracing</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={12}>
          <Card className="mb-3">
            <Card.Header className="border-bottom">
              <Row>
                <Col>
                  <h5 className="mb-2">Tracing detail</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={showDetail}
                    size="sm"
                    variant="inline-primary"
                    className="mr-3"
                  >
                    <FaCode />
                    &nbsp; Raw Tracing
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <p>
                <b>Workflow: </b>
                {tracing.workflowName}
                <Badge pill className="ml-1 badge-primary ">
                  {tracing.workflowVersion}
                </Badge>
              </p>
              <p>
                <b>Date: </b>
                {formatLongDate(tracing.startedAt)}
              </p>
              <p>
                <b className="mr-2">Total time:</b>
                <TimeBadge
                  startedAt={tracing.startedAt}
                  endedAt={tracing.endedAt}
                />
              </p>
              <p>
                <b className="mr-2">Status:</b>
                <SuccessBadge success={tracing.success} />
              </p>
              {tracing.response?.status && (
                <p>
                  <b className="mr-2">Response status:</b>
                  <Badge
                    pill
                    variant={
                      tracing.response.status < 300 ? "primary" : "danger"
                    }
                  >
                    {tracing.response.status}
                  </Badge>
                </p>
              )}
            </Card.Body>
            {tracing.error && (
              <Card.Footer style={{ background: "#FAD7DD" }}>
                <b>Last Error: </b>
                <pre style={{ color: "#e63757" }}>{tracing.error}</pre>
              </Card.Footer>
            )}
          </Card>

          <Card className="mb-3">
            <Card.Header className="border-bottom">
              <h5>From channel :</h5>
            </Card.Header>
            <Card.Body>
              <p>
                <b>Name: </b>
                {tracing.channel ? tracing.channel.name : <i>Unknown</i>}
              </p>
              <p>
                <b>Company: </b>
                {tracing.channel ? (
                  tracing.channel.company.login
                ) : (
                  <i>Unknown</i>
                )}
              </p>
              {tracing.columns && (
                <>
                  <WorkflowColumnsRow
                    columns={JSON.stringify(tracing.columns, null, 2)}
                  />
                </>
              )}
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Header className="border-bottom">
              <h5>History</h5>
            </Card.Header>
            <Card.Body className="p-0" style={{ borderBottom: 0 }}>
              {channelHistory.length > 0 ? (
                <div className="history">
                  {channelHistory.map(row => (
                    <WorkflowHistoryRow
                      key={row._id}
                      history={row}
                      nodeType={row.nodeType}
                    />
                  ))}
                </div>
              ) : (
                <i className="p-3">No executions.</i>
              )}
            </Card.Body>
          </Card>
          {errorHandlingHistory.length > 0 && (
            <Card className="mb-5">
              <Card.Header className="border-bottom">
                <h5>Error handling history</h5>
              </Card.Header>
              <Card.Body className="p-0" style={{ borderBottom: 0 }}>
                <div className="history">
                  {errorHandlingHistory.map(row => (
                    <WorkflowHistoryRow
                      key={row._id}
                      history={row}
                      nodeType={row.nodeType}
                    />
                  ))}
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      <Modal show={detail !== ""} onHide={() => setDetail("")} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegacyCodeEditor readOnly mode="json" height="500px" code={detail} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDetail("")}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
