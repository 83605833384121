import { differenceInMilliseconds, parseISO } from "date-fns";
import React, { useState } from "react";
import { Badge, Col, Collapse, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp, FaRegClock } from "react-icons/fa";
import { truncate, formatLongDate } from "../../utils/format";
import LegacyCodeEditor from "../LegacyCodeEditor";
import "./HistoryRow.css";
import StatusBadge from "./StatusBadge";

export default function HistoryRow({ history }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <Row
        className="history-row no-gutters  border-bottom py-3 px-3 position-relative"
        onClick={() => setShowMore(!showMore)}
      >
        <Col md="auto" className="pr-3">
          <StatusBadge status={history.responseStatus} />
        </Col>
        {history.callName && (
          <Col xs={2}>
            <b>{history.callName}</b>
          </Col>
        )}
        <Col className="pr-3">
          <code>
            {history.method.toUpperCase()} {history.url}
          </code>
        </Col>
        {history.error && (
          <Col md="auto" className="pr-3">
            <pre style={{ color: "#e63757" }}>
              {truncate(history.error, 50)}
            </pre>
          </Col>
        )}
        <Col md="auto" className="pr-3">
          <Badge pill variant="primary">
            <FaRegClock />{" "}
            {differenceInMilliseconds(
              parseISO(history.endedAt),
              parseISO(history.startedAt)
            )}
            ms
          </Badge>
        </Col>
        <Col md="auto">{formatLongDate(history.startedAt)}</Col>
        <Col md="auto" className="pl-2">
          {showMore ? (
            <FaAngleUp className="history-row-more" />
          ) : (
            <FaAngleDown className="history-row-more" />
          )}
        </Col>
      </Row>
      <Collapse in={showMore}>
        <Row className="no-gutters align-items-center border-bottom pt-2 pb-4 px-3">
          {history.error && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Error: </b>
              <pre style={{ color: "#e63757" }}>{history.error}</pre>
            </Col>
          )}
          <Col xs="12" className="pb-3">
            <b className="d-block mb-2">Headers: </b>
            <LegacyCodeEditor
              readOnly={true}
              showGutter={false}
              mode="json"
              code={JSON.stringify(history.headers, null, 2)}
            />
          </Col>
          <Col xs="12" className="pb-3">
            <b className="d-block mb-2">Body: </b>
            <LegacyCodeEditor
              readOnly={true}
              showGutter={false}
              mode="json"
              code={JSON.stringify(history.body, null, 2)}
            />
          </Col>
          {history.response && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Response: </b>
              <LegacyCodeEditor
                readOnly={true}
                code={JSON.stringify(history.response, null, 2)}
              />
            </Col>
          )}
          {history.extractedContext && (
            <Col xs="12">
              <b className="d-block mb-2">Extracted context: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={false}
                mode="json"
                code={JSON.stringify(history.extractedContext, null, 2)}
              />
            </Col>
          )}
        </Row>
      </Collapse>
    </>
  );
}
