import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import prefilledColumns from "./prefilledColumns.json";
import Columns from "../columns/Columns";

export default class SaasChannelForm extends Component {
  selectSassProvider = event => {
    this.props.onMetaChange(event);
    // Prefill columns with given Saas
    this.props.onChange({
      target: {
        name: "columns",
        value: prefilledColumns[event.target.value] || []
      }
    });
  };
  render() {
    const { channel, onChange } = this.props;
    const { meta = {} } = channel;
    return (
      <>
        <Form.Group xs={3}>
          <Form.Label>SaaS*</Form.Label>
          <Form.Control
            as="select"
            required
            name="sassProvider"
            className={!meta.sassProvider ? "select-default" : ""}
            onChange={this.selectSassProvider}
            value={meta.sassProvider}
          >
            <option value="">Select a SaaS</option>
            <option value="colisweb">Colisweb</option>
          </Form.Control>
        </Form.Group>
        {meta.sassProvider && (
          <>
            <Columns columns={channel.columns} onChange />
            <Form.Group>
              <Form.Label>Example of expected response</Form.Label>
              <LegacyCodeEditor
                code={channel.response}
                onChange={value =>
                  onChange({
                    target: { name: "response", value }
                  })
                }
              />
            </Form.Group>
          </>
        )}
      </>
    );
  }
}
