import React from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import UserAPI from "../api/User";
import UploadAPI from "../api/Upload";
import Auth from "../authentication/auth";
import { ToastContext } from "../contexts/ToastContext";
import { errorMessage } from "../utils/format";

export default class Upload extends React.Component {
  static contextType = ToastContext;

  state = {
    channels: [],
    uploadInput: null,
    channel: ""
  };

  isAdmin = Auth.isAdmin();

  componentDidMount() {
    UserAPI.getChannels().then(channels => {
      this.setState({
        channels: channels.filter(({ type }) => type === "file")
      });
    });
  }

  handleFileChange = uploadInput => {
    this.setState({ uploadInput });
  };

  handleChannelChange = channel => {
    this.setState({ channel });
  };

  handleUploadImage = event => {
    event.preventDefault();

    const data = new FormData();
    data.append("file", this.state.uploadInput);
    data.append("filename", "file" + Date.now());
    data.append("channel", this.state.channel);

    UploadAPI.upload(data)
      .then(data => {
        this.context.addToast("success", data.message);
      })
      .catch(err => {
        this.context.addToast(
          "error",
          errorMessage(err),
          "Failed to upload file"
        );
      })
      .finally(() => {
        this.setState({
          channel: "",
          uploadInput: null
        });
        this.uploadInput.value = "";
      });
  };

  render() {
    const { channel, channels } = this.state;
    return (
      <Container>
        <Card>
          <Card.Header className="border-bottom">
            <h5>Upload</h5>
          </Card.Header>

          <Card.Body>
            <Form onSubmit={this.handleUploadImage}>
              <Form.Group>
                <Form.Label>Channel</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="channel"
                  className={!channel ? "select-default" : ""}
                  onChange={event =>
                    this.handleChannelChange(event.target.value)
                  }
                  value={channel}
                >
                  <option value="">Select a channel</option>
                  {channels.map(channel => (
                    <option key={channel.id} value={channel.id}>
                      {this.isAdmin ? `${channel.company.login} - ` : ""}
                      {channel.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  required
                  ref={ref => (this.uploadInput = ref)}
                  placeholder="Enter your password"
                  onChange={event =>
                    this.handleFileChange(event.target.files.item(0))
                  }
                />
              </Form.Group>
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
