import React, { Component } from "react";
import Dashboard from "../../api/Dashboard";

import { Button, Card, Container, Form } from "react-bootstrap";
import { ToastContext } from "../../contexts/ToastContext";
import { errorMessage } from "../../utils/format";

export default class EditTracing extends Component {
  static contextType = ToastContext;
  state = {
    url: null,
    headers: null
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  updateSettings = event => {
    event.preventDefault();
    const body = {
      url: this.state.url,
      headers: JSON.parse(this.state.headers)
    };

    Dashboard.updateSettings(body)
      .then(data => {
        this.context.addToast("success", data.message);
      })
      .catch(err => {
        this.context.addToast("error", errorMessage(err));
      })
      .finally(() => {});
  };

  async componentDidMount() {
    const settings = await Dashboard.getSettings();
    this.setState({ url: settings?.url });
    if (settings && settings.headers)
      this.setState({ headers: JSON.stringify(settings.headers) });
  }

  render() {
    return (
      <Container>
        <Card>
          <Card.Header>
            <h5>{`Edit dashboard settings`}</h5>
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={this.updateSettings}>
              <Form.Group>
                <Form.Control
                  required="true"
                  type="text"
                  name="url"
                  value={this.state.url}
                  placeholder="Url"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required="false"
                  type="textarea"
                  name="headers"
                  value={this.state.headers}
                  placeholder='{"header1":"value1", "header2":"value2"...}'
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="btn-block">
                {"Update"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
