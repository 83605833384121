import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

export default class SendEmailRecipientForm extends Component {
  state = {};

  render() {
    const { recipient, onChange, onDelete, index } = this.props;
    return (
      <>
        <Form.Row style={{ alignItems: "flex-end" }}>
          <Col xs={10}>
            <Form.Control
              required
              type="text"
              name="recipient"
              value={recipient}
              placeholder="recipient"
              onChange={onChange}
            />
          </Col>
          {!!index && (
            <Button
              type="button"
              size="lg"
              variant="inline-danger"
              onClick={onDelete}
            >
              <FaTrash />
            </Button>
          )}
        </Form.Row>
      </>
    );
  }
}
