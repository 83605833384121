import React from "react";
import CodeNodeForm from "./CodeNodeForm";
import ConditionalNodeForm from "./ConditionalNodeForm";
import ForeachNodeForm from "./ForeachNodeForm";
import HttpNodeForm from "./HttpNodeForm";
import FormNodeForm from "./FormNodeForm";
import FtpNodeForm from "./FtpNodeForm";
import BusinessDashboardNodeForm from "./BusinessDashboardNodeForm";
import ResponseNodeForm from "./ResponseNodeForm";
import WaitNodeForm from "./WaitNodeForm";
import EventNodeForm from "./EventNodeForm";
import SendEmailNodeForm from "./SendEmailNodeForm";
import ChannelNodeForm from "./ChannelNodeForm";

export default function NodeForm({ node, onClose }) {
  if (!node) {
    return null;
  }
  let Form;
  switch (node.type) {
    case "CHANNEL":
      Form = ChannelNodeForm;
      break;
    case "HTTP":
      Form = HttpNodeForm;
      break;
    case "FORM":
      Form = FormNodeForm;
      break;
    case "FTP":
      Form = FtpNodeForm;
      break;
    case "BUSINESS":
      Form = BusinessDashboardNodeForm;
      break;
    case "CODE":
      Form = CodeNodeForm;
      break;
    case "RESPONSE":
      Form = ResponseNodeForm;
      break;
    case "CONDITIONAL":
      Form = ConditionalNodeForm;
      break;
    case "FOREACH":
      Form = ForeachNodeForm;
      break;
    case "WAIT":
      Form = WaitNodeForm;
      break;
    case "EVENT":
      Form = EventNodeForm;
      break;
    case "SEND_EMAIL":
      Form = SendEmailNodeForm;
      break;
    default:
      break;
  }

  return <Form node={node} onClose={onClose} />;
}
