import copy from "clipboard-copy";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import ChannelAPI from "./../../api/Channel";

export default function SubscriptionCodeModal({ channelId, onClose }) {
  const [subscriptionCode, setSubscriptionCode] = useState(null);

  useEffect(() => {
    async function fetchCode() {
      const code = await ChannelAPI.createSubscriptionCode(channelId);
      setSubscriptionCode(code);
    }
    fetchCode();
  }, [channelId]);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Subscription code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You can share this code to another user allowing him to subscribe to
          this channel.
        </p>
        <p className="d-flex align-items-starts">
          <pre style={{ fontSize: "1.4em" }}>{subscriptionCode}</pre>
          <Button
            type="button"
            className="ml-2"
            onClick={() => copy(subscriptionCode)}
            variant="light"
          >
            <FaCopy />
          </Button>
        </p>
        Only one code at a time, valid once and for maximum 24 hours.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
