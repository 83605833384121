import React, { useState, createContext, useContext } from "react";
import { v1 } from "uuid";
export const ToastContext = createContext({
  toasts: [],
  addToast: () => {},
  clearToast: () => {}
});

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  function addToast(type, message, title = null, additional = null) {
    setToasts([...toasts, { id: v1(), type, message, title, additional }]);
  }
  function clearToast(id) {
    setToasts(toasts.filter(t => t.id !== id));
  }
  return (
    <ToastContext.Provider value={{ toasts, addToast, clearToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export function useToasts() {
  return useContext(ToastContext);
}
