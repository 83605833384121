import React, { Component } from "react";
import { Form, Col, InputGroup, Button, ButtonToolbar } from "react-bootstrap";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import Columns from "./../columns/Columns";
import { pathToRegexp } from "path-to-regexp";
import uuid from "uuid";
import { slug } from "../../../utils/format";
import Help from "../../Help";
const { REACT_APP_API_HOST } = window._env_;
export default class RestChannelForm extends Component {
  state = {
    params: []
  };

  onCustomUrlChange = event => {
    this.props.onMetaChange(event);
    // Extract params from url ex: /:id/score -> params : ["id"]
    const { value } = event.target;
    let urlParams = [];
    try {
      pathToRegexp(value || "", urlParams);
    } catch (err) {}
    const params = urlParams.map(({ name }) => name);

    this.setState({ params });
  };

  filterAvailableParams(params, columns) {
    // filter url params only returning those not already present in columns
    const columnsNames = columns.map(({ name }) => name);
    return params.filter(param => !columnsNames.includes(param));
  }

  addColumns = params => {
    const newColumns = params.map(param => ({
      id: uuid(),
      name: param,
      value: "",
      example: ""
    }));
    const { columns } = this.props.channel;
    this.props.onChange({
      target: { name: "columns", value: [...columns, ...newColumns] }
    });
  };

  getSlug(channelOwner, channel) {
    return slug(`${channelOwner} ${channel.name}`);
  }

  render() {
    const { channel, channelOwner, onChange, onMetaChange } = this.props;
    const { meta = {} } = channel;
    const { params } = this.state;
    const availableParams = this.filterAvailableParams(params, channel.columns);
    const channelSlug = this.getSlug(channelOwner, channel);
    const channelUrl = `${REACT_APP_API_HOST}/broadcast/${channelSlug}/`;
    return (
      <>
        <Form.Row>
          <Col xs={1.5}>
            <Form.Label>Method*</Form.Label>
            <Form.Control
              as="select"
              required
              name="method"
              className={!meta.method ? "select-default" : ""}
              onChange={onMetaChange}
              value={meta.method}
            >
              <option value="">Select a method</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>
              Custom Url
              <Help title="Custom url">
                <b>Optional</b>
                <p>
                  You can <b>customize</b> the url to your channel and{" "}
                  <b>add custom parameters</b> with :customparam.
                </p>
                <p>
                  <i>Example : </i>
                  {channelUrl}
                  <b>order/:id</b>
                </p>
                <p>
                  A parameter <b>id</b> will be added to your columns.
                </p>
              </Help>
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{channelUrl}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="url"
                onChange={this.onCustomUrlChange}
                value={meta.url || ""}
              />
            </InputGroup>
          </Col>
        </Form.Row>
        {availableParams.length > 0 && (
          <ButtonToolbar className="justify-content-end mt-2">
            <Button
              variant="primary"
              size="sm"
              onClick={() => this.addColumns(availableParams)}
            >
              Add {availableParams.join(" and ")} in my columns
            </Button>
          </ButtonToolbar>
        )}
        <br />
        <Columns columns={channel.columns} onChange={onChange} />
        <Form.Group>
          <Form.Label>Example of expected response</Form.Label>
          <LegacyCodeEditor
            code={channel.response || ""}
            onChange={value =>
              onChange({
                target: { name: "response", value }
              })
            }
          />
        </Form.Group>
      </>
    );
  }
}
