import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useWorkflow } from "../../../../contexts/WorkflowContext";

import { MdSend } from "react-icons/md";
import { DeleteConfirm } from "../../../DeleteConfirm";

export default function ResponseNode({ node }) {
  const { dispatch } = useWorkflow();
  return (
    <>
      <Card
        className="node p-0"
        onClick={() => dispatch({ type: "toggleNode", payload: { node } })}
      >
        <Card.Body className="node-body">
          <Row className="align-items-center">
            <div className="node-icon">
              <MdSend />
            </div>
            <Col className="node-name">
              <b>Response</b>
            </Col>
            <Col className="node-actions" xs="auto">
              <DeleteConfirm
                className="node-delete"
                onClick={e => {
                  e.stopPropagation();
                  dispatch({ type: "deleteNode", payload: { id: node.id } });
                }}
                size="sm"
                variant="outline-danger"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
