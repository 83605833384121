import React, { useState, createContext, useContext } from "react";

export const NavbarContext = createContext({
  display: true
});

export default function NavbarProvider({ children }) {
  const [display, setDisplay] = useState(true);

  return (
    <NavbarContext.Provider value={{ display, setDisplay }}>
      {children}
    </NavbarContext.Provider>
  );
}

export function useNavbar() {
  return useContext(NavbarContext);
}
