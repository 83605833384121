import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import {
  MdHttp,
  MdFormatListBulleted,
  MdRefresh,
  MdSend,
  MdInput,
  MdEmail
} from "react-icons/md";
import { FaCode, FaCodeBranch, FaRegClock, FaChartLine } from "react-icons/fa";
import { VscSymbolEvent } from "react-icons/vsc";
import { GrGraphQl } from "react-icons/gr";
import "./NewNodeForm.scss";

export default function NewNodeForm({ onClose, onSelect, channel, newNode }) {
  const { nextId, inForeach } = newNode;

  const hasNextNode = !!nextId;
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts">
          <h5>Add a node</h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>

      <div className="node-form-body new-node-form-body">
        <ListGroup className="new-node-list">
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("HTTP")}
          >
            <div className="new-node-logo">
              <MdHttp />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Http request</div>
              <div className="new-node-description">
                Execute an Http Request to an API using JSON or XML, then
                extract data from the response.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("FTP")}
          >
            <div className="new-node-logo">
              <MdInput />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Send to FTP</div>
              <div className="new-node-description">
                Send a file to a remote FTP server.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type new-node-type-disabled"
            disabled
            onClick={() => onSelect("GRAPHQL")}
          >
            <div className="new-node-logo">
              <GrGraphQl />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">GraphQL Request</div>
              <div className="new-node-description">
                Execute a request to a GraphQL gateway
              </div>
              <Badge pill className="badge-primary">
                Not available
              </Badge>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("BUSINESS")}
          >
            <div className="new-node-logo">
              <FaChartLine />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Business dashboard trace</div>
              <div className="new-node-description">
                Send information to the business dashboard.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("CODE")}
          >
            <div className="new-node-logo">
              <FaCode />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Code</div>
              <div className="new-node-description">
                Execute a custom Javascript code to transform data.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("CONDITIONAL")}
          >
            <div className="new-node-logo">
              <FaCodeBranch />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Conditional</div>
              <div className="new-node-description">
                Add a condition that allows to execute two paths based on a
                conditional result.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("FOREACH")}
          >
            <div className="new-node-logo">
              <MdRefresh />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Foreach</div>
              <div className="new-node-description">
                Iterate on each item of a given array.
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("WAIT")}
          >
            <div className="new-node-logo">
              <FaRegClock />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Wait</div>
              <div className="new-node-description">
                Wait for a specific amount of time before moving to next step.
              </div>
            </div>
          </ListGroup.Item>
          {channel?.type === "rest" && !hasNextNode && !inForeach && (
            <ListGroup.Item
              action
              className="new-node-type"
              onClick={() => onSelect("RESPONSE")}
            >
              <div className="new-node-logo">
                <MdSend />
              </div>
              <div className="new-node-type-container">
                <div className="new-node-title">Http Response</div>
                <div className="new-node-description">
                  Sends a Http Response.
                </div>
              </div>
            </ListGroup.Item>
          )}
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("EVENT")}
          >
            <div className="new-node-logo">
              <VscSymbolEvent />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Emit Event</div>
              <div className="new-node-description">
                Emit an event (RabbitMQ, Kafka, PubSub...)
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("SEND_EMAIL")}
          >
            <div className="new-node-logo">
              <MdEmail />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Send email</div>
              <div className="new-node-description">
                Send a email to one or several users
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            action
            className="new-node-type"
            onClick={() => onSelect("FORM")}
          >
            <div className="new-node-logo">
              <MdFormatListBulleted />
            </div>
            <div className="new-node-type-container">
              <div className="new-node-title">Submit form</div>
              <div className="new-node-description">
                Submit form data to a website. Used for data input, file upload,
                login, automation, real user simulation, robotic process
                automation (RPA) etc.
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
}
