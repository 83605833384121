import React from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaPlus, FaTrashAlt, FaEdit } from "react-icons/fa";

import SubscriptionActions from "./../subscriptions/SubscriptionActions";
import { formatLongDate } from "../../utils/format";

const actions = [
  {
    action: "edit",
    text: "Edit",
    icon: <FaEdit />,
    variant: "primary",
    authorizedStatus: []
  },
  {
    action: "delete",
    text: "Delete",
    icon: <FaTrashAlt />,
    variant: "danger",
    authorizedStatus: []
  }
];

export default function WorkflowList({ workflows = [], onAction, isAdmin }) {
  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>My workflows</h5>
          </Col>
          <Col xs="auto">
            <Button onClick={() => onAction("new")} variant="inline-success">
              <FaPlus /> New Workflow
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Channel</th>
              {isAdmin && <th>Subscriber</th>}
              <th>Last updated</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {workflows.map(workflow => (
              <tr key={workflow.id}>
                <td>{workflow.name}</td>
                <td>
                  {workflow.channel.name} - {workflow.company.login}
                </td>
                {isAdmin && <td>{workflow.subscriber.login}</td>}
                <td>{formatLongDate(workflow.updatedAt)}</td>
                <td>
                  <SubscriptionActions
                    actions={actions}
                    status={workflow.status}
                    onAction={action => onAction(action, workflow.id)}
                    isAdmin={isAdmin}
                  />
                </td>
              </tr>
            ))}
            {workflows.length === 0 && (
              <tr>
                <td>
                  <h6>No workflows yet.</h6>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
