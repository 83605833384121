import React, { useMemo, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import Help from "../../../../Help";
import AddBusinessTrace from "../../../../subscriptions/calls/businessTraces/AddBusinessTrace";
import BusinessTraceForm from "../../../../subscriptions/calls/businessTraces/BusinessTraceForm";
import EditableText from "../../EditableText";
import AvailableVariables from "../../AvailableVariables";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

export default function BusinessDashboardNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables } = useWorkflow();

  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);
  const { variables } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );

  const addBusinessTrace = businessTrace => {
    setConfig(config => ({
      ...config,
      businessTraces: [...config.businessTraces, businessTrace]
    }));
  };

  const updateBusinessTrace = ({ target }, index) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setConfig(config => ({
      ...config,
      businessTraces: config.businessTraces.map(
        (businessTrace, currentIndex) => {
          if (currentIndex === index) {
            return { ...businessTrace, [name]: value };
          }
          return businessTrace;
        }
      )
    }));
  };

  const deleteBusinessTrace = deleteIndex => {
    setConfig(config => ({
      ...config,
      businessTraces: config.businessTraces.filter(
        (_businessTrace, index) => index !== deleteIndex
      )
    }));
  };

  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };

  const { businessTraces = [] } = config;
  return (
    <div className="node-form">
      <div className="header-parts mb-3">
        <h5>
          <EditableText
            text={name}
            onChange={({ target }) => setName(target.value)}
          />
        </h5>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </div>

      <div className="header-parts">
        <AvailableVariables variables={variables} node={node} />
      </div>

      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <Form.Label>
              Business Traces&nbsp;
              <Help title="BusinessTraces">
                <p>
                  BusinessTraces lets you send chosen information to the
                  Business Dashboard.
                </p>
                <p>
                  <b>Type</b> describes what kind of data is sent. This improves
                  display in Business Dashboard
                </p>
                <p>
                  <b>Name</b> defines the name of this data in the Business
                  Dashboard. Allowed : 0-9 A-Z a-z _ and -
                </p>
                <p>
                  <b>Variable</b> defines what to send. Examples: columns.var,
                  context.var
                </p>
              </Help>
            </Form.Label>
            {businessTraces.map((businessTrace, index) => (
              <div key={index}>
                <BusinessTraceForm
                  {...businessTrace}
                  onChange={e => updateBusinessTrace(e, index)}
                >
                  <Col xs="auto">
                    <Button
                      type="button"
                      size="lg"
                      variant="inline-danger"
                      onClick={() => deleteBusinessTrace(index)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </BusinessTraceForm>
                <br />
              </div>
            ))}
            <AddBusinessTrace onAdd={addBusinessTrace} />
          </Form.Group>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
