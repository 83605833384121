import BaseAPI from "./BaseAPI";

/**
 * ChatGPT API
 * @extends BaseAPI
 */
export default class ChatGPT extends BaseAPI {
  static async complete(prompt, history, autocomplete) {
    return this.execute("post", `/chat-gpt/_complete`, {
      prompt,
      ...(history && { history }),
      autocomplete: autocomplete || {}
    });
  }
}
