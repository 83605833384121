import React, { useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";

export default function ResponseNodeForm({ node, onClose }) {
  const { updateNode, channel, getAvailableVariables, startTestRun } =
    useWorkflow();
  const [showExpectedResponse, setShowExpectedResponse] = useState(false);
  const [config, setConfig] = useState(node.config);
  const hasExpectedResponse =
    channel?.response !== null && channel?.response !== "";

  const { variables, autocomplete } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );
  const updateConfig = (key, value) => {
    setConfig(config => ({ ...config, [key]: value }));
  };
  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name: "Response", config });
  };

  const headersExample = `  {
    "content-type": "application/json",
    "x-transaction-id": "{{ context.transactionId }}",
    "x-transaction-name": "{{ context.[last name] }}",
    "x-unique-id": "{$ libs.uuid() $}"
  }`;

  const responseExample = `  {
    "success": "ok",
    "id": "{{ context.id }}",
    "lastname": "{{ context.[last name] }}",
    "user": {{{ json context.user }}},
    "code": "{$ libs.uuid() $}"
  }`;

  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>Response</h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>

      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <Form.Label>Response status</Form.Label>
            <Help title="Status" placement="left" maxWidth="600px">
              <p>
                Execute <b>javascript code</b> that{" "}
                <b>returns a valid status code.</b>
              </p>
              Ex:
              <LegacyCodeEditor
                readOnly
                language
                code={
                  "return context.lastResponse.success === true ? 200 : 400"
                }
              />
            </Help>
            <CodeEditor
              code={config.statusCode}
              onChange={code => updateConfig("statusCode", code)}
              autocomplete={autocomplete}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Response headers</Form.Label>
            <Help title="Response headers" placement="left" maxWidth="650px">
              <p>
                Optional : use{" "}
                <a
                  href="https://handlebarsjs.com/guide/#what-is-handlebars"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Handlebars
                </a>{" "}
                to override some response headers to your workflow. Leave blank
                for json responses.
              </p>
              <p>Here are the syntaxes for a hardcoded value, a variable value, a variable with space in key name, and a javascript code :</p>
              <LegacyCodeEditor readOnly code={headersExample} />
            </Help>
            <CodeEditor
              language="json"
              code={config.headersTemplate}
              onChange={code => updateConfig("headersTemplate", code)}
              autocomplete={autocomplete}
              validateJson={true}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Response body</Form.Label>
            <Help title="Response body" placement="left" maxWidth="650px">
              <p>
                Use{" "}
                <a
                  href="https://handlebarsjs.com/guide/#what-is-handlebars"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Handlebars
                </a>{" "}
                to create a json response to your workflow.
              </p>
              <p>Here are the syntaxes for a hardcoded value, a variable value, a variable with space in key name, a complex object, and a javascript code :</p>
              <LegacyCodeEditor readOnly code={responseExample} />
              {hasExpectedResponse && (
                <>
                  <br />
                  <p>
                    <b>Expected response from channel</b>
                  </p>
                  <p>
                    <LegacyCodeEditor code={channel?.response} readOnly />
                  </p>
                </>
              )}
            </Help>
            <CodeEditor
              language="json"
              code={config.responseTemplate}
              onChange={code => updateConfig("responseTemplate", code)}
              autocomplete={autocomplete}
              validateJson={false}
            />
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
      <Modal
        show={showExpectedResponse}
        onHide={() => setShowExpectedResponse(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Expected response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegacyCodeEditor readOnly code={channel?.response} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onHide={() => setShowExpectedResponse(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
