import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaTrash, FaTimes, FaCheck } from "react-icons/fa";

export const DeleteConfirm = ({ onClick, ...props }) => {
  const [confirm, setConfirm] = useState(false);
  const changeConfirm = (e, confirm) => {
    e.stopPropagation();
    setConfirm(confirm);
  };

  if (confirm) {
    return (
      <ButtonGroup>
        <Button type="button" {...props} onClick={e => changeConfirm(e, false)}>
          <FaTimes />
        </Button>
        <Button
          type="button"
          {...props}
          onClick={onClick}
          variant="outline-success"
        >
          <FaCheck />
        </Button>
      </ButtonGroup>
    );
  }

  return (
    <Button type="button" onClick={e => changeConfirm(e, true)} {...props}>
      <FaTrash />
    </Button>
  );
};
