import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Container,
  Card,
  Col,
  Form,
  Breadcrumb,
  Row
} from "react-bootstrap";

import SubscriptionAPI from "../../api/Subscription";
import ChannelAPI from "../../api/Channel";
import UserAPI from "../../api/User";
import Auth from "../../authentication/auth";
import { ToastContext } from "../../contexts/ToastContext";
import { errorMessage } from "../../utils/format";
import Help from "../Help";

export default class NewSubscription extends Component {
  static contextType = ToastContext;
  state = {
    channels: [],
    companies: [],
    subscriber: "",
    selectedChannelId: "",
    subscriptionCode: null,
    channel: null
  };

  constructor() {
    super();
    this.isAdmin = Auth.isAdmin();
    this.login = Auth.getLogin();
  }

  componentDidMount() {
    ChannelAPI.all().then(channels => {
      this.setState({ channels });
    });
    if (this.isAdmin) {
      UserAPI.all().then(companies => {
        this.setState({ companies });
      });
    }
  }

  createSubscription = event => {
    event.preventDefault();
    const { channel, subscriber, subscriptionCode } = this.state;

    SubscriptionAPI.create({ channel, subscriber, code: subscriptionCode })
      .then(() => {
        this.props.history.push({
          pathname: "/subscriptions"
        });
        this.context.addToast("success", "Subscription created");
      })
      .catch(err => {
        this.context.addToast(
          "error",
          errorMessage(err),
          "Failed to create subscription"
        );
      });
  };

  selectChannel = async ({ target }) => {
    if (target.value !== "") {
      this.setState({
        selectedChannelId: target.value,
        channel: this.state.channels.find(({ id }) => id === target.value),
        subscriptionCode: null
      });
    } else {
      this.setState({
        selectedChannelId: "",
        channel: null,
        subscriptionCode: null
      });
    }
  };

  selectSubscriber = ({ target }) => {
    this.setState({ subscriber: target.value });
  };

  updateSubscriptionCode = ({ target }) => {
    this.setState({
      subscriptionCode: target.value,
      selectedChannelId: "",
      channel: null
    });
  };

  render() {
    const {
      channels,
      companies,
      selectedChannelId,
      channel,
      subscriptionCode,
      subscriber
    } = this.state;

    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/subscriptions">Subscriptions</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New subscription</Breadcrumb.Item>
        </Breadcrumb>

        <Card>
          <Card.Header className="border-bottom">
            <h5>New subscription</h5>
          </Card.Header>

          <Card.Body>
            <Form onSubmit={this.createSubscription}>
              <Form.Group>
                <Form.Label>Subscribe to one of your channel</Form.Label>
                <Form.Control
                  as="select"
                  name="selectedChannel"
                  className={selectedChannelId === "" ? "select-default" : ""}
                  onChange={this.selectChannel}
                  disabled={!!subscriptionCode}
                  value={selectedChannelId}
                >
                  <option value="">Select a channel</option>
                  {channels.map(channel => (
                    <option key={channel.id} value={channel.id}>
                      {channel.company.login} - {channel.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {channel !== null && (
                <div className="informations">
                  <h6>Channel</h6>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Company</p>
                    </Col>
                    <Col className="information">{channel.company.login}</Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Name</p>
                    </Col>
                    <Col className="information">{channel.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Description</p>
                    </Col>
                    <Col className="information">{channel.description}</Col>
                  </Row>
                  <br />
                </div>
              )}

              <Form.Group>
                <Form.Label>
                  Or using a subscription code
                  <Help>
                    You can subscribe to another user's channel by using a
                    subscription code generated by the other user in the "Edit
                    Channel" page
                  </Help>
                </Form.Label>
                <Form.Control
                  disabled={!!selectedChannelId}
                  name="subscriptionCode"
                  onChange={this.updateSubscriptionCode}
                  value={subscriptionCode}
                />
              </Form.Group>
              {this.isAdmin && companies.length > 0 && (
                <Form.Group>
                  <Form.Label>Subscriber*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="subscriber"
                    className={subscriber === "" ? "select-default" : ""}
                    onChange={this.selectSubscriber}
                    value={subscriber}
                  >
                    <option value="">Select a subscriber</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Button type="submit">Subscribe</Button>
              <br />
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
