import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaRegClock } from "react-icons/fa";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import { DeleteConfirm } from "../../../DeleteConfirm";
import SimpleNextNode from "./SimpleNextNode";

export default function WaitNode({ node }) {
  const { nodes, dispatch } = useWorkflow();
  const nextNode = node.next ? nodes[node.next] : null;
  return (
    <>
      <Card
        className="node p-0"
        onClick={() => dispatch({ type: "toggleNode", payload: { node } })}
      >
        <Card.Body className="node-body">
          <Row className="align-items-center">
            <div className="node-icon">
              <FaRegClock />
            </div>
            <Col className="node-name">
              <b>
                <b>{node.name ? node.name : "Wait for"}</b>
              </b>
            </Col>
            <Col className="node-actions" xs="auto">
              <DeleteConfirm
                className="node-delete"
                onClick={e => {
                  e.stopPropagation();
                  dispatch({ type: "deleteNode", payload: { id: node.id } });
                }}
                size="sm"
                variant="outline-danger"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SimpleNextNode currentNodeId={node.id} nextNode={nextNode} />
    </>
  );
}
