import { parseISO, format } from "date-fns";
import slugify from "slugify";

export function isXml(headers = {}) {
  const contentType = headers["Content-Type"] || headers["content-type"] || "";
  return contentType.match("xml");
}

export function truncate(str, maxLength) {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function formatShortDate(date) {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "dd/MM/yyyy");
}

export function formatLongDate(date) {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "dd/MM/yyyy HH:mm:ss");
}
export function formatHours(date) {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    date = parseISO(date);
  }
  return format(date, "HH:mm:ss");
}

export function errorMessage(err) {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  }
  return err.message;
}

export function slug(input) {
  return slugify(input, { replacement: "-", lower: true });
}
