import React, { useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp, FaRegClock } from "react-icons/fa";
import LegacyCodeEditor from "../LegacyCodeEditor";
import "./HistoryRow.css";

export default function ColumnsRow({ columns }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Row
        className="history-row no-gutters  border-bottom py-3 px-3 position-relative"
        onClick={() => setShowMore(!showMore)}
      >
        <Col md="auto" className="pr-3">
          <b>Input data</b>
        </Col>

        <Col md="auto" className="pl-2">
          {showMore ? (
            <FaAngleUp className="workflow-history-row-more" />
          ) : (
            <FaAngleDown className="workflow-history-row-more" />
          )}
        </Col>
      </Row>
      <Collapse in={showMore}>
        <Row className="no-gutters align-items-center border-bottom pt-2 pb-4 px-3">
          {columns && (
            <Col xs="12">
              <b className="d-block mb-2">Input data: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={false}
                mode="json"
                code={columns}
              />
            </Col>
          )}
        </Row>
      </Collapse>
    </>
  );
}
