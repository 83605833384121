import BaseAPI from "./BaseAPI";

/**
 * Datasource API
 * @extends BaseAPI
 */
export default class Datasource extends BaseAPI {
  /**
   * Get all datasource
   *
   * @returns {Promise<Object>} All the datasource
   */
  static all() {
    return this.execute("get", "/datasources");
  }

  static unlock(id) {
    return this.execute("put", `/datasources/${id}/unlock`);
  }

  static testFtpConnection(config) {
    return this.execute("post", `/datasources/ftp/connection`, config);
  }
}
