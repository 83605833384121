import axios from "axios";
import queryString from "query-string";
import React, { Component } from "react";
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Form,
  Row,
  Table
} from "react-bootstrap";
import { FaFilter, FaPollH, FaTrashAlt } from "react-icons/fa";
import { Link, withRouter } from "react-router-dom";
import Auth from "../../../authentication/auth";
import { formatLongDate } from "../../../utils/format";
import Loader from "../../Loader";
import WorkflowTracingApi from "../../../api/WorkflowTracingAPI";
import MonitoringFilters from "../MonitoringFilters";
import MonitoringPagination from "../MonitoringPagination";
import SuccessBadge from "../SuccessBadge";
import TimeBadge from "../TimeBadge";

const POLL_INTERVAL = 1000;

const DEFAULT_FILTERS = {
  page: 1
};
class WorkflowMonitoring extends Component {
  state = {
    tracings: [],
    loading: true,
    pageLoading: false,
    globalToggle: false,
    selectedTracings: [],
    ...this.getFilters()
  };

  timeoutId = null;
  mounted = true;
  cancelTokens = [];

  isAdmin = Auth.isAdmin();
  login = Auth.getLogin();

  getFilters() {
    const urlFilters = queryString.parse(this.props.location.search);
    urlFilters.page = parseInt(urlFilters.page || 1, 10);
    return {
      filters: { ...DEFAULT_FILTERS, ...urlFilters },
      showFilters: Object.keys(urlFilters).filter(f => f !== "page").length > 0
    };
  }

  componentDidMount() {
    this.getTracings().finally(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.clearPoll();
  }

  clearPoll = () => {
    if (this.cancelTokens.length) {
      this.cancelTokens.forEach(t => t.cancel());
      this.cancelTokens = [];
    }
    clearTimeout(this.timeoutId);
  };

  getTracings = async () => {
    const { filters } = this.state;
    this.clearPoll();

    const cancelToken = axios.CancelToken.source();
    this.cancelTokens.push(cancelToken);

    try {
      const { tracings } = await WorkflowTracingApi.all(filters, cancelToken);
      this.setState({ tracings, pageLoading: false });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
    }

    if (filters.page === 1 && this.mounted) {
      this.timeoutId = setTimeout(this.getTracings, POLL_INTERVAL);
    }
  };

  setPage = page => {
    this.applyFilters({ ...this.state.filters, page });
  };

  deleteTracing = tracingId => {
    WorkflowTracingApi.delete(tracingId).finally(() => {
      this.getTracings();
    });
  };

  toggleFilters = () => {
    this.setState(
      ({ showFilters }) => ({
        showFilters: !showFilters,
        filters: DEFAULT_FILTERS
      }),
      () => {
        if (!this.state.showFilters) {
          this.applyFilters(this.state.filters);
        }
      }
    );
  };

  applyFilters = filters => {
    // apply filters
    this.setState({ filters, pageLoading: true }, this.getTracings);
    // push filters to url
    const urlQuery = queryString.stringify(filters, {
      skipEmptyString: true,
      skipNull: true
    });
    this.props.history.push({
      search: `?${urlQuery}`
    });
  };

  massDelete = async () => {
    const { selectedTracings } = this.state;
    await WorkflowTracingApi.massDelete(selectedTracings);
    this.setState(
      { selectedTracings: [], globalToggle: false },
      this.getTracings
    );
  };

  isSubscriber = tracing => {
    return tracing.subscriber ? tracing.subscriber.login === this.login : false;
  };

  toggleAll = ({ target }) => {
    const { tracings, globalToggle } = this.state;
    let selectedTracings = [];
    if (target.checked) {
      selectedTracings = tracings.map(({ id }) => id);
    }
    this.setState({ selectedTracings, globalToggle: !globalToggle });
  };

  toggleOne = ({ target }, id) => {
    const { selectedTracings } = this.state;
    if (target.checked) {
      this.setState({ selectedTracings: [...selectedTracings, id] });
    } else {
      this.setState({
        selectedTracings: selectedTracings.filter(
          selectedId => selectedId !== id
        )
      });
    }
  };

  render() {
    const {
      filters,
      tracings,
      loading,
      pageLoading,
      showFilters,
      globalToggle,
      selectedTracings
    } = this.state;
    return (
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Workflow tracings</h5>
            </Col>
            <Col xs="auto">
              <Button onClick={this.toggleFilters} variant="inline-alpha">
                <FaFilter /> Filters
              </Button>
            </Col>
          </Row>
          <Row>
            {showFilters && (
              <Col xs={12}>
                <MonitoringFilters
                  onApply={this.applyFilters}
                  defaultFilters={filters}
                  displayWorkflow
                />
              </Col>
            )}
            {selectedTracings.length > 0 && (
              <Col className="mt-3" xs={12}>
                <Button
                  type="button"
                  onClick={this.massDelete}
                  variant="danger"
                >
                  Delete ({selectedTracings.length})
                </Button>
              </Col>
            )}
          </Row>
        </Card.Header>
        <Card.Body style={{ padding: 0 }}>
          <Table size="sm">
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type="checkbox"
                    checked={globalToggle}
                    onChange={this.toggleAll}
                  />
                </th>
                <th>Date</th>
                <th>Channel</th>
                <th>Workflow</th>
                <th>Subscriber</th>
                <th>Success</th>
                <th>Duration</th>
                <th />
              </tr>
            </thead>
            <tbody style={{ opacity: pageLoading ? 0.5 : 1 }}>
              {loading && (
                <tr>
                  <td colSpan="9" style={{ paddingLeft: "45%" }}>
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading &&
                tracings.map(tracing => (
                  <tr key={tracing.id}>
                    <th>
                      <Form.Check
                        type="checkbox"
                        onChange={e => this.toggleOne(e, tracing.id)}
                        checked={selectedTracings.includes(tracing.id)}
                      />
                    </th>
                    <td>{formatLongDate(tracing.startedAt)}</td>
                    <td>
                      {tracing.channel ? tracing.channel.name : <i>Unknown</i>}
                    </td>
                    <td>
                      {tracing.workflow ? (
                        <>
                          {tracing.workflowName}
                          <Badge pill className="ml-1 badge-primary">
                            {tracing.workflowVersion}
                          </Badge>
                        </>
                      ) : (
                        <i>Unknown</i>
                      )}
                    </td>
                    <td>
                      {tracing.subscriber ? (
                        tracing.subscriber.login
                      ) : (
                        <i>Unknown</i>
                      )}
                    </td>
                    <td>
                      <SuccessBadge
                        success={tracing.success}
                        error={tracing.error}
                      />
                    </td>
                    <td>
                      <TimeBadge
                        startedAt={tracing.startedAt}
                        endedAt={tracing.endedAt}
                        error={tracing.error}
                      />
                    </td>
                    <td>
                      <ButtonToolbar className="float-right">
                        <Button
                          as={Link}
                          to={`/workflowTracings/${tracing.id}`}
                          type="button"
                          variant="inline-primary"
                        >
                          <FaPollH /> Detail
                        </Button>
                        &nbsp;
                        {(this.isSubscriber(tracing) || this.isAdmin) && (
                          <Button
                            onClick={() => this.deleteTracing(tracing.id)}
                            variant="inline-danger"
                          >
                            <FaTrashAlt /> Delete
                          </Button>
                        )}
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
              {!loading && tracings.length === 0 && (
                <tr>
                  <td className="pt-4">
                    <h6>No tracings found.</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="py-3">
          <Row>
            <Col style={{ alignContent: "center" }}>
              <p> {tracings.length} Items </p>
            </Col>
            <Col xs="auto">
              <MonitoringPagination
                currentPage={parseInt(filters.page, 10)}
                setPage={this.setPage}
                itemsCount={tracings.length}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  }
}

export default withRouter(WorkflowMonitoring);
