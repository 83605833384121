import React, { useMemo, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export default function CodeNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();
  const formRef = useRef(null);
  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);
  const [error, setError] = useState();
  const { variables, autocomplete } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );

  const updateConfig = (key, value) => {
    setConfig({ ...config, [key]: value });
  };
  const saveNode = e => {
    e.preventDefault();
    if ((config.code === "") | !config.code) {
      setError("Code is required.");
      return;
    }
    updateNode(node.id, { ...node, name, config });
  };
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => {
              startTestRun({ ...node, name, config });
            }}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form ref={formRef} onSubmit={saveNode}>
          <Form.Group>
            <Form.Label className="mr-1">Code*</Form.Label>
            <Help title="Code" placement="left" maxWidth="455px">
              <p>
                Execute <b>javascript code</b> to transform data coming from
                columns or context.
              </p>
              <p>
                New variables can be set <b>in context</b> for next nodes
              </p>
              Ex:
              <LegacyCodeEditor
                readOnly
                code={
                  // eslint-disable-next-line no-template-curly-in-string
                  "context.myVariable = `hello ${columns.name}`;\ncontext.myId = libs.uuid()"
                }
              />
              <br />
              <p>
                Some javascript libraries are available in the <b>libs</b>{" "}
                object.
              </p>
              <p>
                <i>Ex: let myId = libs.uuid()</i>
              </p>
              <p>
                <u>List of available libraries</u>
                <ul>
                  <li>
                    <b>ajv</b> -
                    <a
                      href="https://ajv.js.org/guide/getting-started.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JSON schema validator
                    </a>
                    <p>
                      To validate a schema, you can also use
                      <LegacyCodeEditor
                        code="const { isValid, errors } = libs.compileAndValidateJsonSchema(schema, data);"
                        language="javascript"
                        readOnly
                        height={25}
                      />
                    </p>
                  </li>
                  <li>
                    <b>axios</b> -{" "}
                    <a
                      href="https://axios-http.com/docs/intro"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Promise-based HTTP Client
                    </a>
                  </li>
                  <li>
                    <b>base64</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/base-64"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Base64 encoder/decoder
                    </a>
                  </li>
                  <li>
                    <b>cheerio</b> -{" "}
                    <a
                      href="https://cheerio.js.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subset of core jQuery
                    </a>
                  </li>
                  <li>
                    <b>convertUnits</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/convert-units"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Units manipulation library
                    </a>
                  </li>
                  <li>
                    <b>crypto</b> -{" "}
                    <a
                      href="https://nodejs.org/docs/latest-v14.x/api/crypto.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Node.js cryptography module
                    </a>
                  </li>
                  <li>
                    <b>fastcsv</b> -{" "}
                    <a
                      href="https://c2fo.github.io/fast-csv/docs/formatting/examples"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Node.js fast-csv module
                    </a>
                  </li>
                  <li>
                    <b>dayjs</b> -{" "}
                    <a
                      href="https://day.js.org/en/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Date manipulation library
                    </a>
                  </li>
                  <li>
                    <b>escapeHtml</b> - Escape html from a string
                  </li>
                  <li>
                    <b>file</b> - Returns "FILE_TOKEN+key"
                  </li>
                  <li>
                    <b>js2xml</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/xml-js"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Convert Js to XML
                    </a>
                  </li>
                  <li>
                    <b>json2xml</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/xml-js"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Convert Json to XML
                    </a>
                  </li>
                  <li>
                    <b>jsonwebtoken</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/jsonwebtoken"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Inspect, use, sign & verify JWT
                    </a>
                  </li>
                  <li>
                    <b>lodash</b> -{" "}
                    <a
                      href="https://lodash.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Global javascript utility library
                    </a>
                  </li>
                  <li>
                    <b>md5</b> - Hash a string to md5
                  </li>
                  <li>
                    <b>oauth1</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/oauth-1.0a"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OAuth 1.0a Request Authorization
                    </a>
                  </li>
                  <li>
                    <b>turndown</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/turndown"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Html to markdown library
                    </a>
                  </li>
                  <li>
                    <b>utf8</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/utf8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      UTF-8 encoder/decoder
                    </a>
                  </li>
                  <li>
                    <b>uuid</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/uuid"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Create a random uuid v4
                    </a>
                  </li>
                  <li>
                    <b>xml2js</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/xml-js"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Convert XML to JS
                    </a>
                  </li>
                  <li>
                    <b>admzip</b> -{" "}
                    <a
                      href="https://www.npmjs.com/package/adm-zip"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      zip and unzip
                    </a>
                  </li>
                </ul>
              </p>
            </Help>
            <CodeEditor
              code={config.code}
              onChange={code => updateConfig("code", code)}
              autocomplete={autocomplete}
            />
            {error && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
