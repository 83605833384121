import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import routes from "./routes";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import logo from "../../logo-new.png";
import Auth from "../../authentication/auth";
import { FaEject, FaUser, FaChartLine, FaUnlockAlt } from "react-icons/fa";
import "./Navigation.scss";
import { NavbarContext } from "../../contexts/NavbarContext";

class Navigation extends Component {
  logout = () => {
    Auth.logout();
    this.props.history.push({ pathname: "/login" });
  };

  showUsers = () => {
    this.props.history.push({ pathname: "/users" });
  };
  showDatasources = () => {
    this.props.history.push({ pathname: "/datasources" });
  };
  showStats = () => {
    this.props.history.push({ pathname: "/stats" });
  };
  showDashboard = () => {
    this.props.history.push({ pathname: "/dashboard" });
  };
  render() {
    const isLoggedIn = Auth.isLoggedIn();
    const login = Auth.getLogin();
    const isAdmin = Auth.isAdmin();

    const loggedInMenu = (
      <Nav className="nav-menu mx-auto">
        {routes.map(route => (
          <Navbar.Text key={route.path}>
            {route.icon}
            <Link to={route.path}>{route.name}</Link>
          </Navbar.Text>
        ))}
        <NavDropdown
          id="login-dropdown"
          title={login}
          className="justify-content-end"
        >
          <NavDropdown.Item onClick={this.logout} className="nav-item">
            <FaEject /> Disconnect
          </NavDropdown.Item>
          {isAdmin && (
            <>
              <NavDropdown.Item onClick={this.showUsers} className="nav-item">
                <FaUser /> Users
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.showStats} className="nav-item">
                <FaChartLine /> Stats
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={this.showDatasources}
                className="nav-item"
              >
                <FaUnlockAlt /> Datasources
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={this.showDashboard}
                className="nav-item"
              >
                <FaChartLine /> Dashboard
              </NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav>
    );

    const anonymousMenu = (
      <Nav className="nav">
        <Navbar.Text>
          <Link to="/login">Login</Link>
        </Navbar.Text>
      </Nav>
    );

    return (
      <NavbarContext.Consumer>
        {value => (
          <Navbar
            className="ecosystem-navbar"
            style={value.display ? {} : { display: "none" }}
          >
            <Navbar.Brand>
              <div className="ecosystem-logo">
                <img alt="Apizr - Ecosystem" src={logo} />
              </div>
            </Navbar.Brand>
            <Navbar.Collapse>
              {isLoggedIn ? loggedInMenu : anonymousMenu}
            </Navbar.Collapse>
          </Navbar>
        )}
      </NavbarContext.Consumer>
    );
  }
}

export default withRouter(Navigation);
