import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Modal
} from "react-bootstrap";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import Help from "../../Help";
import { MdSend } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

export default function ResponseForm({
  subscription,
  defaultOpen,
  onChange,
  onDelete,
  onAvailableVariablesClick,
  expectedResponse
}) {
  const [open, setOpen] = useState(defaultOpen);
  const [showExpectedResponse, setShowExpectedResponse] = useState(false);
  const hasExpectedResponse =
    expectedResponse !== null && expectedResponse !== "";
  return (
    <Card className={`node col-7 p-0 ${!open ? "node-closed" : ""}`}>
      <Card.Header className="node-header" onClick={() => setOpen(!open)}>
        <Row className="align-items-center">
          <div className="node-icon">
            <MdSend />
          </div>
          <Col className="node-name">
            <h6>Response</h6>
          </Col>
          <Col className="node-actions" xs="auto">
            <Button
              className="node-delete"
              onClick={onDelete}
              size="sm"
              variant="outline-danger"
            >
              <FaTrash />
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body>
          <Alert className="mb-4" variant="light" style={{ padding: 0 }}>
            <p>You also can use variables in your response template.</p>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() =>
                onAvailableVariablesClick(subscription.calls.length)
              }
            >
              Show variables
            </Button>
            {hasExpectedResponse && (
              <Button
                variant="outline-secondary"
                type="button"
                className="ml-3"
                onClick={() => setShowExpectedResponse(true)}
              >
                Show expected response
              </Button>
            )}
          </Alert>
          <Form.Group>
            <Form.Label>
              Response template :
              <Help title="Response template" maxWidth="320px">
                <p>
                  Use Javascript to create the final response of your calls,{" "}
                  <b>checkout the expected response</b> from channel's owner.
                </p>
                <p>
                  <u>Example :</u>
                </p>
                <LegacyCodeEditor
                  readOnly
                  code={
                    "<% let response = {}; %>\n\n<%- JSON.stringify(response) %>"
                  }
                />
              </Help>{" "}
            </Form.Label>
            <LegacyCodeEditor
              code={subscription.resultTemplate || ""}
              onChange={onChange}
            />
          </Form.Group>
        </Card.Body>
      </Collapse>
      <Modal
        show={showExpectedResponse}
        onHide={() => setShowExpectedResponse(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Expected response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegacyCodeEditor readOnly code={expectedResponse} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onHide={() => setShowExpectedResponse(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}
