import React, { useState, useEffect } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Row,
  Table
} from "react-bootstrap";
import {
  FaArchive,
  FaCheck,
  FaCodeBranch,
  FaPaperPlane,
  FaPlus,
  FaTrashAlt,
  FaExchangeAlt
} from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { formatLongDate } from "../../utils/format";
import WorkflowAPI from "../../api/WorkflowAPI";
import VersionModal from "./VersionModal";
import { AiOutlineFork } from "react-icons/ai";
import EditableText from "./WorkflowEditor/EditableText";

export default function WorkflowVersions({ match }) {
  const workflowId = match.params.id;
  const [workflow, setWorkflow] = useState({});
  const [showNewVersion, setShowNewVersion] = useState(false);
  const [forkedVersion, setForkedVersion] = useState();

  async function fetchWorkflow(workflowId) {
    const workflow = await WorkflowAPI.get(workflowId);
    setWorkflow(workflow);
  }

  useEffect(() => {
    fetchWorkflow(workflowId);
  }, [workflowId]);

  async function deployVersion(versionId) {
    await WorkflowAPI.deployVersion(workflowId, versionId);
    fetchWorkflow(workflowId);
  }

  function newVersion() {
    setShowNewVersion(true);
  }

  function setWorkflowName({ target }) {
    setWorkflow(wk => ({ ...wk, name: target.value }));
  }

  function updateWorkflow() {
    WorkflowAPI.update(workflowId, { name: workflow.name });
  }

  async function deleteVersion(versionId) {
    await WorkflowAPI.deleteVersion(workflowId, versionId);
    fetchWorkflow(workflowId);
  }

  function onNewVersion() {
    fetchWorkflow(workflowId);
    setForkedVersion(null);
  }

  async function forkVersion(versionId) {
    setForkedVersion(versionId);
    setShowNewVersion(true);
  }

  const { name, channel, company, versions = [], currentVersion } = workflow;

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item active>
          <NavLink to="/workflows">Workflows</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Workflow</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Card>
            <Card.Header className="border-bottom">
              <Row>
                <Col xs={12}>
                  {
                    <h4 className="mb-2">
                      <EditableText
                        text={name}
                        onChange={setWorkflowName}
                        onSubmit={updateWorkflow}
                      />
                    </h4>
                  }
                </Col>
                <Col xs={12}>
                  Channel: {company?.login} - {channel?.name}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <Row className="px-4 py-3 align-items-center">
                <Col>
                  <h5 className="mb-0" style={{ color: "#598efb" }}>
                    <FaCodeBranch /> Versions
                  </h5>
                </Col>
              </Row>
              {versions.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center py-4">
                  <h5 className="mr-4 mb-0">No versions yet</h5>
                  <Button
                    onClick={() => newVersion()}
                    size="sm"
                    variant="inline-success"
                    className="mr-3"
                  >
                    <FaPlus />
                    &nbsp; New Version
                  </Button>
                </div>
              ) : (
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Created at</th>
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {versions.map(version => {
                      const isDeployed = currentVersion === version.id;
                      return (
                        <tr key={version.id}>
                          <td>{version.version}</td>
                          <td>{formatLongDate(version.createdAt)}</td>
                          <td>
                            {isDeployed ? (
                              <Badge pill className="badge-soft-success">
                                <FaCheck /> Deployed
                              </Badge>
                            ) : (
                              <Badge pill className="badge-soft-warning">
                                <FaArchive /> Undeployed
                              </Badge>
                            )}
                          </td>
                          <td>
                            <ButtonToolbar className="float-right">
                              {!isDeployed && (
                                <Button
                                  className="ml-2"
                                  onClick={() => deployVersion(version.id)}
                                  variant="inline-success"
                                >
                                  <FaPaperPlane /> Deploy
                                </Button>
                              )}
                              <Button
                                className="ml-2"
                                as={Link}
                                to={`/workflows/${workflowId}/versions/edit/${version.id}`}
                                variant="inline-primary"
                              >
                                <FaExchangeAlt /> Configure
                              </Button>
                              <Button
                                className="ml-2"
                                onClick={() => forkVersion(version.id)}
                                variant="inline-primary"
                              >
                                <AiOutlineFork /> Fork version
                              </Button>
                              {!isDeployed && (
                                <Button
                                  className="ml-2"
                                  onClick={() => deleteVersion(version.id)}
                                  variant="inline-danger"
                                >
                                  <FaTrashAlt /> Delete
                                </Button>
                              )}
                            </ButtonToolbar>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <VersionModal
        workflowId={workflowId}
        existingVersions={versions}
        fromVersion={forkedVersion}
        show={showNewVersion}
        onClose={() => setShowNewVersion(n => !n)}
        onNewVersion={onNewVersion}
      />
    </Container>
  );
}
