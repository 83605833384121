import React, { Component } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaTrash, FaCodeBranch } from "react-icons/fa";
import WorkflowForm from "./WorkflowForm";
import AddWorkflow from "./AddWorkflow";
import "./WorkflowSection.css";
export default class WorkflowSection extends Component {
  state = {
    displayWorkflow: false
  };

  addFlow = flow => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: [...this.props.workflow, flow]
      }
    });
  };

  updateFlow = ({ target }, index) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: this.props.workflow.map((flow, currentIndex) => {
          if (currentIndex === index) {
            return { ...flow, [target.name]: target.value };
          }
          return flow;
        })
      }
    });
  };

  deleteFlow = deleteIndex => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: this.props.workflow.filter(
          (_flow, index) => index !== deleteIndex
        )
      }
    });
  };

  closeSection = () => {
    this.toggleDisplay(false);

    // Delete all workflow
    this.props.onChange({
      target: {
        name: this.props.name,
        value: []
      }
    });
  };

  toggleDisplay = displayWorkflow => {
    this.setState({ displayWorkflow });
  };

  render() {
    const { displayWorkflow } = this.state;
    const { workflow, availableCalls, label } = this.props;
    return (
      <>
        {workflow.length > 0 || displayWorkflow ? (
          <Card className="workflow-card mb-5">
            <Card.Header style={{ backgroundColor: "#28a745" }}>
              <Row className="align-items-center">
                <Col>
                  <h6>
                    <FaCodeBranch /> Workflow
                  </h6>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={this.closeSection}
                    size="sm"
                    variant="outline-light"
                  >
                    <FaTrash />
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                {workflow.map((flow, index) => (
                  <div key={index}>
                    <WorkflowForm
                      {...flow}
                      onChange={e => this.updateFlow(e, index)}
                      availableCalls={availableCalls}
                    >
                      <Col xs="auto">
                        <Button
                          type="button"
                          size="lg"
                          variant="inline-danger"
                          onClick={() => this.deleteFlow(index)}
                        >
                          <FaTrash />
                        </Button>
                      </Col>
                    </WorkflowForm>
                    <br />
                  </div>
                ))}
                <AddWorkflow
                  onAdd={this.addFlow}
                  availableCalls={availableCalls}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        ) : (
          <Button
            className="mb-3"
            type="button"
            variant="outline-success"
            onClick={() => this.toggleDisplay(true)}
          >
            <FaCodeBranch /> Add {label}
          </Button>
        )}
      </>
    );
  }
}
