import React, { useMemo, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export default function ForeachNodeForm({ node, onClose }) {
  const formRef = useRef();
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();
  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);
  const [error, setError] = useState();

  const { variables, autocomplete } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );

  const updateConfig = (key, value) => {
    setConfig({ ...config, [key]: value });
    setError();
  };
  const saveNode = e => {
    e.preventDefault();
    if ((config.foreachCode === "") | !config.foreachCode) {
      setError("Foreach code is required.");
      return;
    }
    updateNode(node.id, { ...node, name, config });
  };
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, name, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode} ref={formRef}>
          <Form.Group>
            <Form.Label>Iterate on which array ?*</Form.Label>
            <Help title="Foreach" placement="left">
              <p>
                Execute <b>javascript code</b> that <b>return an array</b>, next
                nodes will be executed for every item in this array.
              </p>
              Ex:
              <LegacyCodeEditor readOnly code={"return [1,2,3];"} />
            </Help>
            <CodeEditor
              code={config.foreachCode}
              onChange={code => updateConfig("foreachCode", code)}
              autocomplete={autocomplete}
            />
            {error && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Item name in context*</Form.Label>
            <Help title="Item name" placement="left" maxWidth="350px">
              <p>
                Name of the variable in context for every item in the upper
                array
              </p>
              Ex:
              <LegacyCodeEditor
                readOnly
                code={
                  // eslint-disable-next-line no-template-curly-in-string
                  "// Item name set to 'user'\n const myVariable = context.user"
                }
              />
            </Help>
            <Form.Control
              required
              type="text"
              name="itemKey"
              title="Must be a single word with no special characters"
              pattern="^[A-Za-z0-9]+$"
              placeholder="currentItem"
              onChange={({ target }) => updateConfig("itemKey", target.value)}
              value={config.itemKey}
            />
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
