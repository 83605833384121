import React from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

export default function NodeArrow({ onAdd }) {
  return (
    <div className="node-arrow">
      <Button onClick={onAdd} className="node-add-request" variant="primary">
        <FaPlus />
      </Button>
      <svg className="node-arrow-svg">
        <defs>
          <marker
            id="arrow"
            markerWidth="12"
            markerHeight="12"
            refX="0"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
            viewBox="0 0 15 15"
          >
            <path d="M0,0 L0,6 L6,3 z" fill="#5e636b" />
          </marker>
        </defs>
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="82%"
          stroke="#5e636b"
          strokeWidth="3"
          markerEnd="url(#arrow)"
        />
      </svg>
    </div>
  );
}
