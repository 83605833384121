import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import SubscriptionsMonitoring from "./SubscriptionsMonitoring";
import "./Monitoring.scss";
import WorkflowMonitoring from "./workflow/WorkflowMonitoring";

export default function Monitoring({ match, history }) {
  const { type } = match.params;

  function onSelect(type) {
    history.push(type);
  }

  return (
    <Container fluid>
      <Tab.Container
        id="left-tabs-example"
        activeKey={type}
        mountOnEnter
        unmountOnExit
      >
        <Row className="p-5">
          <Col sm={2}>
            <Nav variant="tabs" className="monitoring-tabs flex-column">
              <Nav.Item>
                <Nav.Link eventKey="workflows" onSelect={onSelect}>
                  Worfklows
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subscriptions" onSelect={onSelect}>
                  Subscriptions
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9} className="ml-5">
            <Tab.Content>
              <Tab.Pane eventKey="workflows">
                <WorkflowMonitoring />
              </Tab.Pane>
              <Tab.Pane eventKey="subscriptions">
                <SubscriptionsMonitoring />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}
