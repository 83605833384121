import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";

const EDITOR_MODE = "javascript";
const EDITOR_THEME = "github";
const MIN_HEIGHT = 100;
const HEIGHT_PER_LINE = 20;

// Calculate height from code
const autoHeight = code => {
  if (code === null) {
    return 0;
  }
  const lineCount = (code.match(/\n/g) || []).length + 1; // Number of \n + last line
  const height = lineCount * HEIGHT_PER_LINE;
  return height > MIN_HEIGHT ? `${height}px` : `${MIN_HEIGHT}px`;
};

export default function LegacyCodeEditor({
  readOnly = false,
  showGutter = true,
  mode = EDITOR_MODE,
  height,
  code = "",
  onChange
}) {
  return (
    <AceEditor
      readOnly={readOnly}
      showGutter={showGutter}
      mode={mode}
      theme={EDITOR_THEME}
      fontSize="14px"
      height={height || autoHeight(code)}
      width="100%"
      value={code}
      onChange={onChange}
      editorProps={{
        $blockScrolling: Infinity
      }}
    />
  );
}
