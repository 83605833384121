import BaseAPI from "./BaseAPI";

/**
 * Dashboard API
 * @extends BaseAPI
 */
export default class Dashboard extends BaseAPI {
  /**
   * Get the dashboard settings
   */
  static getSettings() {
    return this.execute("get", `/dashboardSettings`);
  }
  /**
   * Update the dashboard settings
   *
   * @returns {Promise<Object>} update result
   */
  static updateSettings(url) {
    return this.execute("put", `/dashboardSettings`, url);
  }
}
