import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import { DeleteConfirm } from "../../../DeleteConfirm";
import NewNode from "../NewNode";
import NodeArrow from "../NodeArrow";
import NodeContainer from "../NodeContainer";
import SimpleNextNode from "./SimpleNextNode";

export default function ForeachNode({ node }) {
  const { nodes, dispatch } = useWorkflow();
  const { foreach } = node.config ?? {};
  const nextNode = node.next ? nodes[node.next] : null;
  const foreachNode = foreach.next ? nodes[foreach.next] : null;
  return (
    <>
      <Card
        className="node p-0"
        onClick={() => dispatch({ type: "toggleNode", payload: { node } })}
      >
        <Card.Body className="node-body">
          <Row className="align-items-center">
            <div className="node-icon">
              <MdRefresh />
            </div>
            <Col className="node-name">
              <b>{node.name ? node.name : "Foreach"}</b>
            </Col>
            <Col className="node-actions" xs="auto">
              <DeleteConfirm
                className="node-delete"
                onClick={e => {
                  e.stopPropagation();
                  dispatch({ type: "deleteNode", payload: { id: node.id } });
                }}
                size="sm"
                variant="outline-danger"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="node-foreach">
        <div className="node-foreach-for" />
        <NodeArrow
          showNewNode={false}
          onNewNodeClick={() => {
            dispatch({
              type: "toggleNewNode",
              payload: {
                parentId: node.id,
                inForeach: true,
                nextId: foreachNode.id
              }
            });
          }}
          arrowFromTheLeft={true}
        />
        {foreachNode ? (
          <NodeContainer node={foreachNode} />
        ) : (
          <NewNode
            onClick={() =>
              dispatch({
                type: "toggleNewNode",
                payload: { parentId: node.id, inForeach: true }
              })
            }
          />
        )}
      </div>
      <SimpleNextNode
        currentNodeId={node.id}
        nextNode={nextNode}
        showNewNode={true}
      />
    </>
  );
}
