import React, { useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import "./WorkflowHistoryRow.scss";

export default function WorkflowHistoryRow({ columns }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Row
        className={`workflow-history-row no-gutters  border-bottom py-3 px-3 position-relative`}
        onClick={() => setShowMore(!showMore)}
      >
        <Col md="auto" className="pr-3">
          <b>Input data</b>
        </Col>

        <Col md="auto" className="pl-2">
          {showMore ? (
            <FaAngleUp className="workflow-history-row-more" />
          ) : (
            <FaAngleDown className="workflow-history-row-more" />
          )}
        </Col>
      </Row>

      <Collapse in={showMore}>
        <Row className="no-gutters align-items-center border-bottom pt-2 pb-4 px-3">
          <Col xs="12">
            <LegacyCodeEditor
              readOnly={true}
              showGutter={true}
              mode="json"
              code={columns}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}
