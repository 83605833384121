import React, { useState, useMemo } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import AnonymizeInput from "../../../../AnonymizeInput";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

export default function EventNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();

  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);

  const { variables } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );
  const updateConfig = (key, value) => {
    setConfig(config => ({ ...config, [key]: value }));
  };

  const updateAnonymize = ({ target }) => {
    setConfig(config => ({
      ...config,
      anonymize: {
        ...config.anonymize,
        [target.name]: target.checked
      }
    }));
  };

  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };

  const updateRetryOptions = ({ target }) => {
    setConfig(config => ({
      ...config,
      retryOptions: {
        ...config.retryOptions,
        [target.name]: target.value
      }
    }));
  };

  const {
    uri,
    headers,
    body,
    anonymize = {},
    retryActive = false,
    retryOptions = {},
    continueWorkflow = false,
    timeout = DEFAULT_REQUEST_TIMEOUT
  } = config;
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, name, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Uri (protocol - host - port)*</Form.Label>
              <Form.Control
                required
                type="text"
                name="uri"
                title="Must be a valid uri"
                pattern="https?:\/\/.+"
                placeholder="kafka://host:port/topic/{{columns.productId}}"
                onChange={({ target }) => updateConfig("uri", target.value)}
                value={uri}
              />
            </Form.Group>
          </Form.Row>
          <Tabs
            variant="pills"
            className="tabs-call"
            defaultActiveKey="headers"
          >
            <Tab eventKey="headers" title="Headers">
              <Form.Group>
                <CodeEditor
                  language="handlebars"
                  code={headers}
                  onChange={value => updateConfig("headers", value)}
                />
                <br />
                <AnonymizeInput
                  checked={anonymize.headers}
                  name="headers"
                  onChange={updateAnonymize}
                  label="Anonymize Headers"
                  width={230}
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="body" title="Body">
              <Form.Group>
                <CodeEditor
                  language="handlebars"
                  code={body}
                  onChange={value => updateConfig("body", value)}
                />
                <br />
                <AnonymizeInput
                  checked={anonymize.body}
                  name="body"
                  onChange={updateAnonymize}
                  label="Anonymize Body"
                  width={210}
                />
              </Form.Group>
            </Tab>
          </Tabs>

          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="retry-switch"
              name="retryActive"
              label="Auto-retry"
              checked={retryActive}
              onChange={({ target }) =>
                updateConfig("retryActive", target.checked)
              }
            />
            <Help title="Auto retry">
              <p>Automatically retries failed request.</p>
              <p>
                <i>Between 1 to 5 retries</i>
              </p>
              <p>
                Request <b>will be considered failed</b> if there is{" "}
                <b>no response</b> (ENOTFOUND, ETIMEDOUT, etc).
              </p>
              <p>
                Or if <b>response status code</b> is in :
                <ul>
                  <li>100 {"->"} 199</li>
                  <li>429</li>
                  <li>500 {"->"} 599</li>
                </ul>
              </p>
              <p>
                Exponential backoff is used between retries:
                <ul>
                  <li>First retry after 500ms</li>
                  <li>Second retry after 1.5s</li>
                  <li>Third retry after 3.5s</li>
                  <li>Fourth retry after 7.5s</li>
                  <li>Fifth retry after 15.5s</li>
                </ul>
              </p>
            </Help>
          </Form.Row>
          {retryActive === true && (
            <Form.Row className="mt-3">
              <Col xs={3}>
                <Form.Label>Retry count*</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  required
                  value={retryOptions.count}
                  min={1}
                  max={5}
                  onChange={updateRetryOptions}
                />
                <Form.Text className="text-muted">
                  Between 1 and 5 retries
                </Form.Text>
              </Col>
            </Form.Row>
          )}
          <hr />
          <Form.Group>
            <Form.Label>Timeout</Form.Label>
            <Help title="Request timeout">
              <>
                <p>
                  Timeout specifies the number of seconds before the request
                  times out. If the request takes longer than timeout, the
                  request will be aborted.
                </p>
                <p>Minimum: 1, Maximum: 300.</p>
              </>
            </Help>
            <Form.Control
              type="number"
              name="timeout"
              placeholder="15"
              min={1}
              max={300}
              onChange={({ target }) => updateConfig("timeout", target.value)}
              value={timeout}
            />
            <Form.Text className="text-muted">
              In seconds (default to 15 seconds)
            </Form.Text>
          </Form.Group>
          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="continue-workflow"
              name="continueWorkflow"
              label="Continue workflow if request failed"
              checked={continueWorkflow}
              onChange={({ target }) =>
                updateConfig("continueWorkflow", target.checked)
              }
            />
            <Help title="Continue workflow">
              <p>
                By default, the workflow will stop if a request fails, by
                activating this option, workflow will continue even if this
                request returns an error response. After the request an object
                lastResponse will be available in the context containing status
                and success of last request response
              </p>
              <LegacyCodeEditor
                code={JSON.stringify(
                  {
                    context: { lastResponse: { status: 400, success: false } }
                  },
                  null,
                  2
                )}
                readOnly
              />
            </Help>
          </Form.Row>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
