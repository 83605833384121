import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import EditableText from "../../EditableText";

export default function WaitNodeForm({ node, onClose }) {
  const { updateNode } = useWorkflow();
  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);

  const updateConfig = (key, value) => {
    setConfig({ ...config, [key]: value });
  };
  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <Form.Label>Wait for (in milliseconds)*</Form.Label>
            <Form.Control
              type="number"
              name="time"
              required
              placeholder={100}
              max={10000}
              onChange={({ target }) =>
                updateConfig("time", parseInt(target.value))
              }
              value={config.time}
            />
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
