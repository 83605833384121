import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  Collapse,
  Col,
  Form,
  Row,
  Tabs,
  Tab
} from "react-bootstrap";
import { MdHttp } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

import ExtractorForm from "./extractors/ExtractorForm";
import AddExtractor from "./extractors/AddExtractor";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import AnonymizeInput from "../../AnonymizeInput";
import Help from "../../Help";
import WorkflowSection from "./workflow/WorkflowSection";
import { DeleteConfirm } from "../../DeleteConfirm";

export default class CallForm extends Component {
  state = {
    open: this.props.open || false
  };

  addExtractor = extractor => {
    this.props.onChange({
      target: {
        name: "extractors",
        value: [...this.props.extractors, extractor]
      }
    });
  };

  updateExtractor = ({ target }, index) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.props.onChange({
      target: {
        name: "extractors",
        value: this.props.extractors.map((extractor, currentIndex) => {
          if (currentIndex === index) {
            return { ...extractor, [name]: value };
          }
          return extractor;
        })
      }
    });
  };

  deleteExtractor = deleteIndex => {
    this.props.onChange({
      target: {
        name: "extractors",
        value: this.props.extractors.filter(
          (_extractor, index) => index !== deleteIndex
        )
      }
    });
  };

  updateAnonymize = ({ target }) => {
    this.props.onChange({
      target: {
        name: "anonymize",
        value: { ...this.props.anonymize, [target.name]: target.checked }
      }
    });
  };

  updateRetryOptions = ({ target }) => {
    this.props.onChange({
      target: {
        name: "retryOptions",
        value: { ...this.props.retryOptions, [target.name]: target.value }
      }
    });
  };

  render() {
    const {
      index,
      name,
      method,
      url,
      body,
      headers,
      anonymize = {},
      onChange,
      extractors,
      workflow,
      postWorkflow,
      onAvailableVariablesClick,
      availableCalls,
      retryActive = false,
      retryOptions = {},
      timeout
    } = this.props;

    const { open } = this.state;
    return (
      <Card className={`node col-7 p-0 ${!open ? "node-closed" : ""}`}>
        <Card.Header
          className="node-header"
          onClick={() => this.setState({ open: !open })}
        >
          <Row className="align-items-center">
            <div className="node-icon node-icon-http">
              <MdHttp />
            </div>
            <Col className="node-name">
              <b>{name ? name : `Http Request n°${index}`}</b>
            </Col>
            <Col className="node-actions" xs="auto">
              <DeleteConfirm
                className="node-delete"
                onClick={this.props.onDelete}
                size="sm"
                variant="outline-danger"
              />
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={open}>
          <Card.Body>
            <Alert className="mb-4" variant="light" style={{ padding: 0 }}>
              <p>
                Throughout your configuration, you can use variables coming from
                channel's columns and your extractors.
              </p>
              <Button
                variant="outline-primary"
                type="button"
                onClick={onAvailableVariablesClick}
              >
                See variables
              </Button>
            </Alert>
            <WorkflowSection
              label="Pre request workflow"
              name="workflow"
              workflow={workflow}
              onChange={onChange}
              availableCalls={availableCalls}
            />
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="My request"
                onChange={onChange}
                value={name}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} xs={2}>
                <Form.Label>Method*</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="method"
                  onChange={onChange}
                  value={method}
                >
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                  <option value="PUT">PUT</option>
                  <option value="PATCH">PATCH</option>
                  <option value="DELETE">DELETE</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Url*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="url"
                  placeholder="https://api.test.com/products/<%= columns.productId %>"
                  onChange={onChange}
                  value={url}
                />
              </Form.Group>
            </Form.Row>
            <Tabs
              variant="pills"
              className="tabs-call"
              defaultActiveKey="headers"
            >
              <Tab eventKey="headers" title="Headers">
                <Form.Group>
                  <LegacyCodeEditor
                    code={headers}
                    onChange={value =>
                      onChange({
                        target: { name: "headers", value }
                      })
                    }
                  />
                  <br />
                  <AnonymizeInput
                    checked={anonymize.headers}
                    name="headers"
                    onChange={this.updateAnonymize}
                    label="Anonymize Headers"
                    width={230}
                  />
                </Form.Group>
              </Tab>
              <Tab eventKey="body" title="Body">
                <Form.Group>
                  <LegacyCodeEditor
                    code={body}
                    onChange={value =>
                      onChange({
                        target: { name: "body", value }
                      })
                    }
                  />
                  <br />
                  <AnonymizeInput
                    checked={anonymize.body}
                    name="body"
                    onChange={this.updateAnonymize}
                    label="Anonymize Body"
                    width={210}
                  />
                </Form.Group>
              </Tab>
            </Tabs>
            <hr />
            <Form.Row className="pl-1">
              <Form.Check
                type="switch"
                id="retry-switch"
                name="retryActive"
                label="Auto-retry"
                checked={retryActive}
                onChange={onChange}
              />

              <Help title="Auto retry">
                <p>Automatically retries failed request.</p>
                <p>
                  <i>Between 1 to 5 retries</i>
                </p>
                <p>
                  Request <b>will be considered failed</b> if there is{" "}
                  <b>no response</b> (ENOTFOUND, ETIMEDOUT, etc).
                </p>
                <p>
                  Or if <b>response status code</b> is in :
                  <ul>
                    <li>100 {"->"} 199</li>
                    <li>429</li>
                    <li>500 {"->"} 599</li>
                  </ul>
                </p>
                <p>
                  Exponential backoff is used between retries:
                  <ul>
                    <li>First retry after 500ms</li>
                    <li>Second retry after 1.5s</li>
                    <li>Third retry after 3.5s</li>
                    <li>Fourth retry after 7.5s</li>
                    <li>Fifth retry after 15.5s</li>
                  </ul>
                </p>
              </Help>
            </Form.Row>

            {retryActive === true && (
              <Form.Row className="mt-3">
                <Col xs={3}>
                  <Form.Label>Retry count*</Form.Label>
                  <Form.Control
                    type="number"
                    name="count"
                    required
                    value={retryOptions.count}
                    min={1}
                    max={5}
                    onChange={this.updateRetryOptions}
                  />
                  <Form.Text className="text-muted">
                    Between 1 and 5 retries
                  </Form.Text>
                </Col>
              </Form.Row>
            )}
            <hr />
            <Form.Group>
              <Form.Label>Timeout</Form.Label>
              <Help title="Request timeout">
                <>
                  <p>
                    Timeout specifies the number of seconds before the request
                    times out. If the request takes longer than timeout, the
                    request will be aborted.
                  </p>
                  <p>Minimum: 1, Maximum: 300.</p>
                </>
              </Help>
              <Form.Control
                type="number"
                name="timeout"
                placeholder="15"
                min={1}
                max={300}
                onChange={onChange}
                value={timeout}
              />
              <Form.Text className="text-muted">
                In seconds (default to 15 seconds)
              </Form.Text>
            </Form.Group>
            <hr />
            <Form.Group>
              <Form.Label>
                Extractors*&nbsp;
                <Help title="Extractors">
                  <p>
                    Extractors help you extract data from request response body
                    or headers to reuse in other requests config.
                  </p>
                  <p>
                    <b>Path</b> describe how to extract data from JSON response.
                  </p>
                  <p>
                    <i>
                      Under the hood, we are using JSONata, checkout "Test it"
                      for more informations.
                    </i>
                  </p>
                </Help>
              </Form.Label>
              {extractors.map((extractor, index) => (
                <div key={index}>
                  <ExtractorForm
                    {...extractor}
                    onChange={e => this.updateExtractor(e, index)}
                  >
                    <Col xs="auto">
                      <Button
                        type="button"
                        size="lg"
                        variant="inline-danger"
                        onClick={() => this.deleteExtractor(index)}
                      >
                        <FaTrash />
                      </Button>
                    </Col>
                  </ExtractorForm>
                  <br />
                </div>
              ))}
              <AddExtractor onAdd={this.addExtractor} />
            </Form.Group>
            <hr />
            <Form.Group>
              <Form.Label>Response</Form.Label>
              <AnonymizeInput
                name="response"
                checked={anonymize.response}
                onChange={this.updateAnonymize}
                label="Anonymize Response"
                width={240}
              />
            </Form.Group>
            <WorkflowSection
              label="Post request workflow"
              name="postWorkflow"
              workflow={postWorkflow}
              onChange={onChange}
              availableCalls={availableCalls}
            />
          </Card.Body>
        </Collapse>
      </Card>
    );
  }
}
