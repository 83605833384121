import React from "react";
import { Button } from "react-bootstrap";

export default function NewNode({ onClick }) {
  return (
    <div className="new-node">
      <Button
        className="new-node-button"
        variant="inline-alpha"
        onClick={onClick}
      >
        +
      </Button>
    </div>
  );
}
