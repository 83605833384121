import React, { Component } from "react";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { slug } from "../../../utils/format";
import Help from "../../Help";
import Columns from "../columns/Columns";
import DataSourceApi from "./../../../api/Datasource";
import DataPathPlayground from "./FileChannelForm/DataPathPlayground";

const { REACT_APP_EMAIL_HOOK_DOMAIN } = window._env_;

const FtpConnectionMessage = ({ ftpConnection }) => {
  switch (ftpConnection.status) {
    case "loading":
      return <span style={{ color: "#598efb" }}>{ftpConnection.message}</span>;
    case "ok":
      return <span style={{ color: "#00864e" }}>{ftpConnection.message}</span>;
    case "error":
      return <span style={{ color: "#e63757" }}>{ftpConnection.message}</span>;
    default:
      return null;
  }
};
export default class FileChannelForm extends Component {
  state = {
    showPlayground: false,
    ftpConnection: { status: "inactive" }
  };

  toggleTry = () => {
    this.setState(({ showPlayground }) => ({
      showPlayground: !showPlayground
    }));
  };

  updatePath = path => {
    this.props.onMetaChange({ target: { name: "dataPath", value: path } });
    this.toggleTry();
  };

  testFtpConnection = async () => {
    const { ftpHost, ftpPort, ftpUsername, ftpPassword, ftpPrivateKey } =
      this.props.channel.meta;
    this.setState({
      ftpConnection: { status: "loading", message: "Testing connection..." }
    });
    try {
      await DataSourceApi.testFtpConnection({
        ftpHost,
        ftpPort,
        ftpUsername,
        ftpPassword,
        ftpPrivateKey
      });
      this.setState({
        ftpConnection: { status: "ok", message: "Connection ok" }
      });
    } catch (err) {
      this.setState({
        ftpConnection: {
          status: "error",
          message: err.response?.data?.message || "Something bad happened"
        }
      });
    }
  };

  onColumnsChange = event => {
    const { onChange, channel } = this.props;
    // Propagate event to parent
    onChange(event);

    // Check if groupBy column still exist in columns
    const hasGroupByColum = event.target.value.some(
      ({ name }) => name === channel.groupBy
    );
    if (channel.groupBy !== "" && !hasGroupByColum) {
      // If not reset it
      onChange({
        target: { name: "groupBy", value: "" }
      });
    }
  };

  getSlug(channelOwner, channel) {
    return slug(`${channelOwner} ${channel.name}`);
  }

  render() {
    const { showPlayground, ftpConnection } = this.state;
    const { channel, channelOwner, onChange, onMetaChange } = this.props;
    const { columns, groupBy, meta = {} } = channel;
    const {
      sendingMethod,
      ftpHost,
      ftpPort,
      ftpUsername,
      ftpPassword,
      ftpPrivateKey,
      ftpWorkingPath = "/",
      ftpArchivePath = "/archive",
      fileFormat,
      processingMode,
      processingInterval,
      csvDelimiter,
      csvSkipEmpty,
      csvSkipLines,
      dataPath,
      emailsWhitelist = "",
      xlsSheetName,
      customHeaders
    } = meta;
    const channelSlug = this.getSlug(channelOwner, channel);
    return (
      <>
        <Form.Group>
          <Form.Label>Sending method*</Form.Label>
          <Form.Control
            as="select"
            required
            name="sendingMethod"
            className={!sendingMethod ? "select-default" : ""}
            onChange={onMetaChange}
            value={sendingMethod}
          >
            <option value="">Select a method</option>
            <option value="direct">Direct Upload</option>
            <option value="ftp">FTP/SFTP</option>
            <option value="mail">Email</option>
          </Form.Control>
        </Form.Group>

        {sendingMethod === "ftp" && (
          <>
            <hr />
            <br />
            <Form.Row>
              <Form.Group as={Col} md="3">
                <Form.Label>Host*</Form.Label>
                <Form.Control
                  required
                  name="ftpHost"
                  onChange={onMetaChange}
                  value={ftpHost}
                  type="text"
                  placeholder="myftp-host.com"
                />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>Port*</Form.Label>
                <Form.Control
                  required
                  name="ftpPort"
                  onChange={onMetaChange}
                  value={ftpPort}
                  type="text"
                  placeholder="22"
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Label>Username*</Form.Label>
                <Form.Control
                  required
                  onChange={onMetaChange}
                  value={ftpUsername}
                  name="ftpUsername"
                  type="text"
                  placeholder="Username"
                />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>
                  {ftpPrivateKey ? "Pass Phrase" : "Password*"}
                </Form.Label>
                <Form.Control
                  required={!ftpPrivateKey}
                  name="ftpPassword"
                  onChange={onMetaChange}
                  value={ftpPassword}
                  as="textarea"
                  placeholder="Password"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>{ftpPassword ? "SSH Key" : "SSH Key*"}</Form.Label>
                <Form.Control
                  required={!ftpPassword}
                  name="ftpPrivateKey"
                  onChange={onMetaChange}
                  value={ftpPrivateKey}
                  as="textarea"
                  placeholder="SSH key"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Working path</Form.Label>
                <Form.Control
                  name="ftpWorkingPath"
                  onChange={onMetaChange}
                  value={ftpWorkingPath}
                  type="text"
                  placeholder="Working path"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Archive path</Form.Label>
                <Form.Control
                  name="ftpArchivePath"
                  onChange={onMetaChange}
                  value={ftpArchivePath}
                  type="text"
                  placeholder="Archive path"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col>
                <Button
                  type="button"
                  disabled={
                    !ftpHost ||
                    !ftpPort ||
                    !ftpUsername ||
                    (!ftpPassword && !ftpPrivateKey) ||
                    ftpConnection.status === "loading"
                  }
                  onClick={this.testFtpConnection}
                >
                  Test connection
                </Button>
              </Col>
              <Col>
                <FtpConnectionMessage ftpConnection={ftpConnection} />
              </Col>
            </Form.Row>
            <hr />
            <br />
          </>
        )}
        {sendingMethod === "mail" && (
          <>
            <Alert variant="info">
              <p>
                Simply send an email to:{" "}
                <b>{`${channelSlug}@${REACT_APP_EMAIL_HOOK_DOMAIN}`}</b> with
                your files as attachments.
              </p>
              <hr />
              <p className="mb-0">Maximum 5 files and 10mb per file.</p>
            </Alert>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Senders email whitelist</Form.Label>
                <Form.Control
                  name="emailsWhitelist"
                  onChange={onMetaChange}
                  value={emailsWhitelist}
                  type="email"
                  multiple
                  placeholder="test@mail.com,test@mail.fr"
                />
                <Form.Text className="text-muted">
                  Only allow certain emails to contact the channel
                </Form.Text>
              </Form.Group>
            </Form.Row>
          </>
        )}
        <Form.Group>
          <Form.Label>Format*</Form.Label>
          <Form.Control
            as="select"
            required
            name="fileFormat"
            className={!fileFormat ? "select-default" : ""}
            onChange={onMetaChange}
            value={fileFormat}
          >
            <option value="">Select a format</option>
            <option value="csv">CSV / Excel</option>
            <option value="xml">XML</option>
            <option value="raw">OTHER</option>
            <option disabled value="json">
              JSON
            </option>
          </Form.Control>
        </Form.Group>

        {fileFormat === "raw" && (
          <>
            <Form.Text className="text-muted">
              File will not be parsed and its contents will be placed in the
              variable named columns.fileContents
            </Form.Text>
            <br />
          </>
        )}

        {fileFormat === "csv" && (
          <>
            <Form.Group>
              <Form.Label>CSV Delimiter (optional)</Form.Label>
              <Form.Control
                type="text"
                name="csvDelimiter"
                placeholder="Delimiter"
                value={csvDelimiter}
                onChange={onMetaChange}
              />
              <Form.Text className="text-muted">',' by default</Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Check
                id="empty-switch"
                type="switch"
                name="csvSkipEmpty"
                checked={csvSkipEmpty}
                onChange={onMetaChange}
                label="CSV skip empty lines"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>CSV skip number of lines (optional)</Form.Label>
              <Form.Control
                type="number"
                name="csvSkipLines"
                value={csvSkipLines}
                onChange={onMetaChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Sheet name (optional)</Form.Label>
              <Form.Control
                type="text"
                name="xlsSheetName"
                value={xlsSheetName}
                onChange={onMetaChange}
              />
              <Form.Text className="text-muted">
                first sheet by default
              </Form.Text>
            </Form.Group>
            {/* Add form for custom headers */}
            <Form.Group>
              <Form.Label>
                Custom headers (optional)
                <Help>
                  To define headers, you need to write them like this:
                  headers1,headers2,headers3, ...
                </Help>
              </Form.Label>
              <Form.Control
                type="text"
                name="customHeaders"
                value={customHeaders}
                onChange={onMetaChange}
              />
            </Form.Group>
          </>
        )}

        {fileFormat === "xml" && (
          <Form.Group>
            <Form.Label>
              Extractor&nbsp;&nbsp;
              <Help title="Extractor">
                <p>Extractor help you extract data from your XML file.</p>
                <p>
                  <i>
                    Under the hood, we are using JSONata, checkout "Test it" for
                    more informations.
                  </i>
                </p>
              </Help>
            </Form.Label>
            <Form.Row style={{ alignItems: "flex-end" }}>
              <Col>
                <Form.Control
                  type="text"
                  name="dataPath"
                  value={dataPath}
                  placeholder="Path"
                  onChange={onMetaChange}
                />
              </Col>
              <Col>
                <Button
                  size="lg"
                  variant="inline-primary"
                  type="button"
                  style={{ fontSize: "1rem", padding: "0.4rem" }}
                  onClick={this.toggleTry}
                >
                  Test it
                </Button>
              </Col>
            </Form.Row>
          </Form.Group>
        )}

        {fileFormat !== "raw" && (
          <Form.Group>
            <Form.Label>Processing mode*</Form.Label>
            <Form.Control
              as="select"
              required
              name="processingMode"
              className={!processingMode ? "select-default" : ""}
              onChange={onMetaChange}
              value={processingMode}
            >
              <option value="">Select a mode</option>
              <option value="single">Process file per line</option>
              <option value="whole">Process file as whole</option>
            </Form.Control>
          </Form.Group>
        )}

        {fileFormat !== "raw" && processingMode === "single" && (
          <Form.Group>
            <Form.Label>Processing interval per line (optional)</Form.Label>
            <Form.Control
              type="number"
              name="processingInterval"
              placeholder="100"
              min={100}
              value={processingInterval}
              onChange={onMetaChange}
            />
            <Form.Text className="text-muted">In miliseconds</Form.Text>
          </Form.Group>
        )}

        {fileFormat !== "raw" && (
          <Columns columns={columns} onChange={this.onColumnsChange} />
        )}

        {fileFormat !== "raw" &&
          columns.length > 0 &&
          processingMode === "single" && (
            <Form.Group>
              <Form.Label>Group by column (optional)</Form.Label>
              <Form.Control
                style={{ width: "25%" }}
                as="select"
                name="groupBy"
                className={!groupBy ? "select-default" : ""}
                onChange={onChange}
                value={groupBy}
              >
                <option value="">Select a column</option>
                {columns.map(column => (
                  <option key={column.index} value={column.name}>
                    {column.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        {showPlayground && (
          <DataPathPlayground
            onClose={this.toggleTry}
            onPathSave={this.updatePath}
          />
        )}
      </>
    );
  }
}
