import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Help from "../Help";
import "react-datepicker/dist/react-datepicker.css";
import TracingAPI from "../../api/Tracing";

export default function MonitoringFilters({
  onApply,
  defaultFilters,
  displayWorkflow = false
}) {
  const [filters, setFilters] = useState(defaultFilters);
  const [channels, setChannels] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    Promise.all([
      TracingAPI.channels(),
      TracingAPI.subscribers(),
      TracingAPI.workflows()
    ]).then(([channels, subscribers, workflows]) => {
      setChannels(channels);
      setSubscribers(subscribers);
      setWorkflows(workflows);
    });
  }, []);

  const apply = () => {
    onApply({ ...filters, page: 1 }); // reset page when applying new filters
  };

  const onChange = ({ target }) => {
    setFilters({ ...filters, [target.name]: target.value });
  };

  const onDateChange = (name, date) => {
    let dateString = null;
    if (date instanceof Date) {
      dateString = date.toISOString();
    }
    onChange({ target: { name, value: dateString } });
  };

  return (
    <>
      <Row className={"mt-3"}>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>From</Form.Label>
            <DatePicker
              className="form-control"
              selected={Date.parse(filters.from) || ""}
              onChange={date => onDateChange("from", date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              zone
              timeCaption="time"
              dateFormat="dd/MM/yyyy HH:mm"
            />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group>
            <Form.Label>To</Form.Label>
            <DatePicker
              className="form-control"
              selected={Date.parse(filters.to) || ""}
              onChange={date => onDateChange("to", date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              timeCaption="time"
              dateFormat="dd/MM/yyyy HH:mm"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Channel</Form.Label>
            <Form.Control
              as="select"
              name="channel"
              onChange={onChange}
              className={!filters.channel ? "select-default" : ""}
              value={filters.channel}
            >
              <>
                <option value="">Select a channel</option>
                {channels.map(channel => (
                  <option key={channel.id} value={channel.id}>
                    {channel.name}
                  </option>
                ))}
              </>
            </Form.Control>
          </Form.Group>
        </Col>

        {displayWorkflow && (
          <Col>
            <Form.Group>
              <Form.Label>Workflow</Form.Label>
              <Form.Control
                as="select"
                name="workflow"
                onChange={onChange}
                className={!filters.workflow ? "select-default" : ""}
                value={filters.workflow}
              >
                <>
                  <option value="">Select a workflow</option>
                  <option value="one">one</option>
                  {workflows.map(workflow => (
                    <option key={workflow.id} value={workflow.id}>
                      {workflow.name}
                    </option>
                  ))}
                </>
              </Form.Control>
            </Form.Group>
          </Col>
        )}
        {displayWorkflow || (
          <Col>
            <Form.Group>
              <Form.Label>Subscriber</Form.Label>
              <Form.Control
                as="select"
                name="subscriber"
                onChange={onChange}
                className={!filters.subscriber ? "select-default" : ""}
                value={filters.subscriber}
              >
                <>
                  <option value="">Select a subscriber</option>
                  {subscribers.map(subscriber => (
                    <option key={subscriber.id} value={subscriber.id}>
                      {subscriber.login}
                    </option>
                  ))}
                </>
              </Form.Control>
            </Form.Group>
          </Col>
        )}
        <Col>
          <Form.Group>
            <Form.Label>Success</Form.Label>
            <Form.Control
              as="select"
              name="success"
              onChange={onChange}
              value={filters.success}
            >
              <option value="all">All</option>
              <option value="true">Success</option>
              <option value="false">Error</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label data-tip data-for="registerTip">
              Search input data
            </Form.Label>
            <Help title="Search data received in input channels">
              <b>Full text search on input data</b>
              <p>
                Available only when a channel filter is selected. Minimum search
                terms length is 3 letters or digits.
              </p>
              <p>
                One can search for a term or value among the input data received
                by the channel.
              </p>
              <p>
                If the input data received by the channel is fully anonymized
                (which is the default behaviour), values appear as stars in the
                monitoring, and no search is possible. To make an input field
                searchable, one must declare its name in the input channel and
                uncheck its anonymization.
              </p>
            </Help>
            <Form.Control
              type="text"
              name="search"
              onChange={onChange}
              value={filters.search}
              disabled={!filters.channel}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Button
        onClick={apply}
        type="button"
        variant="primary"
        disabled={filters.search && filters.search.length < 3}
      >
        Apply
      </Button>
    </>
  );
}
