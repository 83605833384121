import React, { Component } from "react";
import { Form, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import ColumnForm from "./ColumnForm";
import randomId from "../../../utils/randomId";

export default class AddColumn extends Component {
  state = {
    id: randomId(),
    name: "",
    example: "",
    anonymize: false
  };

  handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { id, name, example, anonymize } = this.state;
    this.props.onAdd({ id, name, example, anonymize });
    this.setState({ id: randomId(), name: "", example: "", anonymize: false });
  };

  render() {
    const { name, example, anonymize } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <ColumnForm
          name={name}
          example={example}
          anonymize={anonymize}
          onChange={this.handleInputChange}
        >
          <Col>
            <Overlay
              placement="right"
              target={this.buttonRef}
              show={name !== ""}
            >
              {props => <Tooltip {...props}>Don't forget to confirm</Tooltip>}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="inline-alpha"
            >
              <FaPlus />
            </Button>
          </Col>
        </ColumnForm>
      </Form>
    );
  }
}
