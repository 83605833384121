import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
export default class SchedulerChannelForm extends Component {
  render() {
    const { channel, onMetaChange } = this.props;
    const { meta = {} } = channel;
    const { cronSettings } = meta;
    return (
      <>
        <Form.Row>
          <Form.Group as={Col}>
            <p>
              Please specify <b>when the channel is scheduled</b>. Examples :
              <table>
                <tr>
                  <td>To trigger every hour and a half in the morning, use:</td>
                  <td>&nbsp;</td>
                  <td>
                    <b>30 0-12 * * *</b>
                  </td>
                </tr>
                <tr>
                  <td>To trigger every 5 minutes on wednesdays, use:</td>
                  <td>&nbsp;</td>
                  <td>
                    <b>*/5 * * * 3</b>
                  </td>
                </tr>
                <tr>
                  <td>To trigger last day of each year at 2:40, use:</td>
                  <td>&nbsp;</td>
                  <td>
                    <b>40 2 31 12 *</b>
                  </td>
                </tr>
              </table>
              <p></p>
              <Form.Control
                type="text"
                required
                name="cronSettings"
                placeholder="0 * * * *"
                value={cronSettings}
                onChange={onMetaChange}
              />
              <p></p>
              The setting is specified using 5 space-separated values. The
              values represent (in this order) :
              <ul>
                <li>minute (0 to 59)</li>
                <li>hour (0 to 23)</li>
                <li>day of month (1 to 31)</li>
                <li>month (1 to 12)</li>
                <li>day of week (0 to 7) (Sunday=0 or 7)</li>
              </ul>
              <p></p>
              Each value must follow one of the following syntaxes :
              <ul>
                <li>* means : every</li>
                <li>*/5 means : every 5</li>
                <li>1-3 means : 1 and 2 and 3</li>
                <li>1,3 means : 1 and 3</li>
                <li>40 means : 40 and no one else</li>
              </ul>
            </p>
            <p> </p>
          </Form.Group>
        </Form.Row>
      </>
    );
  }
}
