import BaseAPI from "./BaseAPI";

/**
 * Channel API
 * @extends BaseAPI
 */
export default class Channel extends BaseAPI {
  /**
   * Get all the Channels
   *
   * @returns {Promise<Array[Object]>} All the Channels
   */
  static all() {
    return this.execute("get", "/channels");
  }

  /**
   * Get a Channel by its id
   *
   * @param {String} id Id of the channel
   * @returns {Promise<Object>} The Channel
   */
  static get(id) {
    return this.execute("get", `/channels/${id}`);
  }

  /**
   * Create a Channel
   *
   * @param {Object} channel The Channel Object
   * @returns {Promise<Object>} An Channel update response
   */
  static create(channel) {
    return this.execute("post", `/channels`, channel);
  }

  /**
   * Edit a Channel
   *
   * @param {String} id The Channel ID
   * @param {Object} channel The Channel Object
   * @returns {Promise<Object>} An Channel update response
   */
  static edit(id, channel) {
    return this.execute("put", `/channels/${id}`, channel);
  }

  /**
   * Delete a Channel
   *
   * @param {String} id The Channel ID
   * @returns {Promise<Object>} An Channel update response
   */
  static delete(id) {
    return this.execute("delete", `/channels/${id}`);
  }

  /**
   * Create a Channel subscrition code
   *
   * @param {String} id The Channel ID
   * @returns {Promise<String>} A channel subscription coe
   */
  static async createSubscriptionCode(id) {
    const { code } = await this.execute("post", `/channels/${id}/codes`);

    return code;
  }

  /*
   * Get related workflows
   *
   * @param {String} id The Channel ID
   * @returns {Promise<Object[]>} channels workflows
   */
  static getWorkflows(id) {
    return this.execute("get", `/channels/${id}/workflows`);
  }
}
