import React from "react";
import { Card, Table } from "react-bootstrap";
import { AiOutlineApartment } from "react-icons/ai";
const { REACT_APP_API_HOST } = window._env_;

export default function ChannelWorkflows({ channel, workflows }) {
  return (
    <Card className="mt-5">
      <Card.Header className="border-bottom">
        <AiOutlineApartment
          className="nav-icon"
          style={{ fontSize: "1.4rem" }}
        />
        <h4 style={{ display: "inline" }}>Subscribed workflows</h4>
      </Card.Header>
      <Card.Body>
        <Table size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Url</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {workflows.map(workflow => {
              const workflowUrl = `${REACT_APP_API_HOST}/broadcast/${
                channel.slug
              }/${channel.meta?.url || ""}?to=${workflow.id}`;
              return (
                <tr key={workflow.id}>
                  <td>{workflow.name}</td>
                  <td>{workflow.company.login}</td>
                  <td>{workflowUrl}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
