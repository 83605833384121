import BaseAPI from "./BaseAPI";
import queryString from "query-string";
/**
 * Workflow Tracing API
 * @extends BaseAPI
 */
export default class WorkflowTracingAPI extends BaseAPI {
  /**
   * Get all the tracings
   *
   * @param {Object} filters
   * @returns {Promise<Object>} All the tracings paginated
   */
  static all(filters = {}, cancelToken) {
    const query = queryString.stringify(filters, {
      skipEmptyString: true,
      skipNull: true
    });
    return this.execute(
      "get",
      `/workflowTracings?${query}`,
      undefined,
      cancelToken
    );
  }

  /**
   * Get a tracing by its id
   *
   * @param {String} id Id of the tracing
   * @returns {Promise<Object>} The tracinf
   */
  static get(id) {
    return this.execute("get", `/workflowTracings/${id}`);
  }

  /**
   * Delete a tracing
   *
   * @param {String} id The tracing ID
   * @returns {Promise}
   */
  static delete(id) {
    return this.execute("delete", `/workflowTracings/${id}`);
  }

  /**
   * Mass delete tracings
   *
   * @param {Array} ids Tracing ids
   * @returns {Promise}
   */
  static massDelete(ids) {
    return this.execute("post", `/workflowTracings/massDelete`, { ids });
  }

  /**
   * Get channels in tracings
   *
   * @returns {Promise}
   */
  static channels() {
    return this.execute("get", "/workflowTracings/channels");
  }

  /**
   * Get susbscribers in tracings
   *
   * @returns {Promise}
   */
  static subscribers() {
    return this.execute("get", "/workflowTracings/subscribers");
  }
}
