import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";

export default class BusinessTraceForm extends Component {
  state = {};

  updatePath = path => {
    // Simulate path input change
    this.props.onChange({ target: { name: "path", value: path } });
    this.toggleTry();
  };

  render() {
    const { type, name, variable, onChange, children = null } = this.props;
    return (
      <>
        <Form.Row style={{ alignItems: "flex-end" }}>
          <Col xs={3}>
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              required
              name="type"
              onChange={onChange}
              value={type}
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              pattern="[A-Za-z0-9-_]{1,50}"
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              onChange={onChange}
            />
          </Col>
          <Col>
            <Form.Label>Variable</Form.Label>
            <Form.Control
              required
              type="text"
              name="variable"
              value={variable}
              placeholder="Variable"
              onChange={onChange}
            />
          </Col>
          {children}
        </Form.Row>
      </>
    );
  }
}
