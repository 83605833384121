import React, { useEffect, useRef, useState } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import { autoCompleteHelper } from "./workflow/editor/editor";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { MdSend } from "react-icons/md";
import ChatGPT from "../api/ChatGPT";
import ReactMarkdown from "react-markdown";
import MarkdownCodeBlock from "./MarkdownCodeBlock";
import { FaBolt, FaUser } from "react-icons/fa";

const DEFAULT_LANGUAGE = "javascript";

const MIN_HEIGHT = 100;
const HEIGHT_PER_LINE = 20;

// Calculate height from code
const autoHeight = code => {
  if (code === null) {
    return 0;
  }
  const lineCount = (code.match(/\n/g) || []).length + 1; // Number of \n + last line
  const height = lineCount * HEIGHT_PER_LINE;
  return height > MIN_HEIGHT ? `${height}px` : `${MIN_HEIGHT}px`;
};

export default function CodeEditor({
  language = DEFAULT_LANGUAGE,
  height,
  code = "",
  onChange,
  autocomplete,
  readOnly = false,
  validateJson = true
}) {
  const monaco = useMonaco();
  const [isChatGptEnabled, setIsChatGptEnabled] = useState(false);
  const [question, setQuestion] = useState("");
  const [history, setHistory] = useState([]);
  const [generatedCodes, setGeneratedCodes] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef();

  const handleEditorDidMount = (editor, monaco) => {
    if (onChange) {
      editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_K,
        () => {
          setIsChatGptEnabled(!isChatGptEnabled);
        }
      );
    }
  };

  const handleChatGPTComplete = async () => {
    try {
      setError(null);
      setIsLoading(true);

      const { code: generatedCode, history: newHistory } =
        await ChatGPT.complete(question, history, autocomplete);

      setIsLoading(false);
      setGeneratedCodes([...generatedCodes, generatedCode]);
      setHistory(newHistory);
      setQuestion("");
      scrollRef.current.scrollIntoView({ behavior: "instant" });
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  const resetChatGpt = () => {
    setGeneratedCodes([]);
    setHistory([]);
    setQuestion("");
  };

  useEffect(() => {
    let autocompleteInstance;
    if (monaco && autocomplete) {
      autocompleteInstance = autoCompleteHelper(monaco, autocomplete);
    }
    if (monaco && language === "json") {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: validateJson
      });
    }
    return () => {
      autocompleteInstance?.dispose();
    };
  }, [autocomplete, monaco, language, validateJson]);

  return (
    <div ref={scrollRef} style={{ border: "1px solid rgba(0, 0, 0, 0.125)" }}>
      {isChatGptEnabled && (
        <div
          style={{
            marginBottom: "20px",
            maxHeight: "400px",
            overflow: "scroll"
          }}
        >
          {history.map((hist, index) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: hist.role === "user" ? "white" : "#eee",
                    padding: "10px 20px 10px 0px"
                  }}
                >
                  <div style={{ padding: "0 10px", fontSize: "20px" }}>
                    {hist.role === "user" ? <FaUser /> : <FaBolt />}
                  </div>
                  <div style={{ flexGrow: 1, overflow: "auto" }}>
                    <ReactMarkdown
                      components={{
                        code: MarkdownCodeBlock
                      }}
                    >
                      {hist.content}
                    </ReactMarkdown>
                    {hist.role !== "user" && (
                      <Button
                        onClick={() => {
                          onChange(
                            `${code}\n${generatedCodes[Math.floor(index / 2)]}`
                          );
                          resetChatGpt();
                        }}
                      >
                        Use this solution
                      </Button>
                    )}
                  </div>
                </div>
              </>
            );
          })}
          {error && (
            <Alert style={{ padding: "5px", margin: "10px" }} variant="danger">
              {error}
            </Alert>
          )}
          <InputGroup style={{ padding: "10px" }}>
            <InputGroup.Prepend>
              <Button variant="outline-primary" onClick={() => resetChatGpt()}>
                <AiOutlineReload />
              </Button>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              name="question"
              placeholder="Generate a function that multiply each item of an array by two"
              autoFocus
              value={question}
              onChange={event => setQuestion(event.target.value)}
            />

            <InputGroup.Append>
              <Button onClick={handleChatGPTComplete} disabled={isLoading}>
                {isLoading ? (
                  <Spinner size="sm" role="status" animation="border" />
                ) : (
                  <MdSend />
                )}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      )}
      <div height="calc(100% - 1px)">
        <Editor
          height={height || autoHeight(code)}
          width="100%"
          theme="vs"
          defaultLanguage={language}
          value={code}
          onChange={onChange}
          options={{
            readOnly
          }}
          onMount={handleEditorDidMount}
        />
      </div>
    </div>
  );
}
