import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import {
  FaFileExport,
  FaFileImport,
  FaLongArrowAltRight,
  FaPlus
} from "react-icons/fa";
import { MdSave } from "react-icons/md";
import { NavLink, Prompt, Link } from "react-router-dom";
import { v1 } from "uuid";
import SubscriptionAPI from "../../../api/Subscription";
import { ToastContext } from "../../../contexts/ToastContext";
import { loadFile, saveFile } from "../../../utils/file";
import { errorMessage } from "../../../utils/format";
import AvailableVariables from "../LegacyAvailableVariables";
import CallForm from "./CallForm";
import "./ConfigureCalls.css";
import NodeArrow from "./NodeArrow";
import ResponseForm from "./ResponseForm";

const REST_CHANNEL = "rest";
const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

export default class SubscriptionEditor extends Component {
  static contextType = ToastContext;

  defaultCall = () => ({
    id: v1(),
    name: null,
    method: "GET",
    url: "",
    body: "",
    headers: '{\n\t"Content-Type": "application/json"\n}',
    extractors: [],
    workflow: [],
    postWorkflow: [],
    open: true,
    retryActive: false,
    retryOptions: {},
    timeout: DEFAULT_REQUEST_TIMEOUT
  });

  state = {
    subscription: {
      channel: null,
      calls: [],
      resultTemplate: null
    },
    responseOpen: false,
    availableVariables: [],
    touched: false
  };

  constructor(props) {
    super(props);
    this.subscriptionId = props.match.params.id;
  }

  componentDidMount() {
    SubscriptionAPI.get(this.subscriptionId).then(subscription =>
      this.setState({ subscription }, () => {
        // If no calls and no response yet add a default call
        if (
          subscription.calls.length === 0 &&
          subscription.resultTemplate === ""
        ) {
          this.addCall();
        }
      })
    );
  }

  componentDidUpdate() {
    if (this.state.touched) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }
  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  updateSubscription = event => {
    event.preventDefault();
    const { subscription } = this.state;

    SubscriptionAPI.edit(subscription.id, subscription)
      .then(() => {
        this.setState({ touched: false });
        this.context.addToast(
          "success",
          "Subscription has been saved successfully.",
          "Success",
          <Link to="/subscriptions"> Go back to subscriptions</Link>
        );
      })
      .catch(err => {
        this.context.addToast(
          "error",
          errorMessage(err),
          "Failed to update subscription"
        );
      });
  };

  addCall = index => {
    if (index !== undefined && this.hasGotoWorkflow()) {
      this.context.addToast(
        "warning",
        "Please make sure your Goto workflows are still correct"
      );
    }
    const { subscription } = this.state;
    const calls = [...subscription.calls];
    calls.splice(index + 1 || subscription.calls.length, 0, this.defaultCall());

    this.setState(({ subscription }) => ({
      subscription: { ...subscription, calls },
      touched: true
    }));
  };

  updateCall = ({ target }, index) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(({ subscription }) => ({
      subscription: {
        ...subscription,
        calls: subscription.calls.map((call, currentIndex) => {
          if (currentIndex === index) {
            return { ...call, [name]: value };
          }
          return call;
        })
      },
      touched: true
    }));
  };

  deleteCall = deleteIndex => {
    this.setState(({ subscription }) => ({
      subscription: {
        ...subscription,
        calls: subscription.calls.filter((_call, index) => {
          return index !== deleteIndex;
        })
      },
      touched: true
    }));
  };

  updateResponse = response => {
    this.setState(({ subscription }) => ({
      subscription: { ...subscription, resultTemplate: response },
      touched: true
    }));
  };

  addResponse = () => {
    this.updateResponse(
      "<% let response = {}; %>\n\n<%- JSON.stringify(response) %>"
    );
    this.setState({ responseOpen: true });
  };

  deleteResponse = () => this.updateResponse(null);

  showAvailableVariables = callIndex => {
    const { subscription } = this.state;
    // First get columns
    const columnsVariables = subscription.channel.columns.map(
      ({ name }) => `columns.${name}`
    );
    // Then get variables from previous extractors
    const previousCalls = subscription.calls.slice(0, callIndex);
    // Extract every extractors from calls
    const contextVariables = previousCalls.reduce((variables, call) => {
      const extractorsVariables = call.extractors.map(
        ({ name }) => `context.${name}`
      );
      return [...variables, ...extractorsVariables];
    }, []);
    const availableVariables = [...columnsVariables, ...contextVariables];
    this.setState({ availableVariables });
  };

  closeAvailableVariables = () => {
    this.setState({ availableVariables: [] });
  };

  hasGotoWorkflow = () => {
    const { calls } = this.state.subscription;
    return calls.some(({ postWorkflow, workflow }) => {
      return [...workflow, ...postWorkflow].some(
        ({ action }) => action === "goto"
      );
    });
  };

  export = () => {
    const { subscription } = this.state;
    let filename = `${subscription.channel.slug}-${subscription.subscriber.login}.ecosystem.json`;
    let contentType = "application/json;charset=utf-8;";
    const exportData = {
      calls: subscription.calls,
      resultTemplate: subscription.resultTemplate
    };
    saveFile(filename, contentType, exportData);
  };

  import = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    loadFile(file, content => {
      try {
        const { calls = [], resultTemplate = null } = JSON.parse(content);
        this.setState({
          subscription: { ...this.state.subscription, calls, resultTemplate }
        });
      } catch (err) {}
    });
  };

  render() {
    const { subscription, availableVariables, responseOpen, touched } =
      this.state;
    const { calls, channel, company, resultTemplate, subscriber } =
      subscription;
    const canRespond = channel && channel.type === REST_CHANNEL;
    const hasResponse = resultTemplate !== null;

    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/subscriptions">Subscriptions</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Subscription editor</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <h4>Subscription editor</h4>
                    <p>
                      {company ? company.login : <i>Unknown</i>}-
                      {channel ? channel.name : <i>Unknown</i>}
                      &nbsp;
                      <FaLongArrowAltRight />
                      &nbsp;
                      {subscriber ? subscriber.login : <i>Unknown</i>}
                    </p>
                    <p>
                      Configure the sequence of requests to your APIs to fulfill
                      channel's requirements.
                    </p>
                  </Col>
                  <Col xs="auto">
                    <Button
                      className="mr-3"
                      onClick={() => this.upload.click()}
                      size="sm"
                      variant="inline-primary"
                    >
                      <FaFileImport />
                      &nbsp; Import
                    </Button>
                    <Button
                      onClick={this.export}
                      size="sm"
                      variant="inline-primary"
                    >
                      <FaFileExport />
                      &nbsp; Export
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <input
              id="import"
              type="file"
              ref={ref => (this.upload = ref)}
              style={{ display: "none" }}
              onChange={this.import}
              accept="application/json"
            />
            <div className="my-5">
              <Form onSubmit={this.updateSubscription}>
                <Form.Group>
                  {calls.map((call, index) => (
                    <div className="node-container" key={call.id}>
                      <CallForm
                        index={index + 1}
                        {...call}
                        onChange={e => this.updateCall(e, index)}
                        onDelete={() => this.deleteCall(index)}
                        onAvailableVariablesClick={() =>
                          this.showAvailableVariables(index)
                        }
                        availableCalls={calls}
                      />
                      {index + 1 !== calls.length && (
                        <NodeArrow onAdd={() => this.addCall(index)} />
                      )}
                    </div>
                  ))}
                  {canRespond && hasResponse && (
                    <div className="node-container">
                      {calls.length > 0 && (
                        <NodeArrow onAdd={() => this.addCall()} />
                      )}
                      <ResponseForm
                        subscription={subscription}
                        expectedResponse={channel.response}
                        defaultOpen={responseOpen}
                        onChange={this.updateResponse}
                        onDelete={this.deleteResponse}
                        onAvailableVariablesClick={this.showAvailableVariables}
                      />
                    </div>
                  )}
                </Form.Group>
                <div className="editor-new-node">
                  {!hasResponse && (
                    <Button
                      type="button"
                      onClick={() => this.addCall()}
                      variant="inline-alpha"
                    >
                      <FaPlus /> New Http Request
                    </Button>
                  )}
                  {canRespond && !hasResponse && (
                    <Button
                      className="ml-3"
                      type="button"
                      onClick={() => this.addResponse()}
                      variant="inline-alpha"
                    >
                      <FaPlus /> New Response
                    </Button>
                  )}
                </div>
                <div className="editor-save">
                  <Button type="submit" variant="success" size="lg">
                    <MdSave /> Save
                  </Button>
                </div>
              </Form>
            </div>
          </Col>

          {/* <Col xs={4}>
            <ChannelInfo channel={subscription.channel} />
          </Col> */}
        </Row>
        {availableVariables.length > 0 && (
          <AvailableVariables
            variables={availableVariables}
            onClose={this.closeAvailableVariables}
          />
        )}
        <Prompt
          when={touched}
          message="You have unsaved changes, are you sure you want to leave?"
        />
      </Container>
    );
  }
}
