import { differenceInMilliseconds, parseISO } from "date-fns";
import set from "lodash.set";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaBackward, FaPaste, FaPlay, FaRegClock } from "react-icons/fa";
import WorkflowAPI from "../../../api/WorkflowAPI";
import CodeEditor from "../../CodeEditor";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import StatusBadge from "../../monitoring/StatusBadge";
import SuccessBadge from "../../monitoring/SuccessBadge";

const STORAGE_KEY = `test-run-storage`;

const getStorageKey = (prefix, workflowId) =>
  `${prefix}-${workflowId}-${STORAGE_KEY}`;

export function cleanStorage(workflowId) {
  localStorage.removeItem(getStorageKey("columns", workflowId));
  localStorage.removeItem(getStorageKey("context", workflowId));
}
export default function TestRunModal({ channel, workflowId, node, onClose }) {
  const [currentStep, setStep] = useState("setup");
  const [columns, setColumns] = useState("{}");
  const [columnsError, setColumnsError] = useState();
  const [context, setContext] = useState("{}");
  const [contextError, setContextError] = useState();
  const [lastResultContext, setLastResultContext] = useState();
  const [running, setRunning] = useState();
  const [result, setResult] = useState(null);

  // Setup columns and context
  useEffect(() => {
    const savedColumns = localStorage.getItem(
      getStorageKey("columns", workflowId)
    );

    if (savedColumns) {
      setColumns(savedColumns);
    } else {
      const columnsConfig = channel.columns ?? [];
      // Create columns values from config using lodash.set
      const columnsValues = columnsConfig.reduce(
        (acc, { name, example = "" }) => set(acc, name, example),
        {}
      );
      setColumns(JSON.stringify(columnsValues, null, 2));
    }
    // Set last result context if any
    setLastResultContext(
      localStorage.getItem(getStorageKey("context", workflowId))
    );
  }, [channel, workflowId]);

  function onColumnsChange(columns) {
    setColumns(columns);
    setColumnsError(null);
    localStorage.setItem(getStorageKey("columns", workflowId), columns);
  }

  async function run(e) {
    e.preventDefault();

    try {
      JSON.parse(columns);
    } catch (err) {
      setColumnsError(
        `Input columns is not a valid json value: ${err.message}`
      );
      return;
    }
    try {
      JSON.parse(context);
    } catch (err) {
      setContextError(
        `Input context is not a valid json value: ${err.message}`
      );
      return;
    }

    setRunning(true);
    try {
      const result = await WorkflowAPI.testRun(node, columns, context);

      setStep("result");
      setResult(result);

      localStorage.setItem(
        getStorageKey("context", workflowId),
        result.resultContext
          ? JSON.stringify(result.resultContext, null, 2)
          : "{}"
      );
    } finally {
      setRunning(false);
    }
  }

  async function previous() {
    setStep("setup");
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title as="h5">Test {node.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === "setup" && (
          <>
            <Form onSubmit={run}>
              <Form.Row>
                <Form.Group as={Col} xs="12">
                  <Form.Label>Input columns</Form.Label>
                  <CodeEditor
                    name="columns"
                    language="json"
                    code={columns}
                    onChange={onColumnsChange}
                  />
                  {columnsError && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {columnsError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs="12">
                  <Form.Label className={lastResultContext ? "mb-4" : ""}>
                    Input context
                  </Form.Label>
                  {lastResultContext && (
                    <Button
                      type="button"
                      variant="outline-primary"
                      className="ml-3"
                      size="sm"
                      onClick={() => setContext(lastResultContext)}
                    >
                      <FaPaste /> Paste result context from latest run
                    </Button>
                  )}
                  <CodeEditor
                    name="context"
                    language="json"
                    code={context}
                    onChange={setContext}
                  />
                  {contextError && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {contextError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>

              <Button
                type="submit"
                className="btn-block"
                variant="inline-success"
                disabled={running}
              >
                <FaPlay /> Run
              </Button>
            </Form>
          </>
        )}
        {currentStep === "result" && result && (
          <>
            {result.error && (
              <Row className="pb-3">
                <Col xs="12">
                  <b className="d-block mb-2">Error: </b>
                  <pre style={{ color: "#e63757", whiteSpace: "pre-wrap" }}>
                    {result.error}
                  </pre>
                </Col>
              </Row>
            )}
            <Row className="pb-3">
              <Col xs="12">
                <b className="mr-2">Status:</b>
                <SuccessBadge success={result.success} />
              </Col>
            </Row>
            {result.detail?.startedAt && result.detail?.endedAt && (
              <Row className="pb-3">
                <Col xs="12">
                  <b className="mr-2">Total time:</b>
                  <Badge pill variant="primary">
                    <FaRegClock />{" "}
                    {differenceInMilliseconds(
                      parseISO(result.detail.endedAt),
                      parseISO(result.detail.startedAt)
                    )}
                    ms
                  </Badge>
                </Col>
              </Row>
            )}
            {result.detail?.request && (
              <Row className="pb-3">
                <Col xs="12" className="pr-3">
                  <b className="mr-2">Method/Url:</b>
                  <code>
                    {result.detail.request.method.toUpperCase()}{" "}
                    {result.detail.request.url}
                  </code>
                </Col>
              </Row>
            )}
            {result.detail?.response?.status && (
              <Row className="pb-3">
                <Col xs={12} className="pr-3">
                  <b className="mr-2">Response status:</b>
                  <StatusBadge
                    status={result.detail.response?.status ?? null}
                  />
                </Col>
              </Row>
            )}
            {result.detail?.request?.headers && (
              <Row>
                <Col xs="12" className="pb-3">
                  <b className="d-block mb-2">Headers: </b>
                  <LegacyCodeEditor
                    readOnly={true}
                    showGutter={true}
                    mode="json"
                    code={JSON.stringify(
                      result.detail.request.headers,
                      null,
                      2
                    )}
                  />
                </Col>
              </Row>
            )}
            {result.detail?.request?.body && (
              <Row>
                <Col xs="12" className="pb-3">
                  <b className="d-block mb-2">Body: </b>
                  <LegacyCodeEditor
                    readOnly={true}
                    showGutter={true}
                    mode="json"
                    code={JSON.stringify(result.detail.request.body, null, 2)}
                  />
                </Col>
              </Row>
            )}
            {result.detail?.response?.body && (
              <Row>
                <Col xs="12" className="pb-3">
                  <b className="d-block mb-2">Response: </b>
                  <LegacyCodeEditor
                    readOnly={true}
                    showGutter={true}
                    mode="json"
                    code={JSON.stringify(result.detail.response.body, null, 2)}
                  />
                </Col>
              </Row>
            )}
            {result.detail?.conditionResult !== undefined && (
              <Row className="pb-3">
                <Col xs="12">
                  <b className="d-block mb-2">Condition result : </b>

                  {result.detail?.conditionResult === true ? (
                    <b style={{ color: "green" }}>Yes</b>
                  ) : (
                    <b style={{ color: "red" }}>No</b>
                  )}
                </Col>
              </Row>
            )}
            {result.detail?.foreachArrayResult !== undefined && (
              <Row className="pb-3">
                <Col xs="12">
                  <b className="d-block mb-2">Iterating on : </b>

                  <LegacyCodeEditor
                    readOnly={true}
                    showGutter={true}
                    mode="json"
                    code={JSON.stringify(
                      result.detail.foreachArrayResult,
                      null,
                      2
                    )}
                  />
                </Col>
              </Row>
            )}
            {result.resultContext && (
              <Row className="pb-3">
                <Col xs="12">
                  <b className="d-block mb-2">Result context: </b>
                  <LegacyCodeEditor
                    readOnly={true}
                    showGutter={true}
                    mode="json"
                    code={JSON.stringify(result.resultContext, null, 2)}
                  />
                </Col>
              </Row>
            )}

            <Button
              type="button"
              className="btn-block"
              variant="outline-primary"
              onClick={previous}
            >
              <FaBackward /> Back to setup
            </Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
