import React, { Component } from "react";
import { Form, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import FieldForm from "./FieldForm";

export default class AddField extends Component {
  state = {
    type: "value",
    name: "",
    content: ""
  };

  handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { type, name, content } = this.state;
    this.props.onAdd({ type, name, content });
    this.setState({ type: "value", name: "", content: "" });
  };

  render() {
    const { type, name, content } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <FieldForm
          type={type}
          name={name}
          content={content}
          onChange={this.handleInputChange}
        >
          <Col xs="auto">
            <Overlay
              placement="top"
              target={this.buttonRef}
              show={name !== "" && content !== ""}
            >
              {props => <Tooltip {...props}>Don't forget to confirm</Tooltip>}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="inline-alpha"
            >
              <FaPlus />
            </Button>
          </Col>
        </FieldForm>
      </Form>
    );
  }
}
