import React from "react";
import { Pagination } from "react-bootstrap";

export default function MonitoringPagination({
  currentPage,
  setPage,
  itemsCount
}) {
  const isFirstPage = currentPage === 1;

  return (
    <Pagination>
      {!isFirstPage && currentPage !== 2 ? (
        <Pagination.First onClick={() => setPage(1)} />
      ) : null}

      {!isFirstPage && (
        <Pagination.Prev onClick={() => setPage(currentPage - 1)} />
      )}

      <Pagination.Item active>{currentPage}</Pagination.Item>

      {itemsCount > 0 && (
        <Pagination.Next onClick={() => setPage(currentPage + 1)} />
      )}
    </Pagination>
  );
}
