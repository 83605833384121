import React, { useState, useRef, useLayoutEffect } from "react";
import { useWorkflow } from "../../contexts/WorkflowContext";
import NewNodeForm from "./WorkflowEditor/NewNodeForm";
import NodeForm from "./WorkflowEditor/nodes/forms/NodeForm";
export default function WorkflowSidebar() {
  const [width, setWidth] = useState(450);
  const resizeRef = useRef();
  const sidebarRef = useRef();
  const { channel, newNode, showNode, dispatch } = useWorkflow();
  const isOpen = (newNode !== null && showNode === null) || showNode !== null;

  useLayoutEffect(() => {
    const resizeBar = resizeRef.current;
    const initResize = () => {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResize);
    };
    const stopResize = () => {
      window.removeEventListener("mousemove", resize, false);
      window.removeEventListener("mouseup", stopResize, false);
    };
    const resize = e => {
      const delta = e.clientX - sidebarRef.current.offsetLeft;
      setWidth(width => width + delta * -1);
    };

    resizeBar.addEventListener("mousedown", initResize);
    return () => {
      resizeBar.removeEventListener("mousedown", initResize);
    };
  }, []);

  return (
    <div className="workflow-sidebar-container" ref={sidebarRef}>
      <div className="workflow-sidebar-resize" ref={resizeRef} />
      <div
        className="workflow-sidebar"
        style={
          isOpen
            ? { minWidth: "450px", width: `${width}px` }
            : { overflow: "auto" }
        }
      >
        {newNode !== null && showNode === null && (
          <NewNodeForm
            onSelect={type =>
              dispatch({ type: "createNode", payload: { type } })
            }
            onClose={() => dispatch({ type: "toggleNewNode" })}
            newNode={newNode}
            channel={channel}
          />
        )}
        {showNode !== null && (
          <NodeForm
            node={showNode}
            key={showNode.id}
            onClose={() => dispatch({ type: "toggleNode" })}
          />
        )}
      </div>
    </div>
  );
}
