import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import PrivateRoute from "./components/navigation/PrivateRoute";
import Navigation from "./components/navigation/Navigation";
import Login from "./components/Login";
import Channels from "./components/channels/Channels";
import EditChannel from "./components/channels/EditChannel";
import Upload from "./components/Upload";
import Monitoring from "./components/monitoring/Monitoring";
import Broadcast from "./components/Broadcast";
/** css imports need to stay here */
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";

import Subscriptions from "./components/subscriptions/Subscriptions";
import NewSubscription from "./components/subscriptions/NewSubscription";
import Datasources from "./components/Datasources";
import Stats from "./components/Stats";
import { ROLES } from "./authentication/auth";
import Toasts from "./components/Toasts";
import Tracing from "./components/monitoring/Tracing";
import ToastProvider from "./contexts/ToastContext";
import Users from "./components/users/Users";
import EditUser from "./components/users/EditUser";
import Dashboard from "./components/dashboard/Settings";
import SubscriptionEditor from "./components/subscriptions/calls/ConfigureCalls";
import WorkflowContainer from "./components/workflow/WorkflowContainer";
import WorkflowTracing from "./components/monitoring/workflow/WorkflowTracing";
import Workflows from "./components/workflow/Workflows";
import NewWorkflow from "./components/workflow/NewWorkflow";
import WorkflowVersions from "./components/workflow/WorkflowVersions";
import NavbarProvider from "./contexts/NavbarContext";

export default class App extends Component {
  render() {
    return (
      <ToastProvider>
        <NavbarProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <>
              <Navigation />
              <Switch>
                <Redirect exact from="/" to="/channels" />
                <Route path="/login" component={Login} />
                <PrivateRoute exact path="/channels" component={Channels} />
                <PrivateRoute
                  exact
                  path="/channels/create"
                  component={EditChannel}
                />
                <PrivateRoute
                  exact
                  path="/channels/edit/:id"
                  component={EditChannel}
                />

                <PrivateRoute
                  exact
                  path="/subscriptions"
                  component={Subscriptions}
                />
                <PrivateRoute
                  exact
                  path="/subscriptions/create"
                  component={NewSubscription}
                />
                <PrivateRoute
                  exact
                  path="/subscriptions/edit/:id"
                  component={SubscriptionEditor}
                />
                <PrivateRoute exact path="/workflows" component={Workflows} />
                <PrivateRoute
                  exact
                  path="/workflows/create"
                  component={NewWorkflow}
                />
                <PrivateRoute
                  exact
                  path="/workflows/edit/:id"
                  component={WorkflowVersions}
                />
                <PrivateRoute
                  exact
                  path="/workflows/:id/versions/edit/:versionId"
                  component={WorkflowContainer}
                />
                <PrivateRoute path="/upload" component={Upload} />
                <PrivateRoute
                  path="/monitoring/:type(subscriptions|workflows)"
                  component={Monitoring}
                />
                <Redirect from="/monitoring" to="/monitoring/workflows" />
                <PrivateRoute path="/tracings/:id" component={Tracing} />
                <PrivateRoute
                  path="/workflowTracings/:id"
                  component={WorkflowTracing}
                />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/stats"
                  component={Stats}
                />
                <PrivateRoute path="/broadcast" component={Broadcast} />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/users/create"
                  component={EditUser}
                />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/users/edit/:id"
                  component={EditUser}
                />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/users"
                  component={Users}
                />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/datasources"
                  component={Datasources}
                />
                <PrivateRoute
                  authorized={[ROLES.ADMIN]}
                  path="/dashboard"
                  component={Dashboard}
                />
              </Switch>
              <Toasts />
            </>
          </Router>
        </NavbarProvider>
      </ToastProvider>
    );
  }
}
