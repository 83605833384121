import { differenceInMilliseconds, parseISO } from "date-fns";
import React, { useState } from "react";
import { Badge, Col, Collapse, Row } from "react-bootstrap";
import {
  FaAngleDown,
  FaAngleUp,
  FaCode,
  FaCodeBranch,
  FaRegClock
} from "react-icons/fa";
import { formatHours } from "../../../utils/format";
import LegacyCodeEditor from "../../LegacyCodeEditor";
import SuccessBadge from "../SuccessBadge";
import "./WorkflowHistoryRow.scss";
import StatusBadge from "./../StatusBadge";
import { MdHttp, MdRefresh, MdSend } from "react-icons/md";

export default function WorkflowHistoryRow({ history, nodeType }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Row
        className={`workflow-history-row no-gutters  border-bottom py-3 px-3 position-relative ${
          history.parent ? "has-parent" : ""
        }`}
        onClick={() => setShowMore(!showMore)}
      >
        <Col md="auto" className="pr-3">
          <SuccessBadge success={history.success} />
        </Col>
        <Col md="auto" className="pr-3">
          {getLogo(history.nodeType)}
        </Col>
        <Col md={history.nodeType === "HTTP" ? "auto" : ""} className="pr-3">
          <b>{history.nodeName}</b>
          {history.nodeType === "FOREACH" && (
            <p>
              <p></p>
              <span>
                (only the first row and the first errored row, if any, are
                logged below){" "}
              </span>
            </p>
          )}
        </Col>
        {history.nodeType === "HTTP" && (
          <>
            <Col md="auto" className="pr-3">
              <StatusBadge status={history.detail?.response?.status ?? null} />
            </Col>

            {history.detail && (
              <Col className="pr-3">
                <code>
                  {history.detail.request.method.toUpperCase()}{" "}
                  {history.detail.request.url}
                </code>
              </Col>
            )}
          </>
        )}

        {history.detail?.startedAt && history.detail?.endedAt && (
          <>
            <Col md="auto" className="pr-3">
              <Badge pill variant="primary">
                <FaRegClock />{" "}
                {differenceInMilliseconds(
                  parseISO(history.detail.endedAt),
                  parseISO(history.detail.startedAt)
                )}
                ms
              </Badge>
            </Col>
            <Col md="auto">{formatHours(history.detail.startedAt)}</Col>
          </>
        )}
        <Col md="auto" className="pl-2">
          {showMore ? (
            <FaAngleUp className="workflow-history-row-more" />
          ) : (
            <FaAngleDown className="workflow-history-row-more" />
          )}
        </Col>
      </Row>

      <Collapse in={showMore}>
        <Row className="no-gutters align-items-center border-bottom pt-2 pb-4 px-3">
          {history.error && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Error: </b>
              <pre style={{ color: "#e63757" }}>{history.error}</pre>
            </Col>
          )}
          {history.detail?.request?.headers && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Headers: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={true}
                mode="json"
                code={JSON.stringify(history.detail.request.headers, null, 2)}
              />
            </Col>
          )}
          {history.detail?.request?.body && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Body: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={true}
                mode="json"
                code={JSON.stringify(history.detail.request.body, null, 2)}
              />
            </Col>
          )}
          {history.detail?.response?.body && (
            <Col xs="12" className="pb-3">
              <b className="d-block mb-2">Response: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={true}
                mode="json"
                code={JSON.stringify(history.detail.response.body, null, 2)}
              />
            </Col>
          )}
          {nodeType !== "FOREACH" && history.context && (
            <Col xs="12">
              <b className="d-block mb-2">Context: </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={true}
                mode="json"
                code={JSON.stringify(history.context, null, 2)}
              />
            </Col>
          )}
          {history.detail?.foreachArrayResult && (
            <Col xs="12">
              <b className="d-block mb-2">Iterating on : </b>
              <LegacyCodeEditor
                readOnly={true}
                showGutter={true}
                mode="json"
                code={JSON.stringify(
                  history.detail?.foreachArrayResult,
                  null,
                  2
                )}
              />
            </Col>
          )}
          {history.detail?.conditionResult !== undefined && (
            <Col xs="12">
              <b className="d-block mb-2">Condition result : </b>

              {history.detail?.conditionResult === true ? (
                <b style={{ color: "green" }}>Yes</b>
              ) : (
                <b style={{ color: "red" }}>No</b>
              )}
            </Col>
          )}
        </Row>
      </Collapse>
    </>
  );
}

function getLogo(nodeType) {
  switch (nodeType) {
    case "HTTP": {
      return <MdHttp className="row-logo" />;
    }
    case "CODE": {
      return <FaCode className="row-logo" />;
    }
    case "CONDITIONAL": {
      return <FaCodeBranch className="row-logo" />;
    }
    case "FOREACH": {
      return <MdRefresh className="row-logo" />;
    }
    case "RESPONSE": {
      return <MdSend className="row-logo" />;
    }
    case "WAIT": {
      return <FaRegClock className="row-logo" />;
    }
    default: {
      return null;
    }
  }
}
