import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import LegacyCodeEditor from "../../../LegacyCodeEditor";

export default class WorkflowForm extends Component {
  render() {
    const {
      condition,
      action,
      step,
      onChange,
      availableCalls,
      children = null
    } = this.props;

    return (
      <Form.Row style={{ alignItems: "flex-end" }}>
        <Col xs={12}>
          <Form.Label>If</Form.Label>
          <LegacyCodeEditor
            name="condition"
            onChange={value =>
              onChange({
                target: { name: "condition", value }
              })
            }
            code={condition}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Action</Form.Label>
          <Form.Control
            as="select"
            required
            name="action"
            onChange={onChange}
            value={action}
          >
            <option value="stop">Stop</option>
            <option value="goto">Go to</option>
          </Form.Control>
        </Col>

        {action === "goto" && (
          <Col xs={3}>
            <Form.Label>Go to</Form.Label>
            <Form.Control
              as="select"
              required
              name="step"
              onChange={onChange}
              value={step}
              className={!step ? "select-default" : ""}
            >
              <option value="">Select a request</option>
              {availableCalls.map((call, index) => (
                <option key={index} value={index + 1}>
                  {call.name || `Http request n°${index + 1}`}
                </option>
              ))}
            </Form.Control>
          </Col>
        )}
        {children}
      </Form.Row>
    );
  }
}
