import React, { useState, useRef } from "react";
import { Badge, Overlay, Popover, PopoverTitle } from "react-bootstrap";
import { FaQuestion } from "react-icons/fa";

export default function Help({
  placement = "right",
  title,
  children,
  maxWidth = "276px",
  maxHeight = "800px",
  width = "auto"
}) {
  const [show, setShow] = useState(false);
  const target = useRef();

  return (
    <>
      <Badge
        ref={target}
        pill
        variant="light"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <FaQuestion />
      </Badge>

      <Overlay target={target.current} show={show} placement={placement}>
        {props => {
          const { style, ...otherProps } = props;
          return (
            <Popover
              style={{
                ...style,
                maxWidth,
                width
              }}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              {...otherProps}
            >
              <PopoverTitle>{title}</PopoverTitle>
              <Popover.Content
                style={{
                  maxHeight,
                  overflow: "scroll"
                }}
              >
                {children}
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
}
