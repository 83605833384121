import BaseAPI from "./BaseAPI";

/**
 * Subscription API
 * @extends BaseAPI
 */
export default class Subscription extends BaseAPI {
  /**
   * Create a new subscription
   *
   * @param {Object} subscription The subscription Object
   * @returns {Promise<Object>} Creation result
   */
  static create(subscription) {
    return this.execute("post", "/subscriptions", subscription);
  }

  /**
   * Edit a Subscription
   *
   * @param {String} id The Subscription ID
   * @param {Object} subscription The updated Subscription
   * @returns {Promise<Object>} Update results
   */
  static edit(id, subscription) {
    return this.execute("put", `/subscriptions/${id}`, subscription);
  }

  /**
   * Get all the subscriptions
   *
   * @returns {Promise<Array[Object]>} All the subscriptions
   */
  static all() {
    return this.execute("get", "/subscriptions");
  }

  /**
   * Get a Subscription by its ID
   *
   * @param {String} id The Subscription ID
   * @returns {Promise<Object>} The Subscription
   */
  static get(id) {
    return this.execute("get", `/subscriptions/${id}`);
  }

  /**
   * Delete a Subscription by its ID
   *
   * @param {String} id The Subscription ID
   * @returns {Promise<Object>} The Subscription
   */
  static delete(id) {
    return this.execute("delete", `/subscriptions/${id}`);
  }

  /**
   * Approve a Subscription by its ID
   *
   * @param {String} id The Subscription ID
   * @returns {Promise<Object>} The Subscription
   */
  static approve(id) {
    return this.execute("PATCH", `/subscriptions/${id}/approve`);
  }

  /**
   * Reject a Subscription by its ID
   *
   * @param {String} id The Subscription ID
   * @returns {Promise<Object>} The Subscription
   */
  static reject(id) {
    return this.execute("PATCH", `/subscriptions/${id}/reject`);
  }
}
