import React, { Component } from "react";
import { Form, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import BusinessTraceForm from "./BusinessTraceForm";

export default class AddBusinessTrace extends Component {
  state = {
    type: "string",
    name: "",
    variable: ""
  };

  handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { type, name, variable } = this.state;
    this.props.onAdd({ type, name, variable });
    this.setState({ type: "string", name: "", variable: "" });
  };

  render() {
    const { name, type, variable } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <BusinessTraceForm
          name={name}
          type={type}
          variable={variable}
          onChange={this.handleInputChange}
        >
          <Col xs="auto">
            <Overlay
              placement="top"
              target={this.buttonRef}
              show={name !== "" && type !== ""}
            >
              {props => <Tooltip {...props}>Don't forget to confirm</Tooltip>}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="inline-alpha"
            >
              <FaPlus />
            </Button>
          </Col>
        </BusinessTraceForm>
      </Form>
    );
  }
}
