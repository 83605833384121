import React, { useEffect, useRef } from "react";
import { Badge, Breadcrumb, Button, Card, Col, Row } from "react-bootstrap";
import {
  FaFileExport,
  FaFileImport,
  FaSave,
  FaWindowClose
} from "react-icons/fa";
import { Link, NavLink, Prompt } from "react-router-dom";
import { useNavbar } from "../../contexts/NavbarContext";
import { useToasts } from "../../contexts/ToastContext";
import { useWorkflow } from "../../contexts/WorkflowContext";
import { loadFile, saveFile } from "../../utils/file";
import { errorMessage } from "../../utils/format";
import "./WorkflowEditor.scss";
import NodeContainer from "./WorkflowEditor/NodeContainer";
import TestRunModal, { cleanStorage } from "./WorkflowEditor/TestRunModal";
import WorkflowSidebar from "./WorkflowSidebar";

export default function WorkflowEditor() {
  const {
    id,
    name,
    channel,
    version,
    saveWorkflow,
    nodes,
    touched,
    isDeployed,
    testRun,
    closeTestRun,
    updateNodes
  } = useWorkflow();

  const { setDisplay } = useNavbar();

  // Hide navbar
  useEffect(() => {
    setDisplay(false);
    return () => {
      setDisplay(true);
    };
  }, [setDisplay]);

  // Handling unsaved workflow
  useEffect(() => {
    if (touched) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  }, [touched]);

  // Delete test run modal storage when exiting editor
  useEffect(() => {
    return () => {
      cleanStorage(id);
    };
  }, [id]);

  const { addToast } = useToasts();
  const importRef = useRef();

  const save = async () => {
    if (isDeployed) {
      const confirmed = window.confirm(
        "Are you sure you want to update a currently deployed workflow ?"
      );
      if (!confirmed) {
        return;
      }
    }
    try {
      await saveWorkflow();
      addToast(
        "success",
        "Workflow version has been saved successfully.",
        "Success",
        <Link to={`/workflows/edit/${id}`}> Go back to workflows</Link>
      );
    } catch (err) {
      addToast("error", errorMessage(err), "Failed to update subscription");
    }
  };

  const exportWorkflow = () => {
    let filename = `workflow-${id}-${version}.ecosystem.json`;
    let contentType = "application/json;charset=utf-8;";
    const exportData = { nodes };
    saveFile(filename, contentType, exportData);
  };

  const importWorkflow = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    loadFile(file, content => {
      try {
        const { nodes = {} } = JSON.parse(content);
        updateNodes(nodes);
      } catch (err) {}
    });
  };

  return (
    <>
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col xs="4" className="d-flex">
                <Breadcrumb className="editor-breadcrumb">
                  <Breadcrumb.Item active>
                    <NavLink to={`/workflows/edit/${id}`}>
                      {"<"} &nbsp; Workflow
                    </NavLink>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Version {version}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col
                xs="4"
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className="mb-1">{name}</h4>
                <Badge pill className="ml-3 badge-primary ">
                  {version}
                </Badge>
              </Col>
              <Col xs="4" className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  onClick={() => importRef.current.click()}
                  size="sm"
                  variant="inline-primary"
                >
                  <FaFileImport />
                  &nbsp; Import
                </Button>
                <input
                  id="import"
                  type="file"
                  ref={importRef}
                  style={{ display: "none" }}
                  onChange={importWorkflow}
                  accept="application/json"
                />
                <Button
                  className="mr-2"
                  onClick={exportWorkflow}
                  size="sm"
                  variant="inline-primary"
                >
                  <FaFileExport />
                  &nbsp; Export
                </Button>
                <Button
                  onClick={() => save()}
                  size="sm"
                  variant="inline-success"
                >
                  <FaSave />
                  &nbsp; Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="workflow-editor-container">
          <div className="workflow-editor">
            <NodeContainer node={nodes.channel} />
            {nodes.global_error_catch && (
              <NodeContainer
                node={nodes.global_error_catch}
                startIcon={<FaWindowClose color="red" />}
                title={nodes.global_error_catch.name}
              />
            )}
          </div>
          <WorkflowSidebar />
        </div>
      </Col>
      {testRun && (
        <TestRunModal
          channel={channel}
          workflowId={id}
          node={testRun.node}
          onClose={closeTestRun}
        />
      )}
      <Prompt
        when={touched}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </>
  );
}
