import BaseAPI from "./BaseAPI";

/**
 * User API
 * @extends BaseAPI
 */
export default class User extends BaseAPI {
  /**
   * Get users filter by optionnal role (restricted to admin in API)
   *
   * @returns {Promise<[Object]>} Users
   */
  static all(role = null) {
    return this.execute("get", `/users${role ? `?role=${role}` : ""}`);
  }

  /**
   * Delete a user
   * @returns {Promise<void>}
   */
  static delete(id) {
    return this.execute("delete", `/users/${id}`);
  }

  /**
   * Get a user
   * @returns {Promise<Object>}
   */
  static get(id) {
    return this.execute("get", `/users/${id}`);
  }

  /**
   * Create a new User
   *
   * @returns {Promise<Object>} Creation result
   */
  static create(user) {
    return this.execute("post", "/users", user);
  }

  /**
   * Create a new User
   *
   * @returns {Promise<Object>} update result
   */
  static edit(id, user) {
    return this.execute("put", `/users/${id}`, user);
  }

  static getChannels() {
    return this.execute("get", "/users/current/channels");
  }

  static getSubscriptions() {
    return this.execute("get", "/users/current/subscriptions");
  }

  static getWorkflows() {
    return this.execute("get", "/users/current/workflows");
  }

  /**
   * Login the User
   *u
   * @param {Object} data The user login/password
   * @returns {Promise<Object>} the login response
   */
  static login(credentials) {
    return this.execute("post", "/login", credentials);
  }
}
