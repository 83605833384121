import React, { useState, useMemo } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { FaPlay, FaTrash } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import AnonymizeInput from "../../../../AnonymizeInput";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AddField from "../../../../subscriptions/calls/fields/AddField";
import FieldForm from "../../../../subscriptions/calls/fields/FieldForm";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

export default function FormNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();

  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);

  const { variables } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );
  const updateConfig = (key, value) => {
    setConfig(config => ({ ...config, [key]: value }));
  };

  const addField = field => {
    setConfig(config => ({
      ...config,
      fields: [...config.fields, field]
    }));
  };

  const handlebarsExample = `{
    "User-Agent": "Mozilla/5.0",
    "Authorization": "Basic {{myAuthData}}",
    "x-tracking-id": "{{ columns.[tracking id] }}",
    "x-transaction-id": "{$ libs.uuid() $}"
  }`;

  const HeadersHelper = (
    <Help title="Headers" placement="left" maxWidth="650px">
      <p>
        Use{" "}
        <a
          href="https://handlebarsjs.com/guide/#what-is-handlebars"
          target="_blank"
          rel="noopener noreferrer"
        >
          Handlebars
        </a>{" "}
        to define the form headers.
      </p>
      <p>
        Here are the syntaxes for a hardcoded value, a variable value, a context
        variable with space in key name, and a javascript code :
      </p>
      <LegacyCodeEditor readOnly code={handlebarsExample} />
    </Help>
  );

  const FieldsHelper = (
    <Help title="Fields" placement="left" maxWidth="650px">
      <p>
        Use{" "}
        <a
          href="https://handlebarsjs.com/guide/#what-is-handlebars"
          target="_blank"
          rel="noopener noreferrer"
        >
          Handlebars
        </a>{" "}
        to define the form fields. There are two types of fields:
      </p>
      <p>
        The value type of field is composed of a name and a value. The value can
        be a constant, or a calculated value like :<br />
        {"{{context.myValue}}"}
      </p>
      <p>
        The file type is used to simulate a file upload. The remote server will
        be presented a simulated file named as specified in the name field. Most
        form processors need this name to have a suffix indicating the type of
        file, for instance <b>myfile.xml</b>. The content is not passed using a
        real file but must be prepared in some context variable, in a previous
        step, and used here using a syntax such as :<br />
        {"{{context.myContent}}"}
      </p>
    </Help>
  );

  const updateField = ({ target }, index) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setConfig(config => ({
      ...config,
      fields: config.fields.map((field, currentIndex) => {
        if (currentIndex === index) {
          return { ...field, [name]: value };
        }
        return field;
      })
    }));
  };

  const deleteField = deleteIndex => {
    setConfig(config => ({
      ...config,
      fields: config.fields.filter((_field, index) => index !== deleteIndex)
    }));
  };

  const updateAnonymize = ({ target }) => {
    setConfig(config => ({
      ...config,
      anonymize: {
        ...config.anonymize,
        [target.name]: target.checked
      }
    }));
  };

  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };

  const updateRetryOptions = ({ target }) => {
    setConfig(config => ({
      ...config,
      retryOptions: {
        ...config.retryOptions,
        [target.name]: target.value
      }
    }));
  };

  const {
    url,
    headers,
    fields = [],
    anonymize = {},
    retryActive = false,
    retryOptions = {},
    continueWorkflow = false,
    timeout = DEFAULT_REQUEST_TIMEOUT
  } = config;
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, name, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Url*</Form.Label>
              <Form.Control
                required
                type="text"
                name="url"
                title="Must be a valid url"
                pattern="https?:\/\/.+"
                placeholder="https://api.test.com/products/{{columns.productId}}"
                onChange={({ target }) => updateConfig("url", target.value)}
                value={url}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Headers</Form.Label>
            {HeadersHelper}

            <Form.Group>
              <CodeEditor
                language="handlebars"
                code={headers}
                onChange={value => updateConfig("headers", value)}
              />
              <br />
              <AnonymizeInput
                checked={anonymize.headers}
                name="headers"
                onChange={updateAnonymize}
                label="Anonymize Headers"
                width={230}
              />
            </Form.Group>

            <Form.Label>Fields</Form.Label>
            {FieldsHelper}

            <Form.Group>
              {fields.map((field, index) => (
                <div key={index}>
                  <FieldForm {...field} onChange={e => updateField(e, index)}>
                    <Col xs="auto">
                      <Button
                        type="button"
                        size="lg"
                        variant="inline-danger"
                        onClick={() => deleteField(index)}
                      >
                        <FaTrash />
                      </Button>
                    </Col>
                  </FieldForm>
                  <br />
                </div>
              ))}
              <AddField onAdd={addField} />
              <br />
              <AnonymizeInput
                checked={anonymize.body}
                name="body"
                onChange={updateAnonymize}
                label="Anonymize fields"
                width={210}
              />
            </Form.Group>
          </Form.Group>

          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="retry-switch"
              name="retryActive"
              label="Auto-retry"
              checked={retryActive}
              onChange={({ target }) =>
                updateConfig("retryActive", target.checked)
              }
            />
            <Help title="Auto retry">
              <p>Automatically retries failed submit.</p>
              <p>
                <i>Between 1 to 5 retries</i>
              </p>
              <p>
                Submit <b>will be considered failed</b> if there is{" "}
                <b>no response</b> (ENOTFOUND, ETIMEDOUT, etc).
              </p>
              <p>
                Or if <b>response status code</b> is in :
                <ul>
                  <li>100 {"->"} 199</li>
                  <li>429</li>
                  <li>500 {"->"} 599</li>
                </ul>
              </p>
              <p>
                Exponential backoff is used between retries:
                <ul>
                  <li>First retry after 500ms</li>
                  <li>Second retry after 1.5s</li>
                  <li>Third retry after 3.5s</li>
                  <li>Fourth retry after 7.5s</li>
                  <li>Fifth retry after 15.5s</li>
                </ul>
              </p>
            </Help>
          </Form.Row>
          {retryActive === true && (
            <Form.Row className="mt-3">
              <Col xs={3}>
                <Form.Label>Retry count*</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  required
                  value={retryOptions.count}
                  min={1}
                  max={5}
                  onChange={updateRetryOptions}
                />
                <Form.Text className="text-muted">
                  Between 1 and 5 retries
                </Form.Text>
              </Col>
            </Form.Row>
          )}
          <hr />
          <Form.Group>
            <Form.Label>Timeout</Form.Label>
            <Help title="Submit timeout">
              <>
                <p>
                  Timeout specifies the number of seconds before the form submit
                  times out. If the submit processing takes longer than timeout,
                  the submit will be aborted.
                </p>
                <p>Minimum: 1, Maximum: 300.</p>
              </>
            </Help>
            <Form.Control
              type="number"
              name="timeout"
              placeholder="15"
              min={1}
              max={300}
              onChange={({ target }) => updateConfig("timeout", target.value)}
              value={timeout}
            />
            <Form.Text className="text-muted">
              In seconds (default to 15 seconds)
            </Form.Text>
          </Form.Group>
          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="continue-workflow"
              name="continueWorkflow"
              label="Continue workflow if submit failed"
              checked={continueWorkflow}
              onChange={({ target }) =>
                updateConfig("continueWorkflow", target.checked)
              }
            />
            <Help title="Continue workflow">
              <p>
                By default, the workflow will stop if a form submit fails. By
                activating this option, workflow will continue even if this
                submit returns an error response. After the submit an object
                named lastResponse will be available in the context containing
                status and success of last submit response
              </p>
              <LegacyCodeEditor
                code={JSON.stringify(
                  {
                    context: { lastResponse: { status: 400, success: false } }
                  },
                  null,
                  2
                )}
                readOnly
              />
            </Help>
          </Form.Row>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
