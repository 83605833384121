import React, { Component } from "react";
import { Form, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import ExtractorForm from "./ExtractorForm";

export default class AddExtractor extends Component {
  state = {
    part: "body",
    name: "",
    path: "",
    anonymize: false
  };

  handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { name, path, part, anonymize } = this.state;
    this.props.onAdd({ part, name, path, anonymize });
    this.setState({ part: "body", name: "", path: "", anonymize: false });
  };

  render() {
    const { name, path, anonymize } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <ExtractorForm
          name={name}
          path={path}
          anonymize={anonymize}
          onChange={this.handleInputChange}
        >
          <Col xs="auto">
            <Overlay
              placement="top"
              target={this.buttonRef}
              show={name !== "" && path !== ""}
            >
              {props => <Tooltip {...props}>Don't forget to confirm</Tooltip>}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="inline-alpha"
            >
              <FaPlus />
            </Button>
          </Col>
        </ExtractorForm>
      </Form>
    );
  }
}
