import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row
} from "react-bootstrap";
import { FaCode, FaLongArrowAltRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { formatLongDate } from "../../utils/format";
import TracingApi from "./../../api/Tracing";
import LegacyCodeEditor from "./../LegacyCodeEditor";
import HistoryRow from "./HistoryRow";
import ColumnsRow from "./ColumnsRow";
import Loader from "../Loader";
import SuccessBadge from "./SuccessBadge";
import TimeBadge from "./TimeBadge";

export default function Tracing({ match }) {
  const [tracing, setTracing] = useState(null);
  const [detail, setDetail] = useState("");

  useEffect(() => {
    const tracingId = match.params.id;
    async function fetchTracing() {
      const tracing = await TracingApi.get(tracingId);
      setTracing(tracing);
    }
    fetchTracing();
  }, [match.params.id]);

  const showDetail = () => {
    setDetail(JSON.stringify(tracing, null, 2));
  };

  if (!tracing) {
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item active>
          <NavLink to="/monitoring">Monitoring</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Tracing</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={12}>
          <Card className="mb-3">
            <Card.Header className="border-bottom">
              <Row>
                <Col>
                  <h5 className="mb-2">Tracing detail</h5>
                  {tracing.channel ? (
                    tracing.channel.company.login
                  ) : (
                    <i>Unknown</i>
                  )}
                  &nbsp;
                  <FaLongArrowAltRight />{" "}
                  {tracing.subscriber ? (
                    tracing.subscriber.login
                  ) : (
                    <i>Unknown</i>
                  )}
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={showDetail}
                    size="sm"
                    variant="inline-primary"
                    className="mr-3"
                  >
                    <FaCode />
                    &nbsp; Raw Tracing
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <p>
                <b>Date: </b>
                {formatLongDate(tracing.startedAt)}
              </p>
              <p>
                <b className="mr-2">Total time:</b>
                <TimeBadge
                  startedAt={tracing.startedAt}
                  endedAt={tracing.endedAt}
                />
              </p>
              <p>
                <b className="mr-2">Status:</b>
                <SuccessBadge success={tracing.success} />
              </p>
            </Card.Body>
            {tracing.error && (
              <Card.Footer style={{ background: "#FAD7DD" }}>
                <b>Last Error: </b>
                <pre style={{ color: "#e63757" }}>{tracing.error}</pre>
              </Card.Footer>
            )}
            {tracing.response && (
              <Card.Footer style={{ background: "#F9FAFD" }}>
                <b className="d-block mb-2">Response to channel: </b>
                <LegacyCodeEditor
                  readOnly
                  showGutter={false}
                  mode="json"
                  code={JSON.stringify(tracing.response, null, 2)}
                />
                {tracing.responseStatus && (
                  <p className="mt-3">
                    <b className="mr-2">Response status:</b>
                    <Badge
                      pill
                      variant={
                        tracing.responseStatus < 300 ? "primary" : "danger"
                      }
                    >
                      {tracing.responseStatus}
                    </Badge>
                  </p>
                )}
              </Card.Footer>
            )}
          </Card>

          <Card className="mb-3">
            <Card.Header className="border-bottom">
              <h5>From channel</h5>
            </Card.Header>
            <Card.Body>
              <p>
                <b>Name: </b>
                {tracing.channel ? tracing.channel.name : <i>Unknown</i>}
              </p>
              <p>
                <b>Company: </b>
                {tracing.channel ? (
                  tracing.channel.company.login
                ) : (
                  <i>Unknown</i>
                )}
              </p>
              {tracing.columns && (
                <>
                  <ColumnsRow
                    columns={JSON.stringify(tracing.columns, null, 2)}
                  />
                </>
              )}
            </Card.Body>
          </Card>
          <Card className="mb-5 ">
            <Card.Header className="border-bottom">
              <h5>History</h5>
            </Card.Header>
            <Card.Body className="p-0" style={{ borderBottom: 0 }}>
              {tracing.history.length > 0 ? (
                <div className="history">
                  {tracing.history.map(row => (
                    <HistoryRow key={row._id} history={row} />
                  ))}
                </div>
              ) : (
                <i className="p-3">No requests.</i>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={detail !== ""} onHide={() => setDetail("")} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegacyCodeEditor readOnly mode="json" height="500px" code={detail} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDetail("")}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
