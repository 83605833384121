import React, { Component } from "react";
import { MdBuild } from "react-icons/md";
import { Button, Col, Form } from "react-bootstrap";
import Columns from "../columns/Columns";

export default class IotChannelForm extends Component {
  state = {
    showAdvanced: false
  };

  toggleAdvanced = () => {
    this.setState(({ showAdvanced }) => ({
      showAdvanced: !showAdvanced
    }));
  };

  render() {
    const { channel, onMetaChange, onChange } = this.props;
    const { meta = {} } = channel;
    const { showAdvanced } = this.state;
    return (
      <>
        <Form.Group>
          <Form.Label>Protocol*</Form.Label>
          <Form.Control
            as="select"
            required
            name="iotProtocol"
            className={!meta.iotProtocol ? "select-default" : ""}
            onChange={onMetaChange}
            value={meta.iotProtocol}
          >
            <option value="">Select a type</option>
            <option value="mqtt">MQTT</option>
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Host*</Form.Label>
            <Form.Control
              type="text"
              required
              name="iotHost"
              placeholder="mqtt://broker.hivemq.com"
              value={meta.iotHost}
              onChange={onMetaChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs={2}>
            <Form.Label>Port</Form.Label>
            <Form.Control
              type="text"
              name="iotPort"
              placeholder="1883"
              value={meta.iotPort}
              onChange={onMetaChange}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Label>Topic*</Form.Label>
          <Form.Control
            type="text"
            required
            name="iotTopic"
            placeholder=""
            value={meta.iotTopic}
            onChange={onMetaChange}
          />
        </Form.Group>
        <Form.Group>
          <Button type="button" onClick={this.toggleAdvanced} variant="light">
            <MdBuild /> Advanced
          </Button>
          {showAdvanced && (
            <div style={{ paddingTop: "1rem" }}>
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="iotUsername"
                  placeholder="username"
                  value={meta.iotUsername}
                  onChange={onMetaChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  name="iotPassword"
                  placeholder="password"
                  value={meta.iotPassword}
                  onChange={onMetaChange}
                />
              </Form.Group>
            </div>
          )}
        </Form.Group>
        <Columns columns={channel.columns} onChange={onChange} />
      </>
    );
  }
}
