import React, { Component } from "react";
import { Button, Col, Modal, Row, Form, Alert } from "react-bootstrap";
import LegacyCodeEditor from "../../../LegacyCodeEditor";
import jsonata from "jsonata";

export default class ExtractorPlayground extends Component {
  defaultJson = {
    cart: {
      products: [
        {
          name: "Product 1",
          price: 100
        },
        {
          name: "Product 2",
          price: 55
        }
      ],
      coupon: "-10%"
    }
  };

  state = {
    json: "",
    path: "",
    result: ""
  };

  prettyStringify = json => JSON.stringify(json, null, 2);

  componentDidMount = () => {
    const json = this.prettyStringify(this.defaultJson);
    const path =
      this.props.path === "" ? "cart.products[].price" : this.props.path;

    this.setState({
      json: this.prettyStringify(this.defaultJson),
      path,
      result: this.prettyStringify(this.extract(path, json))
    });
  };

  updateJson = json => {
    const { path } = this.state;
    let extractResult = this.extract(path, json);

    this.setState({
      json,
      result: this.prettyStringify(extractResult)
    });
  };

  updatePath = ({ target }) => {
    const extractResult = this.extract(target.value, this.state.json);
    this.setState({
      path: target.value,
      result: this.prettyStringify(extractResult)
    });
  };

  extract(path, json) {
    let expression;
    try {
      expression = jsonata(path);
    } catch (err) {
      return err.message;
    }
    let parsedResponse;
    try {
      parsedResponse = JSON.parse(json);
    } catch (err) {
      return err.message;
    }
    let extracted;
    try {
      const result = expression.evaluate(parsedResponse);
      extracted = result === undefined ? null : result;
    } catch (err) {
      return err.message;
    }
    return extracted;
  }
  render() {
    const { json, path, result } = this.state;
    return (
      <Modal
        show={true}
        onHide={this.props.onClose}
        className="modal-playground modal-xxl"
      >
        <Modal.Header>
          <Modal.Title as="h5">Extractor Playground</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center">
            <Alert variant="info">
              We are using JSONata behind the scene to help you extract pieces
              of data, &nbsp;
              <a
                href="https://docs.jsonata.org/simple"
                target="_blank"
                rel="noopener noreferrer"
              >
                see the documention
              </a>
              &nbsp;for the <b>path</b> syntax.
            </Alert>
          </Row>
          <Row>
            <Col>
              <h6>JSON</h6>
              <LegacyCodeEditor
                name="json"
                onChange={this.updateJson}
                code={json}
              />
            </Col>
            <Col>
              <Row className="mb-3">
                <h6>Path</h6>
                <Form.Control
                  type="text"
                  name="path"
                  value={path}
                  onChange={this.updatePath}
                />
              </Row>
              <Row>
                <h6>Result</h6>
                <LegacyCodeEditor readonly code={result} />
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.props.onPathSave(path)}>
            Save path
          </Button>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
