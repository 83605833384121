import React, { useState, useMemo } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FaPlay, FaTrash } from "react-icons/fa";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import AnonymizeInput from "../../../../AnonymizeInput";
import CodeEditor from "../../../../CodeEditor";
import Help from "../../../../Help";
import LegacyCodeEditor from "../../../../LegacyCodeEditor";
import AddExtractor from "../../../../subscriptions/calls/extractors/AddExtractor";
import ExtractorForm from "../../../../subscriptions/calls/extractors/ExtractorForm";
import AvailableVariables from "../../AvailableVariables";
import EditableText from "../../EditableText";

export const DEFAULT_REQUEST_TIMEOUT = 15; // 15 seconds

const handlebarsExample = `{
  "message": "ok",
  "id": "{{ context.id }}",
  "lastname": "{{ context.[last name] }}",
  "code": "{$ libs.uuid() $}"
}`;

const handlebarsObjectExample = `{
  "user": {{{ json context.user }}}
}`;

const PartsHelper = (
  <Help title="Headers/Body" placement="left" maxWidth="650px">
    <p>
      Use{" "}
      <a
        href="https://handlebarsjs.com/guide/#what-is-handlebars"
        target="_blank"
        rel="noopener noreferrer"
      >
        Handlebars
      </a>{" "}
      to define the request headers and body.
    </p>
    <p>
      Here are the syntaxes for a hardcoded value, a variable value, a variable
      with space in key name, and a javascript code :
    </p>
    <LegacyCodeEditor readOnly code={handlebarsExample} />
    <p></p>
    <p>
      For the request body it is also possible to include a complex object :
    </p>
    <LegacyCodeEditor readOnly code={handlebarsObjectExample} />
  </Help>
);

export default function HttpNodeForm({ node, onClose }) {
  const { updateNode, getAvailableVariables, startTestRun } = useWorkflow();

  const [name, setName] = useState(node.name);
  const [config, setConfig] = useState(node.config);

  const { variables } = useMemo(
    () => getAvailableVariables(node.id),
    [getAvailableVariables, node.id]
  );
  const updateConfig = (key, value) => {
    setConfig(config => ({ ...config, [key]: value }));
  };

  const addExtractor = extractor => {
    setConfig(config => ({
      ...config,
      extractors: [...config.extractors, extractor]
    }));
  };

  const updateExtractor = ({ target }, index) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setConfig(config => ({
      ...config,
      extractors: config.extractors.map((extractor, currentIndex) => {
        if (currentIndex === index) {
          return { ...extractor, [name]: value };
        }
        return extractor;
      })
    }));
  };

  const deleteExtractor = deleteIndex => {
    setConfig(config => ({
      ...config,
      extractors: config.extractors.filter(
        (_extractor, index) => index !== deleteIndex
      )
    }));
  };

  const updateAnonymize = ({ target }) => {
    setConfig(config => ({
      ...config,
      anonymize: {
        ...config.anonymize,
        [target.name]: target.checked
      }
    }));
  };

  const saveNode = e => {
    e.preventDefault();
    updateNode(node.id, { ...node, name, config });
  };

  const updateRetryOptions = ({ target }) => {
    setConfig(config => ({
      ...config,
      retryOptions: {
        ...config.retryOptions,
        [target.name]: target.value
      }
    }));
  };

  const {
    method,
    url,
    headers,
    body,
    anonymize = {},
    extractors = [],
    retryActive = false,
    retryOptions = {},
    continueWorkflow = false,
    timeout = DEFAULT_REQUEST_TIMEOUT
  } = config;
  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts mb-3">
          <h5>
            <EditableText
              text={name}
              onChange={({ target }) => setName(target.value)}
            />
          </h5>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

        <div className="header-parts">
          <AvailableVariables variables={variables} node={node} />
          <Button
            className="btn"
            onClick={() => startTestRun({ ...node, name, config })}
            type="submit"
            variant="outline-success"
          >
            <FaPlay /> Test node
          </Button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Row>
            <Form.Group as={Col} xs={3}>
              <Form.Label>Method*</Form.Label>
              <Form.Control
                as="select"
                required
                name="method"
                onChange={({ target }) => updateConfig("method", target.value)}
                value={method}
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="PATCH">PATCH</option>
                <option value="DELETE">DELETE</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Url*</Form.Label>
              <Form.Control
                required
                type="text"
                name="url"
                title="Must be a valid url"
                pattern="https?:\/\/.+"
                placeholder="https://api.test.com/products/{{columns.productId}}"
                onChange={({ target }) => updateConfig("url", target.value)}
                value={url}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Headers</Form.Label>
            {PartsHelper}
            <CodeEditor
              language="handlebars"
              code={headers}
              onChange={value => updateConfig("headers", value)}
            />
            <br />
            <AnonymizeInput
              checked={anonymize.headers}
              name="headers"
              onChange={updateAnonymize}
              label="Anonymize Headers"
              width={230}
            />
          </Form.Group>

          {method !== "GET" && (
            <Form.Group>
              <Form.Label>Body</Form.Label>
              {PartsHelper}
              <CodeEditor
                language="handlebars"
                code={body}
                onChange={value => updateConfig("body", value)}
              />
              <br />
              <AnonymizeInput
                checked={anonymize.body}
                name="body"
                onChange={updateAnonymize}
                label="Anonymize Body"
                width={210}
              />
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>
              Extractors&nbsp;
              <Help title="Extractors">
                <p>
                  Extractors help you extract data from a JSON response body, or
                  from response headers, for reuse in next steps of the
                  workflow. An extractor creates a variable in the workflow
                  execution context, that can be referenced using the syntax :
                  context.<i>name</i>
                </p>
                <p>
                  <b>Path</b> describe how to extract data from JSON response.
                </p>
                <p>
                  <i>
                    The extraction is made using JSONata syntax, checkout "Test
                    it" for more information.
                  </i>
                </p>
              </Help>
            </Form.Label>
            {extractors.map((extractor, index) => (
              <div key={index}>
                <ExtractorForm
                  {...extractor}
                  onChange={e => updateExtractor(e, index)}
                >
                  <Col xs="auto">
                    <Button
                      type="button"
                      size="lg"
                      variant="inline-danger"
                      onClick={() => deleteExtractor(index)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </ExtractorForm>
                <br />
              </div>
            ))}
            <AddExtractor onAdd={addExtractor} />
          </Form.Group>
          <hr />
          <Form.Group>
            <Form.Label>Response</Form.Label>
            <AnonymizeInput
              name="response"
              checked={anonymize.response}
              onChange={updateAnonymize}
              label="Anonymize Response"
              width={240}
            />
          </Form.Group>
          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="retry-switch"
              name="retryActive"
              label="Auto-retry"
              checked={retryActive}
              onChange={({ target }) =>
                updateConfig("retryActive", target.checked)
              }
            />
            <Help title="Auto retry">
              <p>Automatically retries failed request.</p>
              <p>
                <i>Between 1 to 5 retries</i>
              </p>
              <p>
                Request <b>will be considered failed</b> if there is{" "}
                <b>no response</b> (ENOTFOUND, ETIMEDOUT, etc).
              </p>
              <p>
                Or if <b>response status code</b> is in :
                <ul>
                  <li>100 {"->"} 199</li>
                  <li>429</li>
                  <li>500 {"->"} 599</li>
                </ul>
              </p>
              <p>
                Exponential backoff is used between retries:
                <ul>
                  <li>First retry after 500ms</li>
                  <li>Second retry after 1.5s</li>
                  <li>Third retry after 3.5s</li>
                  <li>Fourth retry after 7.5s</li>
                  <li>Fifth retry after 15.5s</li>
                </ul>
              </p>
            </Help>
          </Form.Row>
          {retryActive === true && (
            <Form.Row className="mt-3">
              <Col xs={3}>
                <Form.Label>Retry count*</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  required
                  value={retryOptions.count}
                  min={1}
                  max={5}
                  onChange={updateRetryOptions}
                />
                <Form.Text className="text-muted">
                  Between 1 and 5 retries
                </Form.Text>
              </Col>
            </Form.Row>
          )}
          <hr />
          <Form.Group>
            <Form.Label>Timeout</Form.Label>
            <Help title="Request timeout">
              <>
                <p>
                  Timeout specifies the number of seconds before the request
                  times out. If the request takes longer than timeout, the
                  request will be aborted.
                </p>
                <p>Minimum: 1, Maximum: 300.</p>
              </>
            </Help>
            <Form.Control
              type="number"
              name="timeout"
              placeholder="15"
              min={1}
              max={300}
              onChange={({ target }) => updateConfig("timeout", target.value)}
              value={timeout}
            />
            <Form.Text className="text-muted">
              In seconds (default to 15 seconds)
            </Form.Text>
          </Form.Group>
          <hr />
          <Form.Row className="pl-1">
            <Form.Check
              type="switch"
              id="continue-workflow"
              name="continueWorkflow"
              label="Continue workflow if request failed"
              checked={continueWorkflow}
              onChange={({ target }) =>
                updateConfig("continueWorkflow", target.checked)
              }
            />
            <Help title="Continue workflow">
              <p>
                By default, the workflow will stop if a request fails, by
                activating this option, workflow will continue even if this
                request returns an error response. After the request an object
                lastResponse will be available in the context containing status
                and success of last request response
              </p>
              <LegacyCodeEditor
                code={JSON.stringify(
                  {
                    context: { lastResponse: { status: 400, success: false } }
                  },
                  null,
                  2
                )}
                readOnly
              />
            </Help>
          </Form.Row>
          <hr />
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
