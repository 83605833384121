import React, { Component } from "react";
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Table
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { MdPause, MdPlayArrow } from "react-icons/md";
import { Link } from "react-router-dom";
import ChannelAPI from "../../api/Channel";
import UserAPI from "../../api/User";
import Auth from "../../authentication/auth";
import { truncate } from "../../utils/format";

export default class Channels extends Component {
  state = {
    channels: [],
    showDeleteConfirm: false,
    deletingChannel: null
  };

  constructor() {
    super();
    this.isAdmin = Auth.isAdmin();
  }

  componentDidMount() {
    this.loadChannels();
  }

  loadChannels() {
    UserAPI.getChannels().then(channels => this.setState({ channels }));
  }

  askDeleteConfirm = channelId => {
    this.setState({ showDeleteConfirm: true, deletingChannel: channelId });
  };

  closeDeleteConfirm = () => {
    this.setState({ showDeleteConfirm: false, deletingChannel: null });
  };

  deleteChannel = channelId => {
    ChannelAPI.delete(channelId).finally(() => {
      this.closeDeleteConfirm();
      this.loadChannels();
    });
  };

  render() {
    const { channels, showDeleteConfirm, deletingChannel } = this.state;
    return (
      <Container style={{ "max-width": "100%" }}>
        <Card>
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5>My channels</h5>
              </Col>
              <Col xs="auto">
                <Button as={Link} to="/channels/create" variant="light">
                  <FaPlus /> New Channel
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <Table size="sm">
              <thead>
                <tr>
                  {this.isAdmin && <th>Company</th>}
                  <th>Name</th>

                  <th>Slug</th>
                  <th>Description</th>
                  <th>State</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {channels.map(channel => (
                  <tr key={channel.id}>
                    {this.isAdmin && <td>{channel.company.login}</td>}
                    <td>{channel.name}</td>

                    <td>{channel.slug}</td>
                    <td>{truncate(channel.description, 40)}</td>
                    <td>
                      {channel.active ? (
                        <Badge pill className="badge-primary">
                          <MdPlayArrow /> Active
                        </Badge>
                      ) : (
                        <Badge pill className="badge-secondary">
                          <MdPause /> Paused
                        </Badge>
                      )}
                    </td>
                    <td style={{ minWidth: "200px" }}>
                      <ButtonToolbar className="float-right">
                        <Button
                          as={Link}
                          to={`/channels/edit/${channel.id}`}
                          variant="inline-primary"
                        >
                          <FaEdit /> Edit
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => this.askDeleteConfirm(channel.id)}
                          variant="inline-danger"
                        >
                          <FaTrashAlt /> Delete
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
                {channels.length === 0 && (
                  <tr>
                    <td>
                      <h6>No channels yet.</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal show={showDeleteConfirm} onHide={this.closeDeleteConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this channel ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeDeleteConfirm}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => this.deleteChannel(deletingChannel)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
