import React from "react";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import NewNode from "../NewNode";
import NodeArrow from "../NodeArrow";
import NodeContainer from "../NodeContainer";
import NodeFiller from "../NodeFiller";

export default function SimpleNextNode({
  currentNodeId,
  nextNode,
  showNewNode = true
}) {
  const { dispatch } = useWorkflow();
  return (
    <div className="node-next">
      <NodeArrow
        showNewNode={nextNode !== null && showNewNode}
        onNewNodeClick={() => {
          dispatch({
            type: "toggleNewNode",
            payload: {
              parentId: currentNodeId,
              nextId: nextNode ? nextNode.id : null
            }
          });
        }}
      />
      {nextNode ? (
        <NodeContainer node={nextNode} />
      ) : (
        <div className="node-container">
          <NewNode
            onClick={() =>
              dispatch({
                type: "toggleNewNode",
                payload: { parentId: currentNodeId }
              })
            }
          />
          <NodeFiller />
        </div>
      )}
    </div>
  );
}
