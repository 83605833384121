import PropTypes from "prop-types";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/cjs/styles/hljs";

export default function MarkdownCodeBlock({
  language = "javascript",
  children
}) {
  return (
    <SyntaxHighlighter language={language} style={vs2015}>
      {children}
    </SyntaxHighlighter>
  );
}

MarkdownCodeBlock.propTypes = {
  language: PropTypes.string
};
