import React from "react";
import NewNode from "./NewNode";

export default function NodeArrow({
  showNewNode,
  onNewNodeClick,
  arrowFromTheLeft = false
}) {
  return (
    <div className="node-arrow">
      <svg className="node-arrow-svg">
        <defs>
          <marker
            id="arrow"
            markerWidth="12"
            markerHeight="12"
            refX="0"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
            viewBox="0 0 15 15"
          >
            <path d="M0,0 L0,6 L6,3 z" fill="#5e636b" />
          </marker>
        </defs>
        <line
          x1="50%"
          y1="0%"
          x2="50%"
          y2="82%"
          stroke="#5e636b"
          strokeWidth="3"
          markerEnd="url(#arrow)"
        />
        {arrowFromTheLeft && (
          <line
            x1="25%"
            y1="39%"
            x2="25%"
            y2="39%"
            stroke="#5e636b"
            stroke-width="3"
            marker-end="url(#arrow)"
          ></line>
        )}
      </svg>
      {showNewNode ? <NewNode onClick={onNewNodeClick} /> : null}
    </div>
  );
}
