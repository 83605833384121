import React from "react";
import CodeNode from "./nodes/CodeNode";
import HttpNode from "./nodes/HttpNode";
import FormNode from "./nodes/FormNode";
import FtpNode from "./nodes/FtpNode";
import BusinessDashBoardNode from "./nodes/BusinessDashboardNode";
import ResponseNode from "./nodes/ResponseNode";
import ChannelNode from "./nodes/ChannelNode";
import ConditionalNode from "./nodes/ConditionalNode";
import WaitNode from "./nodes/WaitNode";
import ForeachNode from "./nodes/ForeachNode";
import SendEmailNode from "./nodes/SendEmailNode";

export default function NodeContainer({ node, startIcon, title, children }) {
  let Node;
  switch (node.type) {
    case "CHANNEL":
      Node = ChannelNode;
      break;
    case "HTTP":
      Node = HttpNode;
      break;
    case "FORM":
      Node = FormNode;
      break;
    case "FTP":
      Node = FtpNode;
      break;
    case "BUSINESS":
      Node = BusinessDashBoardNode;
      break;
    case "CODE":
      Node = CodeNode;
      break;
    case "RESPONSE":
      Node = ResponseNode;
      break;
    case "CONDITIONAL":
      Node = ConditionalNode;
      break;
    case "FOREACH":
      Node = ForeachNode;
      break;
    case "WAIT":
      Node = WaitNode;
      break;
    case "SEND_EMAIL":
      Node = SendEmailNode;
      break;
    default:
      break;
  }
  return (
    <div className="node-container">
      <Node
        node={node}
        startIcon={startIcon}
        title={title}
        children={children}
      />
    </div>
  );
}
