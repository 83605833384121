import BaseAPI from './BaseAPI'

/**
 * Upload API
 * @extends BaseAPI
 */
export default class Upload extends BaseAPI {
  /**
   * Upload a file
   *
   * @param {FormData} data The form data containing the file
   * @returns {Promise<Object>} An upload response
   */
  static upload(data) {
    return this.execute('post', `/upload`, data)
  }
}
