import React, { Component } from "react";
import { Form, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import WorkflowForm from "./WorkflowForm";

export default class AddWorkflow extends Component {
  state = {
    condition: "",
    action: "stop",
    step: ""
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const { condition, action, step } = this.state;
    this.props.onAdd({ condition, action, step });
    this.setState({ condition: "", action: "stop", step });
  };

  render() {
    const { condition, action, step } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <WorkflowForm
          condition={condition}
          action={action}
          step={step}
          onChange={this.handleInputChange}
          availableCalls={this.props.availableCalls}
        >
          <Col xs="auto">
            <Overlay
              placement="top"
              target={this.buttonRef}
              show={condition !== ""}
            >
              {props => <Tooltip {...props}>Don't forget to confirm</Tooltip>}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="inline-alpha"
            >
              <FaPlus />
            </Button>
          </Col>
        </WorkflowForm>
      </Form>
    );
  }
}
