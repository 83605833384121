import React from "react";
import {
  FaFileAlt,
  FaRss,
  FaHubspot,
  FaCloud,
  FaExchangeAlt,
  FaDatabase,
  FaClock
} from "react-icons/fa";
import { MdHttp } from "react-icons/md";
import { Badge, Card, Col, Row } from "react-bootstrap";

export default function ChannelTypes({ selectedType, onSelect }) {
  const channelClasses = (type, disabled = false) =>
    `channel-type ${selectedType === type ? "channel-type-selected" : ""} ${
      disabled ? "channel-type-disabled" : ""
    }`;
  return (
    <Row className="channel-types">
      <Col xs={3}>
        <Card
          className={channelClasses("file")}
          onClick={() => onSelect("file")}
        >
          <div className="channel-type-title">
            <FaFileAlt />
            <h5>File</h5>
          </div>
          <p>
            Trigger channel from a CSV, Excel or XML file using FTP, Email or
            direct upload
          </p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card
          className={channelClasses("rest")}
          onClick={() => onSelect("rest")}
        >
          <div className="channel-type-title">
            <MdHttp />
            <h5>API Endpoint</h5>
          </div>
          <p>Trigger channel from an Http request</p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card className={channelClasses("web")} onClick={() => onSelect("web")}>
          <div className="channel-type-title">
            <FaExchangeAlt />
            <h5>Web connect</h5>
          </div>
          <p>
            Trigger channel from existing websites or partner API using API
            polling, Websocket or Long polling
          </p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card className={channelClasses("iot")} onClick={() => onSelect("iot")}>
          <Badge className="channel-type-version" variant="primary">
            Beta
          </Badge>
          <div className="channel-type-title">
            <FaHubspot />
            <h5>IOT</h5>
          </div>
          <p>Trigger channel from IOT devices using MQTT or AMQP</p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card
          className={channelClasses("event")}
          onClick={() => onSelect("event")}
        >
          <div className="channel-type-title">
            <FaRss />
            <h5>Realtime Event</h5>
          </div>
          <p>
            Trigger channel from realtime events using Kafka, JMS or RabbitMQ
          </p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card
          className={channelClasses("scheduler")}
          onClick={() => onSelect("scheduler")}
        >
          <div className="channel-type-title">
            <FaClock />
            <h5>Scheduler</h5>
          </div>
          <p>Trigger channel at a fixed time</p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card
          className={channelClasses("database", true)}
          onClick={() => onSelect("database")}
        >
          <Badge className="channel-type-version" variant="secondary">
            Coming soon
          </Badge>
          <div className="channel-type-title">
            <FaDatabase />
            <h5>Database</h5>
          </div>
          <p>Trigger channel from your ERP, Enterprise App, etc...</p>
        </Card>
      </Col>
      {}

      <Col xs={3}>
        <Card className={channelClasses("cloud", true)}>
          <Badge className="channel-type-version" variant="secondary">
            Coming soon
          </Badge>
          <div className="channel-type-title">
            <FaCloud />
            <h5>Cloud connect</h5>
          </div>
          <p>
            Trigger channel from your OnPrem thanks to an APIZR Cloud Connect{" "}
          </p>
        </Card>
      </Col>
    </Row>
  );
}
