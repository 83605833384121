import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useWorkflow } from "../../../../../contexts/WorkflowContext";
import Help from "../../../../Help";

const ErrorHandlingHelp = (
  <Help title="Error handling" placement="left" maxWidth="350px">
    <p>
      Activating this feature will create a new workflow branch that will be
      executed when an error occurs anywhere on the main branch.
    </p>
    <p>
      If you checked <strong>Continue workflow if request failed</strong> in a
      node, this workflow won't be executed if the node fails.
    </p>
  </Help>
);

export default function ChannelNodeForm({ onClose }) {
  const { nodes, updateNodes, channel, company } = useWorkflow();
  const [hasGlobalCatchNode, setHasGlobalCatchNode] = useState(
    !!nodes.global_error_catch
  );
  const [currentGlobalCatch] = useState(nodes.global_error_catch);
  const newGlobalCatch = {
    name: "Error handling",
    children: [],
    id: "global_error_catch",
    type: "CHANNEL",
    next: Object.values(nodes).find(
      node => node.parent === "global_error_catch"
    )?.id
  };

  const updateGlobalCatchNode = checked => {
    setHasGlobalCatchNode(checked);

    if (checked) {
      updateNodes({
        ...nodes,
        global_error_catch: currentGlobalCatch || newGlobalCatch
      });
    } else {
      const newNodes = { ...nodes };

      delete newNodes.global_error_catch;

      updateNodes(newNodes);
    }
  };
  const saveNode = e => {
    e.preventDefault();
    updateGlobalCatchNode(hasGlobalCatchNode);
    onClose();
  };

  const onBeforeClose = () => {
    updateGlobalCatchNode(!!currentGlobalCatch);
    onClose();
  };

  return (
    <div className="node-form">
      <div className="node-form-header">
        <div className="header-parts">
          <h5>
            <span>
              {company?.login} - {channel?.name}
            </span>
          </h5>
          <button type="button" className="close" onClick={onBeforeClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>
      <div className="node-form-body">
        <Form onSubmit={saveNode}>
          <Form.Group>
            <InputGroup style={{ alignItems: "end" }}>
              <Form.Check
                type="checkbox"
                label="Activate error handling"
                onChange={e => updateGlobalCatchNode(e.target.checked)}
                checked={hasGlobalCatchNode}
              />
              <InputGroup.Append>{ErrorHandlingHelp}</InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Button className="btn-block" type="submit" variant="inline-success">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
